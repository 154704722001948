import { useQuery } from '@apollo/client';
import {
  PostRequirementAction,
  RequirementsTableV2,
} from '../components/shared/RequirementsTable';
import { POST_REQUIREMENTS } from '../graphql/queries';
import { Spinner } from '@kalecard/common';
import { PostRequirement } from '../__generated__/graphql';

export default function ChallengeRequirementsPage() {
  const { data, loading } = useQuery(POST_REQUIREMENTS, {
    variables: {
      types: ['CONTENT'],
    },
  });

  if (loading) return <p>Loading...</p>;
  return (
    <div className="mb-4 mt-4 space-y-6 px-4 sm:px-6 lg:px-8">
      {/* Header */}
      <div className="space-y-2">
        <h1 className="text-xl font-semibold text-gray-900">
          Challenge Requirements
        </h1>
        <p className="text-sm text-gray-700">
          A list of all the challenge requirements
        </p>
      </div>

      {/* Challenge Requirements */}
      {loading ? (
        <Spinner size="h-8 w-8" />
      ) : (
        <RequirementsTableV2
          requirements={data?.postRequirements as PostRequirement[]}
          showBrandDetails={false}
          brand={null}
          actions={[PostRequirementAction.EDIT]}
          onAddRequirement={() => {}}
          onRemoveRequirement={() => {}}
          onNewRequirement={() => {}}
        />
      )}
    </div>
  );
}
