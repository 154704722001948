import { FetchPolicy } from '@apollo/client';
import { Challenge } from '../__generated__/graphql';

export enum ChallengesActionType {
  SET_CHALLENGES,
  UPDATE_CHALLENGES,
  ADD_BRAND_FILTER,
  ADD_STATE_FILTER,
  RESET_FILTERS,
  REMOVE_BRAND_FILTER,
  REMOVE_STATE_FILTER,
  NEW_CHALLENGE,
  TOGGLE_CHALLENGE_STATE,
  UPDATE_CHALLENGE,
  RERANK_CHALLENGE,
  SET_SORTING_TYPE,
  UPDATE_KEYWORDS,
  ADD_CHALLENGE_ID_FILTER,
  REMOVE_CHALLENGE_ID_FILTER,
  REVIEW_PENDING_REVIEW_CHALLENGE,
  SELECT_SORTABLE_COLUMN,
  ADD_FILTER_FOR_PENDING_REVIEW,
  ADD_FILTER_FOR_FEEDBACK,
  REMOVE_FEEDBACK_FILTER,
  TOGGLE_COMMERCIAL_SOUNDS_ONLY_FILTER,
  ADD_TEMPLATE_FILTER,
  REMOVE_TEMPLATE_FILTER,
}

export enum SortableChallengeColumnType {
  CLAIMS,
  POSTS,
  POST_RATE,
}

export interface SortableChallengeColumnTypeData {
  name: string;
  selected: boolean;
  sortingType?: string;
}

export interface SortableColumn {
  key: SortableChallengeColumnType;
  value: SortableChallengeColumnTypeData;
}

export interface ChallengeSortingType {
  id: string;
  name: string;
}

export const challengesSortingTypes: ChallengeSortingType[] = [
  { name: 'First Activation', id: 'FIRST_ACTIVATION_DESC' },
  { name: 'Total Claims', id: 'TOTAL_CLAIMS_DESC' },
  { name: 'Total Posts', id: 'TOTAL_POSTS_DESC' },
  { name: 'Post Rate', id: 'POST_RATE_DESC' },
  { name: 'Creation Date', id: 'CREATED_AT_DESC' },
  { name: 'New Feedback Date', id: 'NEW_FEEDBACK_CREATED_AT_ASC' },
];

interface ChallengeBrand {
  id: string;
  name: string;
}

interface ChallengeState {
  id: string;
  name: string;
}

export interface AdditionalFilters {
  containingFeedback: boolean;
  commercialSoundsOnly: boolean;
}

export interface ChallengesState {
  challenges: Challenge[];
  hasNextPage?: boolean;
  after?: string;
  brand?: ChallengeBrand;
  challengeState?: ChallengeState;
  newChallenges: Challenge[];
  sortingType?: ChallengeSortingType;
  keywords?: string[];
  fetchPolicy?: FetchPolicy;
  brands?: ChallengeBrand[];
  challengeIds?: string[];
  shouldUpdateChallenges?: boolean;
  pendingChallengesCount?: number;
  sortableColumns: SortableColumn[];
  feedbackCount?: number;
  additionalFilters?: AdditionalFilters;
  source?: string;
  challengeTemplateId?: string;
}

export interface ChallengesAction {
  type: ChallengesActionType;
  payload: any;
}

function toggleChallengeStateIfApplicable(
  tempChallenge: Challenge,
  challenge: Challenge
): Challenge {
  if (tempChallenge.id === challenge.id) {
    return {
      ...tempChallenge,
      state: tempChallenge.state === 'ACTIVE' ? 'INACTIVE' : 'ACTIVE',
    };
  }
  return tempChallenge;
}

function updateChallengeIfApplicable(
  tempChallenge: Challenge,
  challenge: Challenge
): Challenge {
  if (tempChallenge.id === challenge.id) {
    return challenge.state === 'DELETED' || challenge.state === 'REJECTED'
      ? null
      : challenge;
  }
  return tempChallenge;
}

function updateSortableColumns(
  sortableColumns: SortableColumn[],
  selectedSortingType: ChallengeSortingType
): SortableColumn[] {
  return sortableColumns.map((col) => {
    return {
      ...col,
      value: {
        ...col.value,
        selected:
          col.value.sortingType === selectedSortingType.id ? true : false,
      },
    };
  });
}

export function ChallengesReducer(
  state: ChallengesState,
  action: ChallengesAction
): ChallengesState {
  switch (action.type) {
    case ChallengesActionType.SET_CHALLENGES: {
      const { challenges, hasNextPage } = action.payload as ChallengesState;
      return {
        ...state,
        challenges: challenges,
        hasNextPage: hasNextPage,
        after: challenges.length.toString(),
        fetchPolicy: 'cache-first',
        shouldUpdateChallenges: false,
      };
    }

    case ChallengesActionType.UPDATE_CHALLENGES: {
      const { challenges, hasNextPage } = action.payload as ChallengesState;

      const updatedChallenges = [...state.challenges, ...challenges];
      return {
        ...state,
        challenges: updatedChallenges,
        hasNextPage: hasNextPage,
        after: updatedChallenges.length.toString(),
        shouldUpdateChallenges: false,
      };
    }

    case ChallengesActionType.ADD_BRAND_FILTER: {
      const brand = action.payload as ChallengeBrand;
      return {
        ...state,
        brands: [...state.brands, brand],
        after: null,
        shouldUpdateChallenges: true,
        source: null,
      };
    }

    case ChallengesActionType.ADD_STATE_FILTER: {
      const { challengeState } = action.payload as ChallengesState;
      return {
        ...state,
        challengeState: challengeState,
        after: null,
        shouldUpdateChallenges: true,
        source: null,
      };
    }

    case ChallengesActionType.RESET_FILTERS: {
      return {
        ...state,
        challengeState: null,
        keywords: [],
        after: null,
        brands: [],
        challengeIds: [],
        shouldUpdateChallenges: true,
        additionalFilters: {
          containingFeedback: false,
          commercialSoundsOnly: false,
        },
        source: null,
        challengeTemplateId: null,
      };
    }

    case ChallengesActionType.REMOVE_BRAND_FILTER: {
      const brand = action.payload as ChallengeBrand;
      const updatedBrands = state.brands?.filter(
        (tempBrand) => tempBrand.id !== brand.id
      );

      return {
        ...state,
        brands: updatedBrands,
        after: null,
        shouldUpdateChallenges: true,
        source: null,
      };
    }

    case ChallengesActionType.REMOVE_STATE_FILTER: {
      const newSortingType =
        state.challengeState?.id === 'PENDING_REVIEW' &&
        state.sortingType?.id === 'CREATED_AT_ASC'
          ? challengesSortingTypes.find(
              (sortingType) => sortingType.id === 'RANK_ASC'
            )
          : state.sortingType;

      return {
        ...state,
        challengeState: null,
        after: null,
        shouldUpdateChallenges: true,
        sortingType: newSortingType,
        source: null,
      };
    }

    case ChallengesActionType.NEW_CHALLENGE: {
      const challenge = action.payload as Challenge;
      return {
        ...state,
        newChallenges: [...state.newChallenges, challenge],
        fetchPolicy: 'network-only',
      };
    }

    case ChallengesActionType.TOGGLE_CHALLENGE_STATE: {
      const challenge = action.payload as Challenge;

      const updatedChallenges = state.challenges.map((tempChallenge) => {
        return toggleChallengeStateIfApplicable(tempChallenge, challenge);
      });

      const updatedNewChallenges = state.newChallenges.map((tempChallenge) => {
        return toggleChallengeStateIfApplicable(tempChallenge, challenge);
      });

      return {
        ...state,
        challenges: updatedChallenges,
        newChallenges: updatedNewChallenges,
        fetchPolicy: 'network-only',
      };
    }

    case ChallengesActionType.UPDATE_CHALLENGE: {
      const challenge = action.payload as Challenge;

      const updatedChallenges = state.challenges
        .map((tempChallenge) => {
          return updateChallengeIfApplicable(tempChallenge, challenge);
        })
        .filter((tempChallenge) => tempChallenge);

      const updatedNewChallenges = state.newChallenges
        .map((tempChallenge) => {
          return updateChallengeIfApplicable(tempChallenge, challenge);
        })
        .filter((tempChallenge) => tempChallenge);

      return {
        ...state,
        challenges: updatedChallenges,
        newChallenges: updatedNewChallenges,
        fetchPolicy: 'network-only',
      };
    }

    case ChallengesActionType.RERANK_CHALLENGE: {
      const { challenge, newBelowChallengeId } = action.payload;

      const tempChallenge = state.challenges.find(
        (temp) => temp.id === challenge.id
      );
      let updatedChallenges = state.challenges.filter(
        (temp) => temp.id !== tempChallenge?.id
      );
      const belowChallengeIdIndex = state.challenges.findIndex(
        (temp) => temp.id === newBelowChallengeId
      );
      const challengeIndex = state.challenges.findIndex(
        (temp) => temp.id === challenge.id
      );

      const slicingPoint =
        challengeIndex < belowChallengeIdIndex
          ? belowChallengeIdIndex
          : belowChallengeIdIndex + 1;

      if (newBelowChallengeId) {
        if (belowChallengeIdIndex !== -1) {
          updatedChallenges = [
            ...updatedChallenges.slice(0, slicingPoint),
            tempChallenge,
            ...updatedChallenges.slice(slicingPoint),
          ];
        }
      } else {
        updatedChallenges = [tempChallenge, ...updatedChallenges];
      }

      return {
        ...state,
        challenges: updatedChallenges,
        after: updatedChallenges.length.toString(),
        fetchPolicy: 'network-only',
      };
    }

    case ChallengesActionType.SET_SORTING_TYPE: {
      const { sortingType } = action.payload as ChallengesState;

      const sortableColumns = updateSortableColumns(
        state.sortableColumns,
        sortingType
      );
      return {
        ...state,
        sortingType: sortingType,
        after: null,
        shouldUpdateChallenges: true,
        sortableColumns: sortableColumns,
        source: null,
      };
    }

    case ChallengesActionType.UPDATE_KEYWORDS: {
      const { keywords } = action.payload as ChallengesState;
      return {
        ...state,
        keywords: Array.from(new Set(keywords)),
        after: null,
        shouldUpdateChallenges: true,
        source: null,
      };
    }

    case ChallengesActionType.ADD_CHALLENGE_ID_FILTER: {
      const challengeId = action.payload as string;
      return {
        ...state,
        challengeIds: [...state.challengeIds, challengeId],
        after: null,
        shouldUpdateChallenges: true,
        source: null,
      };
    }

    case ChallengesActionType.REMOVE_CHALLENGE_ID_FILTER: {
      const challengeId = action.payload as string;

      const updatedChallengeIds = state.challengeIds?.filter(
        (id) => id !== challengeId
      );

      return {
        ...state,
        challengeIds: updatedChallengeIds,
        after: null,
        shouldUpdateChallenges: true,
        source: null,
      };
    }

    case ChallengesActionType.REVIEW_PENDING_REVIEW_CHALLENGE: {
      return {
        ...state,
        pendingChallengesCount: state.pendingChallengesCount
          ? state.pendingChallengesCount - 1
          : 0,
      };
    }

    case ChallengesActionType.SELECT_SORTABLE_COLUMN: {
      const sortableColumn = action.payload as SortableChallengeColumnType;

      const newSortableColumns = state.sortableColumns;
      let sortingType = state.sortingType;
      let shouldUpdateChallenges = false;
      newSortableColumns.map((col) => {
        if (col.key === sortableColumn) {
          if (sortingType?.id !== col.value.sortingType) {
            sortingType = challengesSortingTypes.find(
              (sortingType) => sortingType.id === col.value.sortingType
            );
            shouldUpdateChallenges = true;
          }
          col.value.selected = true;
        } else {
          col.value.selected = false;
        }
      });

      return {
        ...state,
        sortingType: sortingType,
        sortableColumns: newSortableColumns,
        shouldUpdateChallenges: shouldUpdateChallenges,
        after: null,
        source: null,
      };
    }

    case ChallengesActionType.ADD_FILTER_FOR_PENDING_REVIEW: {
      return {
        ...state,
        challengeState: {
          id: 'PENDING_REVIEW',
          name: 'Pending Review',
        },
        sortingType: {
          name: 'Suggested Activation Date',
          id: 'SUGGESTED_ACTIVATION_DATE',
        },
        keywords: [],
        brands: [],
        challengeIds: [],
        after: null,
        shouldUpdateChallenges: true,
        additionalFilters: {
          containingFeedback: false,
          commercialSoundsOnly: false,
        },
        source: 'BRAND_ADMIN',
      };
    }

    case ChallengesActionType.ADD_FILTER_FOR_FEEDBACK: {
      return {
        ...state,
        challengeState: null,
        keywords: [],
        after: null,
        brands: [],
        challengeIds: [],
        shouldUpdateChallenges: true,
        additionalFilters: {
          containingFeedback: true,
          commercialSoundsOnly: false,
        },
        source: null,
        sortingType: challengesSortingTypes.find(
          (sortingType) => sortingType.id === 'NEW_FEEDBACK_CREATED_AT_ASC'
        ),
      };
    }

    case ChallengesActionType.REMOVE_FEEDBACK_FILTER: {
      return {
        ...state,
        after: null,
        shouldUpdateChallenges: true,
        additionalFilters: {
          ...state.additionalFilters,
          containingFeedback: false,
        },
        sortingType: challengesSortingTypes.find(
          (sortingType) => sortingType.id === 'RANK_ASC'
        ),
        source: null,
      };
    }

    case ChallengesActionType.TOGGLE_COMMERCIAL_SOUNDS_ONLY_FILTER: {
      const enabled = action.payload as boolean;
      return {
        ...state,
        after: null,
        shouldUpdateChallenges: true,
        additionalFilters: {
          ...state.additionalFilters,
          commercialSoundsOnly: enabled,
        },
        source: null,
      };
    }

    case ChallengesActionType.ADD_TEMPLATE_FILTER: {
      const { challengeTemplateId } = action.payload as ChallengesState;
      return {
        ...state,
        after: null,
        shouldUpdateChallenges: true,
        source: null,
        challengeTemplateId: challengeTemplateId,
      };
    }

    case ChallengesActionType.REMOVE_TEMPLATE_FILTER: {
      return {
        ...state,
        after: null,
        shouldUpdateChallenges: true,
        source: null,
        challengeTemplateId: null,
      };
    }
  }
}
