export default function CreatorAccountState({ state }) {
  switch (state) {
    case "NEW":
      return (
        <span className="inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-sm font-medium text-yellow-800">
          Pending Approval
        </span>
      );
    case "APPROVED":
      return (
        <span className="inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800">
          Approved
        </span>
      );
    case "BLOCKED_BAD_ACTOR":
      return (
        <span className="inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800">
          Blocked for Bad Actor
        </span>
      );
    case "SCHEDULED_FOR_RESET":
      return (
        <span className="inline-flex items-center rounded-md bg-orange-100 px-2.5 py-0.5 text-sm font-medium text-orange-800">
          Pending deletion
        </span>
      );
    case "RESET":
      return (
        <span className="inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800">
          Deleted
        </span>
      );
    case "SCHEDULED_FOR_BLOCKED_UNDERAGE":
      return (
        <span className="inline-flex items-center rounded-md bg-orange-100 px-2.5 py-0.5 text-sm font-medium text-orange-800">
          Pending Blocking for Underage
        </span>
      );
    case "BLOCKED_UNDERAGE":
      return (
        <span className="inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800">
          Blocked for Underage
        </span>
      );
    case "BLOCKED_NEW_SOCIAL_ACCOUNT":
      return (
        <span className="inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-sm font-medium text-yellow-800">
          Blocked for New Social Account
        </span>
      );
    default:
      return (
        <span className="inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-sm font-medium text-yellow-800">
          {state}
        </span>
      );
  }
}
