import { useEffect, useState } from "react";
import { BonsaiQueue } from "../BonsaiModels";
import { BonsaiActionPanel } from "../BonsaiContainer";
import { Strong } from "../../catalyst/text";
import {
  BonsaiPanel,
  BonsaiOutcome,
  BonsaiContextLinkType,
  OutcomeControls,
  BonsaiLink,
} from "@kalecard/common";
import { Fieldset } from "../../catalyst/fieldset";
import {
  Checkbox,
  CheckboxField,
  CheckboxGroup,
} from "../../catalyst/checkbox";
import { Tooltip } from "flowbite-react";

import {} from "@heroicons/react/24/outline";

interface ReceiptVerificationLineItem {
  description: string | null;
  quantity: number | null;
  total: number | null;
}

interface ReceiptVerificationInput {
  creatorId: string;
  brandName: string;
  brandLogoUrl: string;
  receiptId: number;
  receiptUrl: string;
  lineItems: ReceiptVerificationLineItem[];
}

interface ReceiptVerificationOutput {
  outcome: BonsaiOutcome;
  brandTotalSpend: number;
}

interface ReceiptVerificationToolProps {
  queue: BonsaiQueue;
  onComplete: (outcome: ReceiptVerificationOutput) => void;
  readOnly: boolean;
  taskId: number;
  input: ReceiptVerificationInput;
}

export function ReceiptVerificationTool(props: ReceiptVerificationToolProps) {
  const [selectedOutcome, setSelectedOutcome] =
    useState<BonsaiOutcome>(undefined);
  const [selectedLineItems, setSelectedLineItems] = useState<Set<number>>(
    new Set()
  );
  const [totalSpend, setTotalSpend] = useState<number>(0);

  const lineItems = props.input.lineItems;

  const onLineItemChecked = (index: number) => {
    console.log("onLineItemChecked", index);
    const newSelectedLineItems = new Set(selectedLineItems);
    if (selectedLineItems.has(index)) {
      newSelectedLineItems.delete(index);
    } else {
      newSelectedLineItems.add(index);
    }
    setSelectedLineItems(newSelectedLineItems);
  };

  useEffect(() => {
    let newTotal = 0;
    selectedLineItems.forEach((index) => {
      newTotal += lineItems[index].total || 0;
    });
    setTotalSpend(newTotal);
  }, [selectedLineItems]);

  return (
    <>
      <div className="flex flex-row gap-4">
        <div className="flex-1 flex-row">
          <BonsaiPanel>
            <div className="space-y-2 p-2">
              <div className={"flex justify-between"}>
                <div className="flex-row justify-center space-y-4">
                  <div className="flex items-center space-x-2">
                    <img
                      src={props.input.brandLogoUrl}
                      className="w-8 rounded-full"
                    />
                    <Strong>{props.input.brandName}</Strong>
                  </div>
                </div>
                <BonsaiLink
                  type={BonsaiContextLinkType.CREATOR}
                  url={`/creators/${props.input.creatorId}/messages`}
                />
              </div>
              <div
                onClick={() => {
                  // Open Image in new tab
                  window.open(props.input.receiptUrl);
                }}
                className="flex justify-center space-x-4"
              >
                <img src={props.input.receiptUrl} />
              </div>
            </div>
          </BonsaiPanel>
        </div>
        {lineItems ? (
          <div className="flex-1 flex-row">
            <BonsaiPanel>
              <LineItemsSelector
                lineItems={lineItems}
                totalSpend={totalSpend}
                selectedLineItems={selectedLineItems}
                onLineItemChecked={onLineItemChecked}
              />
            </BonsaiPanel>
          </div>
        ) : null}
      </div>

      {!props.readOnly && (
        <BonsaiActionPanel
          submitDisabled={selectedOutcome === undefined}
          onSubmit={() => {
            props.onComplete({
              outcome: selectedOutcome,
              brandTotalSpend: totalSpend,
            });
            setSelectedOutcome(undefined);
            setSelectedLineItems(new Set<number>());
            setTotalSpend(0);
          }}
          taskDescription={props.queue.taskDescription}
          taskId={props.taskId}
        >
          <OutcomeControls
            onSelected={(outcome) => {
              setSelectedOutcome(outcome);
            }}
            selected={selectedOutcome}
          />
          {selectedOutcome === BonsaiOutcome.ACCEPTED && totalSpend == 0 ? (
            <div className="flex flex-col justify-center">
              <div className="text-md font-bold text-red-500">
                Total spend is 0. Are you sure this is correct?
              </div>
            </div>
          ) : null}
        </BonsaiActionPanel>
      )}
    </>
  );
}

function LineItemsSelector({
  totalSpend,
  lineItems,
  selectedLineItems,
  onLineItemChecked,
}: {
  totalSpend: number;
  lineItems: ReceiptVerificationLineItem[];
  selectedLineItems: Set<number>;
  onLineItemChecked: (index: number) => void;
}) {
  return (
    <div className="flex h-full flex-col justify-between p-4">
      <Fieldset>
        <CheckboxGroup className="space-y-0">
          {lineItems.map((lineItem, index) => (
            <CheckboxField key={index}>
              <Checkbox
                name="requirements"
                color="green"
                checked={selectedLineItems.has(index)}
                onChange={() => onLineItemChecked(index)}
              />
              <Tooltip
                content={
                  <div className="text-wrap">
                    Total: ${lineItem?.total?.toFixed(2) ?? 0}
                  </div>
                }
                placement="top"
              >
                <div>
                  <div>{lineItem.description}</div>
                  <div>Quantity: {lineItem.quantity}</div>
                </div>
              </Tooltip>
            </CheckboxField>
          ))}
        </CheckboxGroup>
      </Fieldset>
      <div className="pt-4 text-sm font-semibold text-slate-900">
        Total Spend: ${totalSpend.toFixed(2)}
        {totalSpend == 0 ? (
          <span className="text-md font-semibold text-red-500"> ⚠️</span>
        ) : null}
      </div>
    </div>
  );
}
