import { useMutation, useQuery } from "@apollo/client";
import { useRef, useState } from "react";
import { BRAND, BrandData } from "../../graphql/queries";
import { TURN_BRAND_OFF_AS_KALE_BRAND } from "../../graphql/mutations";
import { useForm } from "react-hook-form";
import { Spinner } from "@kalecard/common";
import { Button } from "../catalyst/button";
import { Strong, Text } from "../catalyst/text";

export default function BrandOffboardingForm({ brandId }: { brandId: string }) {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  const timerRef = useRef<NodeJS.Timeout>();
  const clearErrorMessage = () => {
    timerRef.current = setTimeout(() => setErrorMessage(null), 5000);
  };

  const clearSuccessMessage = () => {
    timerRef.current = setTimeout(() => setSuccessMessage(null), 7000);
  };

  const { loading: brandLoading, data } = useQuery<BrandData>(BRAND, {
    variables: {
      id: brandId,
    },
  });

  const [turnBrandOffAsKaleBrand] = useMutation(TURN_BRAND_OFF_AS_KALE_BRAND);
  const { handleSubmit } = useForm();

  const onSubmit = async () => {
    setLoading(true);
    try {
      const variables = {
        brandId: brandId,
      };

      await turnBrandOffAsKaleBrand({
        variables: variables,
      });
      setErrorMessage(null);
      setSuccessMessage(`${data.brand.name} is now being offboarded.`);
      clearSuccessMessage();
    } catch (err) {
      console.error(err);
      setErrorMessage("Failed to offboard this brand, try again later.");
      clearErrorMessage();
    }
    setLoading(false);
  };

  return (
    <form>
      {!brandLoading ? (
        <div className="space-y-4">
          <OffboardingExplanation name={data.brand?.name} />
          <div className="flex flex-row items-center justify-end space-x-4">
            {successMessage && (
              <p className="text-sm font-medium text-green-500">
                {successMessage}
              </p>
            )}
            {errorMessage && (
              <p className="text-sm font-medium text-red-500">{errorMessage}</p>
            )}
            {loading && (
              <div className="flex flex-wrap content-center justify-center pr-2">
                <Spinner size="h-6 w-6" />
              </div>
            )}
            <Button
              color="indigo"
              onClick={handleSubmit(onSubmit)}
              className="text-nowrap"
            >
              Offboard {data.brand.name}
            </Button>
          </div>
        </div>
      ) : (
        <div className="flex flex-wrap content-center">
          <Spinner size="h-6 w-6" />
        </div>
      )}
    </form>
  );
}

function OffboardingExplanation({ name }: { name: string }) {
  return (
    <div className="space-y-4">
      <Text>
        <Strong>Heads up!</Strong>
      </Text>
      <Text>
        {name} will enter the new state "Offboarding" that essentially acts as
        "Live" until they run out of budget.
      </Text>
      <Text>
        Once the brand runs out of budget, we will mark them as a non-Kale brand
        and take them off the app.
      </Text>
    </div>
  );
}
