import { useMutation } from "@apollo/client";
import { Score, Spinner } from "@kalecard/common";
import { TOGGLE_POST_SCORE } from "../../graphql/mutations";
import { Tooltip } from "flowbite-react";
import { useContext, useEffect, useRef, useState } from "react";
import { ScoreContext } from "../../providers/ScoreProvider";
import { ScoreActionType } from "../../reducers/ScoreReducer";
import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid";

export default function PostScoreComponent({
  score,
  postId,
}: {
  score: Score;
  postId: string;
}) {
  const timerRef = useRef<NodeJS.Timeout>();
  const [success, setSuccess] = useState<boolean>(null);
  const [isChecked, setIsChecked] = useState<boolean>(score.isSelected);
  const { dispatch } = useContext(ScoreContext);
  const [toggleScore, { loading }] = useMutation(TOGGLE_POST_SCORE);

  const clearSuccess = () => {
    timerRef.current = setTimeout(() => setSuccess(null), 2000);
  };

  useEffect(() => {
    // Clear the interval when the component unmounts
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
      }
    };
  }, []);

  return (
    <div className="flex items-start">
      <div className="flex h-5 items-center">
        <input
          id={`postScore-${postId}-${score.id}`}
          name={`postScore-${postId}-${score.id}`}
          checked={isChecked}
          type="checkbox"
          disabled={true}
          className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-500"
        />
      </div>
      <div className="ml-3 flex space-x-2 text-sm">
        <Tooltip
          content={
            <div className="flex max-w-md flex-wrap">
              <p className="whitespace-pre-wrap">{score.helpText}</p>
            </div>
          }
          placement="right"
        >
          <label
            htmlFor={`postScore-${postId}-${score.id}`}
            className="font-medium text-gray-700"
          >
            {score.description}
          </label>
        </Tooltip>
        {loading && <Spinner size={"h-4 w-4"} />}
        {success === true && (
          <CheckCircleIcon
            className="-mr-0.5 ml-2 h-4 w-4 text-green-500"
            aria-hidden="true"
          />
        )}
        {success === false && (
          <MinusCircleIcon
            className="-mr-0.5 ml-2 h-4 w-4 text-red-500"
            aria-hidden="true"
          />
        )}
      </div>
    </div>
  );
}
