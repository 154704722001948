import { useQuery } from "@apollo/client";
import {
  GET_SHARED_BONSAI_TASK,
  GetSharedBonsaiTaskQuery,
} from "../graphql/queries";
import { bonsaiRegistry } from "../components/bonsai/BonsaiRegistry";
import { useParams } from "react-router-dom";
import { toBonsaiQueue } from "../components/bonsai/BonsaiModels";

interface BonsaiSharePageProps {}

export default function BonsaiSharePage(props: BonsaiSharePageProps) {
  const params = useParams();
  const taskId = parseInt(params["id"]);
  const { data, loading } = useQuery<GetSharedBonsaiTaskQuery>(
    GET_SHARED_BONSAI_TASK,
    {
      variables: {
        taskId: taskId,
      },
    }
  );
  if (loading) return <div>Loading...</div>;

  const queue = toBonsaiQueue(data.getTask.queueName);

  return (
    <div className="mx-auto flex h-full w-full max-w-6xl flex-col justify-between p-4">
      {bonsaiRegistry(
        {
          type: queue,
          friendlyName: "",
          taskDescription: "",
        },
        () => {},
        data.getTask.metadata,
        taskId,
        true
      )}
    </div>
  );
}
