import {
  BonsaiEmptyState,
  BonsaiHeader,
} from "../components/bonsai/BonsaiContainer";
import { bonsaiRegistry } from "../components/bonsai/BonsaiRegistry";
import { useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import {
  GET_BONSAI_QUEUES,
  GET_NEXT_BONSAI_TASK,
  GetBonsaiQueuesQuery,
  NextTaskQuery,
} from "../graphql/queries";
import { COMPLETE_BONSAI_TASK } from "../graphql/mutations";
import {
  BonsaiQueue,
  BonsaiQueueType,
  getIconForBonsaiQueue,
  toBonsaiQueue,
} from "../components/bonsai/BonsaiModels";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../components/catalyst/table";
import { Avatar } from "../components/catalyst/avatar";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { Badge } from "../components/catalyst/badge";
import { getRandomHaiku } from "@kalecard/common";

interface BonsaiPageProps {
  defaultQueue?: BonsaiQueueType;
}

export default function BonsaiPage(props: BonsaiPageProps) {
  const [selectedQueue, setSelectedQueue] = useState<BonsaiQueue>(undefined);
  const [availableTasksInSelectedQueue, setAvailableTasksInSelectedQueue] =
    useState<number>(undefined);
  const {
    data: bonsaiQueuesData,
    loading: bonsaiQueuesLoading,
    refetch: bonsaiQueuesRefetch,
  } = useQuery<GetBonsaiQueuesQuery>(GET_BONSAI_QUEUES, {
    notifyOnNetworkStatusChange: true,
  });

  const availableQueues = useMemo(
    () =>
      bonsaiQueuesData?.getQueues
        ?.map((queue) => {
          const bonsaiQueueType = toBonsaiQueue(queue.name);
          return {
            type: bonsaiQueueType,
            taskDescription: queue.taskDescription,
            friendlyName: queue.friendlyName,
            remainingTasks: queue.remainingTasks,
            icon: getIconForBonsaiQueue(bonsaiQueueType),
            activeAdmins: queue.activeAdmins,
            oldestTaskCreationDate: queue.oldestTaskCreationDate,
            averageTaskAge: queue.averageTaskAge,
          } as BonsaiQueue;
        })
        .filter((queue) => queue.type !== undefined),
    [bonsaiQueuesData?.getQueues]
  );

  useEffect(() => {
    console.log(
      "defaultQueue",
      props.defaultQueue,
      selectedQueue,
      availableQueues
    );
    if (
      props.defaultQueue &&
      availableQueues?.map((queue) => queue.type).includes(props.defaultQueue)
    ) {
      setSelectedQueue(
        availableQueues.find((q) => q.type === props.defaultQueue)
      );
    }
  }, [props.defaultQueue, selectedQueue, availableQueues]);

  if (bonsaiQueuesLoading) return <div>Loading...</div>;

  return (
    <div className="mx-auto flex h-full w-full max-w-6xl flex-col space-y-4 p-4">
      {selectedQueue && (
        <BonsaiHeader
          setQueue={(queue) => setSelectedQueue(queue)}
          remainingTasks={availableTasksInSelectedQueue}
          backDisabled={props.defaultQueue !== undefined}
        />
      )}
      {selectedQueue ? (
        <BonsaiTaskWrapper
          selectedQueue={selectedQueue}
          setRemainingTasks={(remainingTasks) => {
            setAvailableTasksInSelectedQueue(remainingTasks);
          }}
        />
      ) : (
        <BonsaiQueuesMasterView
          bonsaiQueues={availableQueues}
          setSelectedQueue={setSelectedQueue}
          refetchBonsaiQueues={() => bonsaiQueuesRefetch()}
        />
      )}
    </div>
  );
}

interface BonsaiTaskWrapperProps {
  selectedQueue: BonsaiQueue;
  setRemainingTasks: (remainingTasks: number) => void;
}

function BonsaiTaskWrapper(props: BonsaiTaskWrapperProps) {
  const { data, loading, refetch } = useQuery<NextTaskQuery>(
    GET_NEXT_BONSAI_TASK,
    {
      variables: { queueName: props.selectedQueue.type },
    }
  );
  const [completeTask] = useMutation(COMPLETE_BONSAI_TASK);
  const remainingTasksInQueue = data?.getNextTask?.remainingTasksInQueue;
  const setRemainingTasks = props.setRemainingTasks;

  useEffect(() => {
    setRemainingTasks(remainingTasksInQueue);
  }, [setRemainingTasks, remainingTasksInQueue]);

  useEffect(() => {
    refetch();
  }, [props.selectedQueue]);

  const onSubmit = async (outcome: any) => {
    const outcomeString = JSON.stringify(outcome);
    await completeTask({
      variables: {
        queueName: props.selectedQueue.type,
        taskId: data.getNextTask.taskId,
        outcome: outcomeString,
      },
    });

    await refetch();
  };

  if (loading) return <div>Loading...</div>;

  return (
    <>
      {data.getNextTask ? (
        bonsaiRegistry(
          props.selectedQueue,
          onSubmit,
          data.getNextTask.metadata,
          data.getNextTask.taskId
        )
      ) : (
        <div className="flex h-screen items-center justify-center">
          <BonsaiEmptyState />
        </div>
      )}
    </>
  );
}

function BonsaiQueuesMasterView({
  bonsaiQueues,
  setSelectedQueue,
  refetchBonsaiQueues,
}) {
  return (
    <div className="space-y-4">
      <div className="flex justify-between">
        <div className="flex flex-col">
          <h1 className="text-xl font-semibold text-gray-900">Bonsai 🌳</h1>
          <p className="mt-2 text-sm text-gray-700">{getRandomHaiku()}</p>
        </div>
        <div
          className="flex items-end hover:cursor-pointer hover:text-kale-green-500"
          onClick={refetchBonsaiQueues}
        >
          <div className="flex items-center space-x-2">
            <Badge>
              <ArrowPathIcon className="h-5 w-5" />
            </Badge>
          </div>
        </div>
      </div>
      <div className="px-8">
        <BonsaiQueuesTable
          bonsaiQueues={bonsaiQueues}
          setSelectedQueue={setSelectedQueue}
        />
      </div>
    </div>
  );
}

function BonsaiQueuesTable({ bonsaiQueues, setSelectedQueue }) {
  return (
    <Table
      bleed
      className="rounded-md border [--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]"
    >
      <TableHead>
        <TableRow>
          <TableHeader>Queue</TableHeader>
          <TableHeader className="text-center">Remaining Tasks</TableHeader>
          <TableHeader>Oldest Task Age</TableHeader>
          <TableHeader>Average Task Age</TableHeader>
          <TableHeader>Active Admins</TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {bonsaiQueues.map((bonsaiQueue) => (
          <TableRow
            key={bonsaiQueue.type}
            className="hover:cursor-pointer hover:bg-kale-mint-300"
            onClick={() => setSelectedQueue(bonsaiQueue)}
          >
            <TableCell className="font-medium">
              <div className="flex space-x-2">
                {
                  <bonsaiQueue.icon
                    className="mr-3 h-6 w-6 flex-shrink-0 text-gray-500"
                    aria-hidden="true"
                  />
                }
                {bonsaiQueue.friendlyName}
              </div>
            </TableCell>
            <TableCell className="text-center">
              <Badge
                color={formatRemainingTasksBadgeColor(
                  bonsaiQueue.remainingTasks
                )}
              >
                <strong>{bonsaiQueue.remainingTasks}</strong>
              </Badge>
            </TableCell>
            <TableCell>{bonsaiQueue.oldestTaskCreationDate}</TableCell>
            <TableCell>{bonsaiQueue.averageTaskAge}</TableCell>
            <TableCell>
              <AdminIcons activeAdmins={bonsaiQueue.activeAdmins} />
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function AdminIcons({ activeAdmins }) {
  return (
    <div>
      <div className="flex -space-x-2">
        {activeAdmins?.map((activeAdmin) => (
          <Avatar
            initials={activeAdmin.name ? activeAdmin.name[0] : ""}
            title={activeAdmin.name}
            className="size-8 bg-kale-green-500 text-white ring-2 ring-white"
          />
        ))}
      </div>
    </div>
  );
}

const formatRemainingTasksBadgeColor = (remainingTasks) => {
  console.log(remainingTasks);
  if (remainingTasks == 0) {
    return "green";
  } else if (remainingTasks < 50) {
    return "amber";
  } else {
    return "red";
  }
};
