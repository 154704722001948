import { ChevronDownIcon } from "@heroicons/react/24/outline";
import { Dropdown, DropdownButton, DropdownMenu } from "../catalyst/dropdown";
import { BrandCategory } from "@kalecard/common";
import { Strong } from "../catalyst/text";
import { Switch, SwitchField } from "../catalyst/switch";
import { Label } from "../catalyst/fieldset";
import { useEffect, useState } from "react";
import { Checkbox, CheckboxField, CheckboxGroup } from "../catalyst/checkbox";
import { Badge } from "../catalyst/badge";

export default function BrandCategoryDropdown({
  brandCategories,
  selectedBrandCategories,
  setSelectedBrandCategories
}: {
  brandCategories: BrandCategory[],
  selectedBrandCategories: BrandCategory[],
  setSelectedBrandCategories: (selectedBrandCategories: BrandCategory[]) => void
}) {
  const [selectedCategories, setSelectedCategories] = useState(selectedBrandCategories);
  const [isAllCategoriesChecked, setIsAllCategoriesChecked] = useState(brandCategories.length > 0 && selectedBrandCategories.length > 0 && brandCategories.every((category) => selectedBrandCategories.includes(category)));

  const handleAllCategoriesToggle = () => {
    const updatedSelectedCategories = isAllCategoriesChecked ? [] : brandCategories;
    setSelectedBrandCategories(updatedSelectedCategories);
    setSelectedCategories(updatedSelectedCategories);
    setIsAllCategoriesChecked(!isAllCategoriesChecked);
  }

  const isCategoryChecked = (category: BrandCategory) => {
    return selectedBrandCategories.find((selectedCategory) => selectedCategory.id === category.id) != null;
  }

  const handleCategoryToggle = (category: BrandCategory) => {
    const updatedSelectedCategories = isCategoryChecked(category)
      ? selectedBrandCategories.filter((selectedCategory) => selectedCategory.id !== category.id)
      : [...selectedBrandCategories, category];

    setSelectedBrandCategories(updatedSelectedCategories);
    setSelectedCategories(updatedSelectedCategories);
  }

  const handleOnlyCategoryClick = (category: BrandCategory) => {
    const updatedSelectedCategories = brandCategories.filter((brandCategory) => brandCategory.id === category.id);
    setSelectedBrandCategories(updatedSelectedCategories);
    setSelectedCategories(updatedSelectedCategories);
  }

  useEffect(() => {
    const allCategoriesChecked = brandCategories.length > 0 && selectedCategories.length > 0 && brandCategories.every((category) => selectedCategories.find((selectedCategory) => selectedCategory.id === category.id) != null);
    setIsAllCategoriesChecked(allCategoriesChecked);
  }, [selectedCategories]);

  return (
    <Dropdown>
      <DropdownButton outline className="bg-white">
        <ChevronDownIcon className="h-4 w-4" />
      </DropdownButton>
      <DropdownMenu>
        <div className="flex flex-col p-2 space-y-2">
          <div>
            <SwitchField>
              <Label><Strong>Select all categories</Strong></Label>
              <Switch name="all_categories" color="emerald" checked={isAllCategoriesChecked} onClick={handleAllCategoriesToggle}></Switch>
            </SwitchField>
          </div>
          <div>
            <CheckboxGroup>
              {brandCategories.map((brandCategory) => (
                <CheckboxFieldWithHover brandCategory={brandCategory} isCategoryChecked={isCategoryChecked} handleCategoryToggle={handleCategoryToggle} handleOnlyCategoryClick={handleOnlyCategoryClick} />
              ))}
            </CheckboxGroup>
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
}

function CheckboxFieldWithHover({ brandCategory, isCategoryChecked, handleCategoryToggle, handleOnlyCategoryClick }) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <CheckboxField
      key={brandCategory.id}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Checkbox
        name={brandCategory.name}
        color="emerald"
        checked={isCategoryChecked(brandCategory)}
        onClick={() => handleCategoryToggle(brandCategory)}
      />
      <div className="flex justify-between">
        <Label>{brandCategory.name}</Label>
        {isHovered && <Badge color="emerald" className="hover:cursor-pointer" onClick={() => handleOnlyCategoryClick(brandCategory)}>Only</Badge>}
      </div>
    </CheckboxField>
  );
}