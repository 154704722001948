import { useMutation } from "@apollo/client";
import { Deal, Spinner } from "@kalecard/common";
import { useState } from "react";
import { UNDO_DEAL } from "../graphql/mutations";

export default function RefundDealComponent({
  deal,
  updateDeal,
  setDealState
}: {
  deal: Deal,
  updateDeal?: Function;
  setDealState?: (dealState: string) => void
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [shouldRefund, setShouldRefund] = useState(true);
  const [undoDeal] = useMutation(UNDO_DEAL);

  return (
    <div>
      <div className="flex space-x-2">
        <button
          type="submit"
          disabled={isLoading}
          className="inline-flex items-center rounded-md border border-transparent bg-red-600 px-2 text-sm font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 disabled:bg-red-700"
          onClick={async () => {
            setIsLoading(true);
            try {
              const result = await undoDeal({
                variables: {
                  dealId: deal.id,
                  shouldRefund: shouldRefund,
                },
              });
              if (result.data.undoDeal) {
                const newDeal: Deal = {
                  ...deal,
                };
                newDeal.state = "UNDONE";
                newDeal.rewardAmount = deal.rewardAmount * -1;
                updateDeal && updateDeal(newDeal);
                setDealState && setDealState(newDeal.state);
              }
              console.log(result);
            } catch (err) {
              console.error(err);
            } finally {
              setIsLoading(false);
            }
          }}
        >
          Refund
        </button>
        {isLoading && <Spinner size="h-5 w-5" />}
      </div>
      <div className="pt-2">
        <input
          id="shouldRefund"
          onChange={(e) => setShouldRefund(e.target.checked)}
          name="shouldRefund"
          type="checkbox"
          defaultChecked={shouldRefund}
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
        />
        <label className="pl-2 text-sm ">Credit the brand</label>
      </div>
    </div>
  );
}