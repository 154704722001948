import React from "react";
import { BonsaiQueue } from "../BonsaiModels";
import { BonsaiActionPanel } from "../BonsaiContainer";
import { BonsaiPanel } from "@kalecard/common";
import { Strong, Text } from "../../catalyst/text";
import { Link } from "../../catalyst/link";
import { TemporalDownloadPostMediaWorkflowLink } from "../../Links";

interface ManualDownloadToolInput {
  postId: number;
  dealId: number;
  creatorId: string;
  externalPermalink: string;
}

interface ManualDownloadToolOutput {}

interface ManualDownloadToolProps {
  queue: BonsaiQueue;
  onComplete: (outcome: ManualDownloadToolOutput) => void;
  readOnly: boolean;
  taskId: number;
  input: ManualDownloadToolInput;
}

export function ManualDownloadTool(props: ManualDownloadToolProps) {
  console.log(props);
  return (
    <>
      <div>
        <BonsaiPanel>
          <div className="flex h-full flex-col justify-between p-4">
            <div className="text-md text-center font-semibold text-slate-700">
              Manual Download Information
            </div>
            <Text>
              <Strong>Post ID:</Strong> {props.input.postId}
            </Text>
            <Text>
              <Strong>Deal ID:</Strong> {props.input.dealId}
            </Text>
            <Text>
              <Strong>Workflow Link: </Strong>{" "}
              <TemporalDownloadPostMediaWorkflowLink
                postId={props.input.postId.toString()}
              />
            </Text>
            <Text>
              <Strong>Creator ID:</Strong>{" "}
              <Link
                target="_blank"
                className="text-sm text-kale-green-500 underline hover:text-kale-green-400"
                href={`/creators/${props.input.creatorId}/deals`}
              >
                {props.input.creatorId}
              </Link>
            </Text>
            <Text>
              <Strong>External Link:</Strong>{" "}
              <Link
                target="_blank"
                className="text-sm text-kale-green-500 underline hover:text-kale-green-400"
                href={props.input.externalPermalink}
              >
                {props.input.externalPermalink}
              </Link>
            </Text>
            <Text>
              <Strong>Creator Media Link:</Strong>{" "}
              <Link
                target="_blank"
                className="text-sm text-kale-green-500 underline hover:text-kale-green-400"
                href={`/creators/${props.input.creatorId}/post?dealId=${props.input.dealId}`}
              >
                Upload Here
              </Link>
            </Text>
          </div>
        </BonsaiPanel>
      </div>
      {!props.readOnly && (
        <BonsaiActionPanel
          submitDisabled={false}
          onSubmit={() => {
            props.onComplete({});
          }}
          taskDescription={props.queue.taskDescription}
          taskId={props.taskId}
        />
      )}
    </>
  );
}
