import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Spinner } from "@kalecard/common";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGoogle } from "@fortawesome/free-brands-svg-icons";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

export type SignInInputs = {};

interface SignInFormProps {
  submitForm: () => Promise<[string | null, boolean]>;
}

export default function SignInForm({ submitForm }: SignInFormProps) {
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    formState: { errors },
  } = useForm<SignInInputs>();

  const onSubmit: SubmitHandler<SignInInputs> = async () => {
    setIsLoading(true);
    const [errorMessage, success] = await submitForm();
    if (success) {
    } else {
      setIsLoading(false);
      setError(errorMessage);
    }
  };

  return (
    <div className="mx-auto w-11/12 rounded px-4 py-8 md:w-2/4 md:px-8">
      {error !== null && (
        <div className="mb-3 w-full bg-red-600 py-4 text-center text-white">
          {error}
        </div>
      )}
      <form onSubmit={handleSubmit(onSubmit)}>
        <button className="mx-auto flex items-center space-x-4 rounded-full border border-kale-green-500 bg-kale-mint-500 px-4 py-2">
          <FontAwesomeIcon icon={faGoogle as IconProp} />
          <p>Log in with Google</p>
        </button>
        {isLoading && (
          <div className="mt-2 flex flex-wrap content-center justify-center">
            <Spinner size="h-6 w-6" />
          </div>
        )}
      </form>
    </div>
  );
}
