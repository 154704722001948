import { CheckCircleIcon, MinusCircleIcon } from "@heroicons/react/24/solid";
import { Creator, numberWithCommas, timeDifferenceForDate } from "@kalecard/common";
import { useHistory } from "react-router-dom";
import DealStateComponent from "../../DealStateComponent";

interface CreatorComponentInterface {
  creator: Creator;
}

export default function CreatorListComponent(props: CreatorComponentInterface) {
  const history = useHistory();
  const creatorPage = `/creators/${props.creator.id}/`;

  const navigateToCreator = () => {
    history.push(creatorPage);
  };

  return (
    <tr onClick={navigateToCreator} className="cursor-pointer hover:bg-gray-50">
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div className="flex flex-col space-y-1">
          {props.creator?.externalUsers?.map((externalUser) => {
            return (
              <div key={externalUser.id}>
                <div className="flex items-center space-x-1">
                  <img
                    className={`${props.creator?.externalUsers.length > 1
                        ? "h-5 w-5"
                        : "h-12 w-12"
                      } rounded-full`}
                    src={externalUser?.pictureUrl}
                    alt=""
                  />
                  <div className="flex items-center font-medium">
                    {externalUser.username} -{" "}
                    {externalUser.externalPlatform == "TIKTOK" ? "TT" : "IG"}
                    <span className="font-bold">
                      {externalUser.isPrivate && " - Private"}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <a href={creatorPage}>{props.creator.phoneNumber}</a>
        <div className="text-xs text-gray-500">{props.creator.name}</div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div className="flex flex-col text-center">
          {props.creator?.externalUsers?.map((externalUser) => {
            return (
              <span key={externalUser.id}>
                {numberWithCommas(externalUser.followers)}
              </span>
            );
          })}
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div className="flex flex-col items-start space-y-1">
          <div>
            <DealStateComponent state={props.creator?.activeDeal?.state} />
          </div>
          <div>
            {props.creator.lastDealTimestamp
              ? `Offered: ${timeDifferenceForDate(
                Number(props.creator.lastDealTimestamp)
              )}`
              : "No deals"}
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div className="flex flex-col">
          <div className="flex">
            Plaid{" "}
            <span>
              {props.creator.creatorSettings?.isPlaidActive ? (
                <CheckCircleIcon
                  className="ml-2 -mr-0.5 h-4 w-4 text-green-500"
                  aria-hidden="true"
                />
              ) : (
                <MinusCircleIcon
                  className="ml-2 -mr-0.5 h-4 w-4 text-red-500"
                  aria-hidden="true"
                />
              )}
            </span>
          </div>
          <div className="flex">
            Instagram{" "}
            <span>
              {props.creator.creatorSettings?.isInstagramConnected ? (
                <CheckCircleIcon
                  className="ml-2 -mr-0.5 h-4 w-4 text-green-500"
                  aria-hidden="true"
                />
              ) : (
                <MinusCircleIcon
                  className="ml-2 -mr-0.5 h-4 w-4 text-red-500"
                  aria-hidden="true"
                />
              )}
            </span>
          </div>
          <div className="flex">
            Tiktok{" "}
            <span>
              {props.creator.creatorSettings?.isTiktokConnected ? (
                <CheckCircleIcon
                  className="ml-2 -mr-0.5 h-4 w-4 text-green-500"
                  aria-hidden="true"
                />
              ) : (
                <MinusCircleIcon
                  className="ml-2 -mr-0.5 h-4 w-4 text-red-500"
                  aria-hidden="true"
                />
              )}
            </span>
          </div>
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div>{props.creator.referralCode}</div>
      </td>
    </tr>
  );
}