import {
  BonsaiContextLinkType,
  BonsaiPanel,
  BonsaiVideoPanel,
  bonsaiContextLinkTypeForExternalUrl,
} from "@kalecard/common";
import { BonsaiQueue } from "../BonsaiModels";
import { BonsaiActionPanel } from "../BonsaiContainer";
import { Strong, Text, TextLink } from "../../catalyst/text";

interface BrandRejectionReviewToolInput {
  videoUrl: string;
  caption: string;
  creatorId: string;
  brandName: string;
  rejectionReason: string;
  originalReviewerInfo: string;
  challengeId: number;
  challengeTitle?: string;
  challengeDescription?: string;

  // The following fields are specific to posts
  postExternalPlatform?: string;
  postExternalUrl?: string;
  postedBy?: string;
  likes?: number;
  comments?: number;
  impressions?: number;
}

interface BrandRejectionReviewToolOutput {}

interface BrandRejectionReviewToolProps {
  queue: BonsaiQueue;
  onComplete: (outcome: BrandRejectionReviewToolOutput) => void;
  readOnly: boolean;
  taskId: number;
  input: BrandRejectionReviewToolInput;
}

export function BrandRejectionReviewTool(props: BrandRejectionReviewToolProps) {
  return (
    <>
      <div className="flex flex-row gap-4">
        <div className="flex-1">
          <BonsaiVideoPanel
            source={props.input.videoUrl}
            links={[
              ...(props.input.postExternalUrl
                ? [
                    {
                      type: bonsaiContextLinkTypeForExternalUrl(
                        props.input.postExternalUrl
                      ),
                      url: props.input.postExternalUrl,
                    },
                  ]
                : []),
              {
                type: BonsaiContextLinkType.CREATOR,
                url: `/creators/${props.input.creatorId}/messages`,
              },
            ]}
            autoPlay={false}
            title="Rejected Video"
          />
        </div>
        <div className="flex-1">
          <BonsaiPanel>
            <div className="flex h-full flex-col justify-between p-4">
              <div className="flex-row justify-center space-y-4">
                <div className="text-md text-center font-semibold text-slate-700">
                  Review Information
                </div>
                <Text>
                  <Strong>Brand:</Strong> {props.input.brandName}
                </Text>
                <Text>
                  <Strong>Challenge (id: {props.input.challengeId}):</Strong>{" "}
                  {props.input.challengeTitle
                    ? props.input.challengeTitle + ": "
                    : ""}
                  {props.input.challengeDescription}
                </Text>
                <div className={"h-0.5"} />

                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm font-semibold text-slate-700">
                      Post Details
                    </span>
                  </div>
                </div>
                <Text>
                  <Strong>Caption:</Strong> {props.input.caption}
                </Text>
                {props.input.postedBy ? (
                  <Text>
                    <Strong>Posted By:</Strong> {props.input.postedBy}
                  </Text>
                ) : null}
                {props.input.likes ? (
                  <Text>
                    <Strong>Likes:</Strong> {props.input.likes}
                  </Text>
                ) : null}
                {props.input.comments ? (
                  <Text>
                    <Strong>Comments:</Strong> {props.input.comments}
                  </Text>
                ) : null}
                {props.input.impressions ? (
                  <Text>
                    <Strong>Impressions:</Strong> {props.input.impressions}
                  </Text>
                ) : null}

                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm font-semibold text-slate-700">
                      Rejection Information
                    </span>
                  </div>
                </div>
                <Text>
                  <Strong>Reviewed By:</Strong>{" "}
                  {props.input.originalReviewerInfo}
                </Text>
                <Text>
                  <Strong>Rejection Reason:</Strong>{" "}
                  {props.input.rejectionReason}
                </Text>
              </div>
            </div>
          </BonsaiPanel>
        </div>
      </div>
      {!props.readOnly && (
        <BonsaiActionPanel
          submitDisabled={false}
          onSubmit={() => {
            props.onComplete({});
          }}
          taskDescription={props.queue.taskDescription}
          taskId={props.taskId}
        />
      )}
    </>
  );
}
