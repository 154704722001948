import { Description, Field, Label } from '../../catalyst/fieldset';
import { Radio, RadioField, RadioGroup } from '../../catalyst/radio';

export function BrandSettingsField({
  label,
  description,
  name,
  value,
  setValue,
}) {
  return (
    <Field>
      <div className="flex items-center justify-between">
        <div>
          <Label>{label}</Label>
          <Description className="pr-8">{description}</Description>
        </div>

        <BrandSettingsRadioGroup
          name={name}
          value={value}
          setValue={setValue}
        />
      </div>
    </Field>
  );
}

function BrandSettingsRadioGroup({ name, value, setValue }) {
  const handleChange = (val) => {
    const newValue = val === 'on';
    setValue(name, newValue);
  };

  const defaultValue = value === true ? 'on' : value === false ? 'off' : null;
  return (
    <RadioGroup
      name={name}
      defaultValue={defaultValue}
      onChange={handleChange}
    >
      <div className="flex flex-col">
        <RadioField>
          <Label>On</Label>
          <Radio
            value="on"
            color="emerald"
          />
        </RadioField>
        <RadioField>
          <Label>Off</Label>
          <Radio
            value="off"
            color="zinc"
          />
        </RadioField>
      </div>
    </RadioGroup>
  );
}
