import { useMutation } from "@apollo/client";
import { useForm } from "react-hook-form";
import { SET_POST_QUALITY } from "../../graphql/mutations";

interface QualityScoreFormInterface {
  postId: string;
  score: number;
  isDisabled?: boolean;
  isSecondaryScore?: boolean;
  dealId?: string;
}

export default function QualityScoreForm({
  postId,
  score,
  isDisabled = false,
  isSecondaryScore = false,
  dealId,
}: QualityScoreFormInterface) {
  const { register, setValue, handleSubmit } = useForm({
    defaultValues: {
      score: score ? score : -1,
    },
  });
  const [setPostQuality] = useMutation(SET_POST_QUALITY);

  const onSubmit = async (data) => {
    if (data.score > 0) {
      console.log(data);
      await setPostQuality({
        variables: {
          postId,
          score: data.score,
          isSecondary: isSecondaryScore,
          dealId,
        },
      });
    }
  };

  const handleChange = (e) => {
    setValue("score", e.target.value);
    handleSubmit(onSubmit)();
  };

  return (
    <>
      <div>
        <label
          htmlFor="location"
          className="block text-sm font-medium text-gray-700"
        >
          {isSecondaryScore ? "Secondary" : "Primary"}
        </label>
        <select
          disabled={isDisabled}
          {...register("score")}
          id="score"
          name="score"
          className={`${
            isDisabled ? " text-gray-400 opacity-60" : ""
          } mt-1 block rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm`}
          onChange={handleChange}
        >
          <option value={-1}>-</option>
          <option value={100}>1</option>
          <option value={200}>2</option>
          <option value={300}>3</option>
          <option value={400}>4</option>
          <option value={500}>5</option>
          <option value={600}>6</option>
          <option value={700}>7</option>
          <option value={800}>8</option>
          <option value={900}>9</option>
          <option value={1000}>10</option>
        </select>
      </div>
    </>
  );
}
