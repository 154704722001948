import SignInForm from "../components/forms/SignInForm";
import firebase from "firebase/app";
import { auth } from "../config/firebase";

const SignIn = () => {
  const signIn = async (): Promise<[string, boolean]> => {
    try {
      const provider = new firebase.auth.GoogleAuthProvider();
      auth.tenantId = process.env.REACT_APP_FIREBASE_ADMIN_TENANT_ID;
      await auth.signInWithPopup(provider);
      return [null, true];
    } catch (error) {
      console.error(error);
      return ["Error signing in with password and email", false];
    }
  };

  return (
    <div className="mt-8">
      <h1 className="mb-2 text-center text-3xl font-bold">Sign In</h1>
      <SignInForm submitForm={signIn} />
    </div>
  );
};
export default SignIn;
