import { useMutation } from "@apollo/client";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Spinner } from "@kalecard/common";
import { UPDATE_POST_METRICS } from "../../graphql/mutations";

type UpdatePostMetricsFormInputs = {
  impressions: string;
  likes: string;
  comments: string;
};

interface UpdatePostMetricsInterface {
  postId: string;
  onSubmit: () => void;
}

export default function UpdatePostMetricsForm(
  props: UpdatePostMetricsInterface
) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [isFirstMetricsRecording, setIsFirstMetricsRecording] = useState(false);
  const [updatePostMetrics] = useMutation(UPDATE_POST_METRICS);
  const { reset, register, handleSubmit } =
    useForm<UpdatePostMetricsFormInputs>();

  const onSubmit: SubmitHandler<UpdatePostMetricsFormInputs> = async (data) => {
    setIsLoading(true);
    const variables = {
      postId: props.postId,
      impressions: data.impressions,
      likes: data.likes,
      comments: data.comments,
      isFirstMetricsRecording,
    };
    try {
      const result = await updatePostMetrics({ variables });
      console.log(result);
      if (result.data.updatePostMetrics) {
        setError(null);
        reset();
        props.onSubmit();
      } else {
        // Set an error
        console.error("Error updating post metrics");
        setError(
          "Something went wrong, try refreshing this page or try again later."
        );
      }
    } catch (err) {
      console.error(err);
      setError("Something went wrong, please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form>
        <div className="mt-1">
          <div className="flex flex-col">
            <div className="mt-1 flex space-x-4">
              <div>
                <label
                  htmlFor="impressions"
                  className="block text-sm font-medium text-gray-700"
                >
                  Impressions
                </label>
                <input
                  type="text"
                  name="impressions"
                  {...register("impressions", { required: true })}
                  id="impressions"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div>
                <label
                  htmlFor="Likes"
                  className="block text-sm font-medium text-gray-700"
                >
                  Likes
                </label>
                <input
                  type="text"
                  name="likes"
                  {...register("likes", { required: true })}
                  id="likes"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>

              <div>
                <label
                  htmlFor="Comments"
                  className="block text-sm font-medium text-gray-700"
                >
                  Comments
                </label>
                <input
                  type="text"
                  name="comments"
                  {...register("comments", { required: true })}
                  id="comments"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                />
              </div>
              <div className="flex flex-col">
                <fieldset className="">
                  <div className="relative flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        id="first"
                        aria-describedby="comments-description"
                        name="first"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                        onChange={(e) =>
                          setIsFirstMetricsRecording(e.target.checked)
                        }
                      />
                    </div>
                    <div className="ml-3 pb-2 text-sm">
                      <label
                        htmlFor="comments"
                        className="font-medium text-gray-700"
                      >
                        First Metrics Recording
                      </label>
                    </div>
                  </div>
                </fieldset>
                <button
                  type="button"
                  onClick={handleSubmit(onSubmit)}
                  disabled={isLoading}
                  className="mr-2 justify-center rounded-md border border-transparent bg-indigo-600 px-2 py-2 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-400"
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="pt-1">
          {isLoading && (
            <div className="flex flex-wrap content-center justify-center">
              <Spinner size="h-6 w-6" />
            </div>
          )}
        </div>
        <div className="mt-2 flex justify-end">
          <p className="font-bold text-red-500">{error}</p>
        </div>
      </form>
    </>
  );
}
