import { useQuery } from "@apollo/client";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "../catalyst/table";
import { ADMIN_PERMISSIONS, AdminPermissionsInterface } from "../../graphql/queries";
import _ from "lodash";
import { FormattedRole } from "./AdminRole";
import { EditAdminPermissionForm, NewAdminPermissionForm } from "../forms/AdminPermissionForm";
import { useState } from "react";
import { AdminPermission, Modal, Spinner } from "@kalecard/common";
import { PlusCircleIcon } from "@heroicons/react/24/outline";

export default function AdminPermissionsTable() {
  const { data, loading, refetch } =
    useQuery<AdminPermissionsInterface>(ADMIN_PERMISSIONS);

  const [currentAdminPermission, setCurrentAdminPermission] = useState(null);
  const [isEditAdminPermissionOpen, setIsEditAdminPermissionOpen] = useState(false);
  const [currentPermissionGroup, setCurrentPermissionGroup] = useState(null);
  const [isNewAdminPermissionOpen, setIsNewAdminPermissionOpen] = useState(false);

  const handleEditAdminPermissionClick = (adminPermission: AdminPermission) => {
    setCurrentAdminPermission(adminPermission);
    setIsEditAdminPermissionOpen(true);
  }

  const handleCloseEditAdminPermission = () => {
    setIsEditAdminPermissionOpen(false);
  }

  const handleNewPermissionClick = (groupName: string) => {
    setCurrentPermissionGroup(groupName);
    setIsNewAdminPermissionOpen(true);
  }

  return (
    !loading
      ? (
        <>
      <Table className="border rounded-md p-4">
        {/* Headers */}
        <TableHead>
          <TableRow className="text-black">
            <TableHeader>Permission</TableHeader>
            <TableHeader>Description</TableHeader>
            <TableHeader>Roles</TableHeader>
            <TableHeader></TableHeader>
          </TableRow>
        </TableHead>

        {/* Table Body */}
        <TableBody>
          {_.chain(data?.adminPermissions)
            .groupBy('groupName')
            .map((permissions, groupName) => [
              <TableRow key={groupName}>
                <TableCell colSpan={4} className="bg-gray-100 font-medium capitalize">
                  <div className="flex items-center justify-between">
                  <p>{`${groupName.toLowerCase()} permissions`}</p>
                  <PlusCircleIcon className="h-5 w-5 text-kale-green-500 hover:cursor-pointer" onClick={() => handleNewPermissionClick(groupName)}/>
                  </div>
                  </TableCell>
              </TableRow>,
              ...permissions.map((adminPermission) => (
                <TableRow key={adminPermission.id}>
                  <TableCell>{adminPermission.permissionName}</TableCell>
                  <TableCell>{adminPermission.description}</TableCell>
                  <TableCell>
                    <div className="flex space-x-2">
                    {adminPermission.roles.map((role) => (
                      <FormattedRole role={role} key={adminPermission.id + role} />
                    ))}
                    </div>
                  </TableCell>
                  <TableCell className="font-medium text-kale-green-500 hover:cursor-pointer" onClick={() => handleEditAdminPermissionClick(adminPermission)}>Edit</TableCell>
                </TableRow>
              ))
            ])
            .flatten()
            .value()}
        </TableBody>
      </Table>
      <Modal
        isModalOpen={isEditAdminPermissionOpen}
        onClose={handleCloseEditAdminPermission}
      >
        <EditAdminPermissionForm adminPermission={currentAdminPermission} onSubmit={handleCloseEditAdminPermission} />
      </Modal>
      <Modal
        isModalOpen={isNewAdminPermissionOpen}
        onClose={() => setIsNewAdminPermissionOpen(false)}
      >
        <NewAdminPermissionForm groupName={currentPermissionGroup} onSubmit={() => setIsNewAdminPermissionOpen(false)} />
      </Modal>
      </>
      )
      : <div className="flex flex-wrap content-center justify-center pr-2">
        <Spinner size="h-6 w-6" />
      </div>
  );
}