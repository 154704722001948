import { Score } from "@kalecard/common";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { ScoreContext } from "../../providers/ScoreProvider";
import { ScoreActionType } from "../../reducers/ScoreReducer";
import { useMutation } from "@apollo/client";
import { UPSERT_POST_SCORE } from "../../graphql/mutations";

type ScoreInputs = {
  id?: string;
  description?: string;
  inverseDescription?: string;
  helpText?: string;
  points?: number;
  isActive: boolean;
  brandId?: string;
};

export default function ScoreComponent({
  score,
  scoreIdx,
}: {
  score: Score;
  scoreIdx: number;
}) {
  const [isUpdateView, setIsUpdateView] = useState<boolean>(false);

  return (
    <>
      <tr className={scoreIdx % 2 === 0 ? undefined : "bg-gray-50"}>
        {isUpdateView ? (
          <ScoreUpdateView score={score} setIsUpdateView={setIsUpdateView} />
        ) : (
          <>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
              {score.description}
            </td>
            <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
              {score.inverseDescription}
            </td>
            <td className="w-96 px-3 py-4 text-sm text-gray-500">
              {score.helpText}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              {score.points}
            </td>
            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
              <div className="flex justify-center">
                <input
                  type="checkbox"
                  name="isActive"
                  id="isActive"
                  checked={score.isActive}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  disabled={true}
                />
              </div>
            </td>
            <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
              <a
                href="#"
                className="text-indigo-600 hover:text-indigo-900"
                onClick={() => {
                  setIsUpdateView(true);
                }}
              >
                Edit<span className="sr-only">, {score.description}</span>
              </a>
            </td>
          </>
        )}
      </tr>
    </>
  );
}

export function ScoreUpdateView({
  score,
  setIsUpdateView = () => {
    /* noop */
  },
  isNew = false,
}: {
  score?: Score;
  setIsUpdateView?: (isUpdateView: boolean) => void;
  isNew?: boolean;
}) {
  const [upsertPostScore] = useMutation(UPSERT_POST_SCORE);
  const { dispatch } = useContext(ScoreContext);
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ScoreInputs>({
    defaultValues: {
      id: score?.id,
      description: score?.description,
      inverseDescription: score?.inverseDescription,
      helpText: score?.helpText,
      points: score?.points,
      isActive: score?.isActive === true,
    },
  });

  const onSubmit = (data: ScoreInputs) => {
    upsertPostScore({
      // @ts-ignore
      variables: {
        ...data,
      },
    })
      .then((res) => {
        if (isNew) {
          dispatch({
            type: ScoreActionType.ADD,
            score: {
              ...res.data.upsertPostScore,
              isActive: data.isActive,
            },
          });
          reset({
            id: null,
            description: null,
            inverseDescription: null,
            helpText: null,
            points: null,
            isActive: false,
          });
        } else {
          dispatch({ type: ScoreActionType.UPDATE, score: data });
        }
        setIsUpdateView(false);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-3">
        <input
          type="text"
          name="description"
          {...register("description", { required: true })}
          id="description"
          placeholder="A simple description"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
        />
      </td>
      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm sm:pl-3">
        <input
          type="text"
          name="inverseDescription"
          {...register("inverseDescription")}
          id="inverseDescription"
          placeholder="Inverse of the description"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
        />
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        <textarea
          rows={2}
          maxLength={120}
          name="helpText"
          {...register("helpText", { required: true })}
          id="helpText"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
        />
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        <input
          type="number"
          onWheel={(e) => (e.target as HTMLElement).blur()}
          step={1}
          min={0}
          max={700}
          name="points"
          {...register("points", {
            required: true,
            validate: (value) =>
              (value >= -500 && value <= 700) ||
              "Points must be between -500 and 700",
          })}
          id="points"
          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
        />
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm">
        <div className="flex justify-center">
          <input
            type="checkbox"
            name="isActive"
            {...register("isActive")}
            id="isActive"
            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
          />
        </div>
      </td>
      <td className="whitespace-nowrap text-sm font-medium sm:pr-3">
        <div className="flex justify-end space-x-2 text-right">
          <button
            className="text-green-600 hover:text-green-900"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          >
            {isNew ? "Save" : "Update"}
          </button>
          {!isNew && (
            <button
              className="text-red-600 hover:text-red-900"
              onClick={() => {
                reset();
                setIsUpdateView(false);
              }}
            >
              Cancel
            </button>
          )}
        </div>
      </td>
    </>
  );
}
