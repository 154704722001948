import { gql } from "../__generated__";

export const SAGE_SETTINGS_FRAGMENT = gql(`
  fragment SageSettingsFragment on SageSettings {
     id
      details {
        contentBudget
        predictedOutstanding
        challengeBudgetAllocation
        targetBudget
        effectiveBudget
      }
      challengeBudgets {
        challengeId
        title 
        description
        allocatedBudget
        utilizedBudget
      }
      availableChallenges {
        id
        title
        description
      }
      schedule {
        updatedAt
        events {
          id
          type
          endAt
          startAt
          endBudget
        }
      }
    }
`);
