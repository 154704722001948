import { useMutation } from "@apollo/client";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Spinner } from "@kalecard/common";
import { UPDATE_CREATOR_ACCOUNT_STATE } from "../../graphql/mutations";

const types = [
  { name: "Fake Impressions", value: "FAKE_IMPRESSIONS" },
  { name: "Stolen Content", value: "STOLEN_CONTENT" },
  { name: "Spam Account", value: "SPAM_ACCOUNT" },
];

type BlockCreatorFormInputs = {
  creatorId: string;
  type: string;
  notes: string;
};

interface BlockCreatorFormInterface {
  creatorId: string;
  blocked?: () => void;
}

export default function BlockCreatorForm(props: BlockCreatorFormInterface) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [updateCreatorAccountState] = useMutation(UPDATE_CREATOR_ACCOUNT_STATE);
  const { reset, register, handleSubmit } = useForm<BlockCreatorFormInputs>();

  const onSubmit: SubmitHandler<BlockCreatorFormInputs> = async (data) => {
    setIsLoading(true);
    const accountState =
      data.type !== "BLOCKED_NEW_SOCIAL_ACCOUNT"
        ? "BLOCKED_BAD_ACTOR"
        : "BLOCKED_NEW_SOCIAL_ACCOUNT";
    const accountStateType =
      data.type !== "BLOCKED_NEW_SOCIAL_ACCOUNT" ? data.type : null;
    const variables = {
      creatorId: props.creatorId,
      accountState: accountState,
      accountStateType: accountStateType,
      reason: data.notes,
    };
    try {
      const result = await updateCreatorAccountState({ variables });
      console.log(result);
      if (result.data.updateCreatorAccountState) {
        setError(null);
        reset();
        props.blocked();
      } else {
        // Set an error
        console.error("Error updating creator account state.");
        setError(
          "Something went wrong, try refreshing this page or try again later."
        );
      }
    } catch (err) {
      console.error(err);
      setError("Something went wrong, please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form>
        <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
          <div className="sm:col-span-2">
            <label
              htmlFor="name"
              className="block text-sm font-medium text-gray-700"
            >
              Reason for Blocking
            </label>
            <div className="mt-1">
              <select
                id="current-tab"
                name="current-tab"
                className="block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                defaultValue={types[0].value}
                {...register("type")}
              >
                {types.map((type) => (
                  <option key={type.name} value={type.value}>
                    {type.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="sm:col-span-4">
            <label
              htmlFor="notes"
              className="block text-sm font-medium text-gray-700"
            >
              Notes
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="notes"
                {...register("notes", { required: false })}
                id="notes"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-5">
          {isLoading && (
            <div className="flex flex-wrap content-center justify-center pr-2">
              <Spinner size="h-6 w-6" />
            </div>
          )}
          <div className="flex justify-end">
            <button
              type="button"
              onClick={handleSubmit(onSubmit)}
              disabled={isLoading}
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-400"
            >
              Block Creator
            </button>
          </div>
        </div>
        <div className="mt-2 flex justify-end">
          <p className="font-bold text-red-500">{error}</p>
        </div>
      </form>
    </>
  );
}
