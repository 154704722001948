import { useMutation } from '@apollo/client';
import { ChallengeFeedback, timeDifferenceForDate } from '@kalecard/common';
import { UDPATE_CHALLENGE_FEEDBACK } from '../../graphql/mutations';
import { useContext, useState } from 'react';
import { ChallengesContext } from '../../providers/ChallengesProvider';
import { ChallengesActionType } from '../../reducers/ChallengesReducer';
import { CheckIcon } from '@heroicons/react/24/solid';
import { classNames } from '../../utils/style';
import { Challenge } from '../../__generated__/graphql';

export default function ChallengeFeedbackForm({
  challenge,
}: {
  challenge: Challenge;
}) {
  const [updateChallengeFeedback] = useMutation(UDPATE_CHALLENGE_FEEDBACK);
  const [error, setError] = useState(null);

  const { dispatch: challengesDispatch } = useContext(ChallengesContext);

  const markAsComplete = async (challengeFeedback: ChallengeFeedback) => {
    try {
      const result = await updateChallengeFeedback({
        variables: {
          challengeFeedbackId: challengeFeedback.id,
          status: 'ADDRESSED',
        },
      });
      console.log(result);
      if (result.data.updateChallengeFeedback) {
        const updatedChallengeFeedback = challenge.feedback?.map((feedback) => {
          if (feedback.id === challengeFeedback.id) {
            return { ...feedback, status: 'ADDRESSED' };
          } else {
            return feedback;
          }
        });
        challengesDispatch({
          type: ChallengesActionType.UPDATE_CHALLENGE,
          payload: {
            ...challenge,
            feedback: updatedChallengeFeedback,
          },
        });
      } else {
        setError('Failed to update challenge feedback. Try again.');
      }
    } catch (err) {
      console.log(err);
      setError('Failed to update challenge feedback. Try again.');
    }
  };

  const newFeedback = challenge.feedback?.filter(
    (feedback) => feedback.status === 'NEW'
  );
  const oldFeedback = challenge.feedback?.filter(
    (feedback) => feedback.status !== 'NEW'
  );

  return (
    challenge.feedback?.length > 0 && (
      <div>
        <p className="text-md pb-2 font-medium">Feedback:</p>
        <div className="flex flex-col space-y-2 divide-y">
          {newFeedback.map((feedback, index) => {
            return (
              <div
                key={feedback.id}
                className={classNames(
                  index > 0 ? 'pt-2' : '',
                  'flex flex-col bg-blue-100 p-2'
                )}
              >
                <p className="text-sm font-medium text-gray-900">
                  {feedback.feedback}
                </p>
                <div className="flex justify-between">
                  <div>
                    {feedback.createdAt && (
                      <p className="pt-2 text-xs text-gray-500">
                        {timeDifferenceForDate(Number(feedback.createdAt))}
                      </p>
                    )}
                    {feedback.actorName && (
                      <p className="text-xs text-gray-500">
                        {feedback.actorName}
                      </p>
                    )}
                  </div>
                  <div
                    className="inline-flex items-center justify-center space-x-2 rounded-md border border-indigo-600 border-transparent bg-white px-2 py-1 text-xs font-medium text-indigo-600 shadow-sm hover:cursor-pointer hover:outline-none hover:ring-2 hover:ring-indigo-500 hover:ring-offset-2 sm:w-auto"
                    onClick={() => markAsComplete(feedback)}
                  >
                    <CheckIcon className="h-4 w-4"/>
                    <p>Mark Complete</p>
                  </div>
                </div>
              </div>
            );
          })}
          {oldFeedback.map((feedback) => {
            return (
              <div
                key={feedback.id}
                className="flex flex-col pt-2"
              >
                <p className="text-sm font-medium text-gray-900">
                  {feedback.feedback}
                </p>
                <div className="flex justify-between">
                  <div>
                    {feedback.createdAt && (
                      <p className="pt-2 text-xs text-gray-500">
                        {timeDifferenceForDate(Number(feedback.createdAt))}
                      </p>
                    )}
                    {feedback.actorName && (
                      <p className="text-xs text-gray-500">
                        {feedback.actorName}
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    )
  );
}
