import { Creator, Deal } from "@kalecard/common";
import RefundDealComponent from "../RefundDealComponent";

interface PayDealFormInterface {
  deal: Deal;
  creator: Creator;
  updateDeal: Function;
}

export default function PayDealForm({
  deal,
  updateDeal,
}: PayDealFormInterface) {
  switch (deal.state) {
    case "UNDONE":
      return (
        <>
          Refunded:{" "}
          <span className="font-bold">${deal.rewardAmount.toFixed(2)}</span>
        </>
      );
    case "PAID":
      return <PaidState deal={deal} updateDeal={updateDeal} />;
  }
}
function PaidState({ deal, updateDeal }: { deal: Deal; updateDeal: Function }) {
  return (
    <div>
      <p>
        Rewarded:{" "}
        <span className="font-bold">${deal.rewardAmount.toFixed(2)}</span>
      </p>
      <RefundDealComponent deal={deal} updateDeal={updateDeal} />
    </div>
  );
}
