import { useMutation } from '@apollo/client';
import { BrandPaymentStructure, Spinner } from '@kalecard/common';
import { useContext, useEffect, useState } from 'react';
import {
  CREATE_BRAND_PAYMENT_STRUCTURE,
  DELETE_BRAND_PAYMENT_STRUCTURE,
  UPDATE_BRAND_PAYMENT_STRUCTURE,
} from '../../graphql/mutations';
import { BrandPaymentStructuresContext } from '../../providers/BrandPaymentStructuresProvider';
import { useForm } from 'react-hook-form';
import { BrandPaymentStructuresActionType } from '../../reducers/BrandPaymentStructuresReducer';
import { Brand } from '../../__generated__/graphql';

export default function BrandPaymentStructureComponent({
  paymentStructure,
  valIndex,
  threshold,
}: {
  paymentStructure: BrandPaymentStructure;
  valIndex: string;
  threshold: string;
}) {
  const [isUpdateView, setIsUpdateView] = useState(false);

  return (
    <tr
      className="border-t border-gray-300"
      key={valIndex}
    >
      {isUpdateView ? (
        <UpdateBrandPaymentStructureView
          paymentStructure={paymentStructure}
          setIsUpdateView={setIsUpdateView}
        />
      ) : (
        <>
          <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
            {threshold}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
            ${paymentStructure.kaleOriginationFee}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
            ${paymentStructure.creatorOriginationFee}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
            ${paymentStructure.kaleCpmFee}
          </td>
          <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-800">
            ${paymentStructure.creatorCpmFee}
          </td>
          <td
            className="whitespace-nowrap px-3 py-4 text-sm font-medium text-kale-green-500 underline hover:cursor-pointer"
            onClick={() => setIsUpdateView(true)}
          >
            Edit
          </td>
        </>
      )}
    </tr>
  );
}

type BrandPaymentStructureInput = {
  minQualityScore?: number;
  kaleOriginationFee?: number;
  creatorOriginationFee?: number;
  kaleCpmFee?: number;
  creatorCpmFee?: number;
  brandId?: string;
  productType?: string;
};

export function UpdateBrandPaymentStructureView({
  paymentStructure,
  setIsUpdateView,
}: {
  paymentStructure: BrandPaymentStructure;
  setIsUpdateView?: (isUpdateView: boolean) => void;
}) {
  const [updateBrandPaymentStructure] = useMutation(
    UPDATE_BRAND_PAYMENT_STRUCTURE
  );
  const [deleteBrandPaymentStructure] = useMutation(
    DELETE_BRAND_PAYMENT_STRUCTURE
  );
  const { dispatch } = useContext(BrandPaymentStructuresContext);

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<BrandPaymentStructureInput>({
    defaultValues: {
      minQualityScore: paymentStructure.minQualityScore,
      kaleOriginationFee: paymentStructure.kaleOriginationFee,
      creatorOriginationFee: paymentStructure.creatorOriginationFee,
      kaleCpmFee: paymentStructure.kaleCpmFee,
      creatorCpmFee: paymentStructure.creatorCpmFee,
    },
  });

  const onSubmit = (data: BrandPaymentStructureInput) => {
    console.log(data);
    updateBrandPaymentStructure({
      variables: {
        brandPaymentId: paymentStructure.id,
        minQualityScore: data.minQualityScore,
        kaleOriginationFee: data.kaleOriginationFee,
        creatorOriginationFee: data.creatorOriginationFee,
        kaleCpmFee: data.kaleCpmFee,
        creatorCpmFee: data.creatorCpmFee,
      },
    })
      .then((res) => {
        console.log(res);
        dispatch({
          type: BrandPaymentStructuresActionType.UPDATE_PAYMENT_STRUCTURE,
          payload: res.data
            .updateBrandPaymentStructure as BrandPaymentStructure,
        });
        reset({
          minQualityScore: null,
          kaleOriginationFee: null,
          creatorOriginationFee: null,
          kaleCpmFee: null,
          creatorCpmFee: null,
        });
      })
      .catch((err) => {
        console.error(err);
      });
    setIsUpdateView(false);
  };

  const onDelete = () => {
    deleteBrandPaymentStructure({
      variables: {
        brandPaymentId: paymentStructure.id,
      },
    })
      .then((res) => {
        console.log(res);
        dispatch({
          type: BrandPaymentStructuresActionType.DELETE_PAYMENT_STRUCTURE,
          payload: paymentStructure as BrandPaymentStructure,
        });
        setIsUpdateView(false);
        reset();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <>
      <td className="whitespace-nowrap text-sm sm:pl-3">
        <div className="flex w-fit flex-col">
          <label className="text-xs">Minimum Score</label>
          <input
            type="number"
            name="minQualityScore"
            {...register('minQualityScore', {
              required: true,
              valueAsNumber: true,
            })}
            id="minQualityScore"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
          />
        </div>
      </td>
      <td className="whitespace-nowrap p-4 text-sm">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">$</p>
          <input
            type="number"
            name="kaleOriginationFee"
            {...register('kaleOriginationFee', {
              required: true,
              valueAsNumber: true,
            })}
            id="kaleOriginationFee"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
          />
        </div>
      </td>
      <td className="whitespace-nowrap p-4 text-sm">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">$</p>
          <input
            type="number"
            name="creatorOriginationFee"
            {...register('creatorOriginationFee', {
              required: true,
              valueAsNumber: true,
            })}
            id="creatorOriginationFee"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
          />
        </div>
      </td>
      <td className="whitespace-nowrap p-4 text-sm">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">$</p>
          <input
            type="number"
            name="kaleCpmFee"
            {...register('kaleCpmFee', { required: true, valueAsNumber: true })}
            id="kaleCpmFee"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
          />
        </div>
      </td>
      <td className="whitespace-nowrap p-4 text-sm">
        <div className="flex items-center space-x-2">
          <p className="text-sm font-medium">$</p>
          <input
            type="number"
            name="creatorCpmFee"
            {...register('creatorCpmFee', {
              required: true,
              valueAsNumber: true,
            })}
            id="creatorCpmFee"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
          />
        </div>
      </td>
      <td className="whitespace-nowrap text-sm font-medium">
        <div className="flex flex-col space-y-2 p-2 text-right">
          <button
            className="text-indigo-600 hover:text-indigo-900"
            onClick={() => {
              handleSubmit(onSubmit)();
            }}
          >
            Update
          </button>
          <button
            className="text-red-600 hover:text-red-900"
            onClick={() => {
              reset();
              setIsUpdateView(false);
            }}
          >
            Cancel
          </button>
          <button
            className="text-kale-green-500 hover:text-green-800"
            onClick={onDelete}
          >
            Delete
          </button>
        </div>
      </td>
      <td></td>
    </>
  );
}

const productTypes = [
  { name: 'Clips', value: 'CLIPS' },
  { name: 'Story', value: 'STORY' },
  { name: 'Feed', value: 'FEED' },
  { name: 'All content types', value: 'DEFAULT' },
];

export function NewBrandPaymentStructure({
  brand,
  defaultPaymentStructures,
  submitted,
}: {
  brand: Brand;
  defaultPaymentStructures: {};
  submitted?: () => void;
}) {
  const [createBrandPaymentStructure] = useMutation(
    CREATE_BRAND_PAYMENT_STRUCTURE
  );
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { dispatch } = useContext(BrandPaymentStructuresContext);

  const { reset, register, handleSubmit, setValue, getValues, watch } =
    useForm<BrandPaymentStructureInput>();

  const onSubmit = (data: BrandPaymentStructureInput) => {
    setIsLoading(true);
    createBrandPaymentStructure({
      variables: {
        brandId: data.brandId === 'DEFAULT' ? null : data.brandId,
        productType: data.productType === 'DEFAULT' ? null : data.productType,
        minQualityScore: data.minQualityScore,
        kaleOriginationFee: data.kaleOriginationFee,
        creatorOriginationFee: data.creatorOriginationFee,
        kaleCpmFee: data.kaleCpmFee,
        creatorCpmFee: data.creatorCpmFee,
      },
    })
      .then((res) => {
        setIsLoading(false);
        console.log(res);
        dispatch({
          type: BrandPaymentStructuresActionType.NEW_PAYMENT_STRUCTURE,
          payload: res.data
            .createBrandPaymentStructure as BrandPaymentStructure,
        });
        reset();
        submitted();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(() => {
    const subscription = watch((value, { name }) => {
      if (
        (name === 'brandId' ||
          name === 'productType' ||
          name === 'minQualityScore') &&
        getValues('brandId') !== 'DEFAULT' &&
        getValues('minQualityScore')
      ) {
        const paymentStructure = defaultPaymentStructures[
          getValues('productType')
        ]?.find(
          (paymentStructure) =>
            paymentStructure.minQualityScore == getValues('minQualityScore')
        );
        if (paymentStructure) {
          setValue('kaleOriginationFee', paymentStructure.kaleOriginationFee);
          setValue(
            'creatorOriginationFee',
            paymentStructure.creatorOriginationFee
          );
          setValue('kaleCpmFee', paymentStructure.kaleCpmFee);
          setValue('creatorCpmFee', paymentStructure.creatorCpmFee);
        } else {
          reset({
            kaleOriginationFee: null,
            creatorOriginationFee: null,
            kaleCpmFee: null,
            creatorCpmFee: null,
          });
        }
      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <form>
      <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
        <div className="col-span-6">
          <label className="text-sm">
            Who does this payment structure apply to?
          </label>
          <div className="flex items-center">
            <input
              id="brand"
              name="brand"
              type="radio"
              defaultChecked={true}
              value={brand.id}
              {...register('brandId')}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label
              htmlFor="brand"
              className="ml-3 block text-sm font-medium text-gray-700"
            >
              {brand.name}
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="allBrands"
              name="allBrands"
              type="radio"
              value={'DEFAULT'}
              {...register('brandId')}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label
              htmlFor="allBrands"
              className="ml-3 block text-sm font-medium text-gray-700"
            >
              All brands
            </label>
          </div>
        </div>
        <div className="flex flex-col space-y-1 sm:col-span-3">
          <label
            htmlFor="minQualityScore"
            className="block text-sm font-medium text-gray-700"
          >
            Minimum Quality Score
          </label>
          <input
            type="number"
            name="minQualityScore"
            {...register('minQualityScore', {
              required: true,
              valueAsNumber: true,
            })}
            id="minQualityScore"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
          />
        </div>
        <div className="sm:col-span-3">
          <label
            htmlFor="productType"
            className="block text-sm font-medium text-gray-700"
          >
            Product Type
          </label>
          <div className="mt-1">
            <select
              name="productType"
              {...register('productType', { required: true })}
              id="productType"
              className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            >
              {productTypes.map((productType) => {
                return (
                  <option value={productType.value}>{productType.name}</option>
                );
              })}
            </select>
          </div>
        </div>
        <div className="flex flex-col space-y-1 sm:col-span-3">
          <label
            htmlFor="kaleOriginationFee"
            className="block text-sm font-medium text-gray-700"
          >
            Kale Origination Fee
          </label>
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium">$</p>
            <input
              type="number"
              name="kaleOriginationFee"
              {...register('kaleOriginationFee', {
                required: true,
                valueAsNumber: true,
              })}
              id="kaleOriginationFee"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
            />
          </div>
        </div>
        <div className="flex flex-col space-y-1 sm:col-span-3">
          <label
            htmlFor="creatorOriginationFee"
            className="block text-sm font-medium text-gray-700"
          >
            Creator Origination Fee
          </label>
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium">$</p>
            <input
              type="number"
              name="creatorOriginationFee"
              {...register('creatorOriginationFee', { required: true })}
              id="creatorOriginationFee"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
            />
          </div>
        </div>
        <div className="flex flex-col space-y-1 sm:col-span-3">
          <label
            htmlFor="kaleCpmFee"
            className="block text-sm font-medium text-gray-700"
          >
            Kale CPM Fee
          </label>
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium">$</p>
            <input
              type="number"
              name="kaleCpmFee"
              {...register('kaleCpmFee', { required: true })}
              id="kaleCpmFee"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
            />
          </div>
        </div>
        <div className="flex flex-col space-y-1 sm:col-span-3">
          <label
            htmlFor="creatorCpmFee"
            className="block text-sm font-medium text-gray-700"
          >
            Creator CPM Fee
          </label>
          <div className="flex items-center space-x-2">
            <p className="text-sm font-medium">$</p>
            <input
              type="number"
              name="creatorCpmFee"
              {...register('creatorCpmFee', { required: true })}
              id="creatorCpmFee"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end pt-5">
        {isLoading && (
          <div className="flex flex-wrap content-center justify-center pr-2">
            <Spinner size="h-6 w-6" />
          </div>
        )}
        <div className="flex justify-end">
          <button
            type="button"
            onClick={handleSubmit(onSubmit)}
            disabled={isLoading}
            className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-400"
          >
            Create New Payment Structure
          </button>
        </div>
      </div>
      <div className="mt-2 flex justify-end">
        <p className="font-bold text-red-500">{error}</p>
      </div>
    </form>
  );
}
