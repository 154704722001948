import {
  AddSageChallengeBudgetMutationVariables,
  RemoveSageChallengeBudgetMutationVariables,
  SageChallengeBudget,
} from '../../__generated__/graphql';
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  ADD_CHALLENGE_BUDGET,
  REMOVE_CHALLENGE_BUDGET,
  UPDATE_CHALLENGE_BUDGET,
} from '../../graphql/mutations';
import { Heading } from '../catalyst/heading';
import { Button } from '../catalyst/button';
import {
  EllipsisHorizontalIcon,
  PlusIcon,
  TrashIcon,
} from '@heroicons/react/16/solid';
import { Divider } from '../catalyst/divider';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../catalyst/table';
import { formatCurrency } from '../../utils/currency';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
  DropdownSeparator,
} from '../catalyst/dropdown';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '../catalyst/dialog';
import {
  Description,
  ErrorMessage,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from '../catalyst/fieldset';
import { Input, InputGroup } from '../catalyst/input';
import { CurrencyDollarIcon } from '@heroicons/react/24/outline';
import { challengeToTitle, EmptyState } from './SageForm';
import { Listbox, ListboxLabel, ListboxOption } from '../catalyst/listbox';
import { PencilSquareIcon } from '@heroicons/react/24/solid';

export interface AvailableChallenge {
  id: number;
  name: string;
}

interface ChallengeBudgetRowProps {
  brandId: string;
  effectiveBudget: number;
  challenges: SageChallengeBudget[];
  availableChallenges: AvailableChallenge[];
}

export function ChallengeBudgetRow(props: ChallengeBudgetRowProps) {
  const [challengeBudgetDialogOpen, setChallengeBudgetDialog] = useState(false);
  const [editingFields, setEditingFields] = useState<
    EditingFields | undefined
  >();
  const [addChallengeBudget] =
    useMutation<AddSageChallengeBudgetMutationVariables>(ADD_CHALLENGE_BUDGET);
  const [updateChallengeBudget] =
    useMutation<AddSageChallengeBudgetMutationVariables>(
      UPDATE_CHALLENGE_BUDGET
    );
  const [removeChallengeBudget] =
    useMutation<RemoveSageChallengeBudgetMutationVariables>(
      REMOVE_CHALLENGE_BUDGET
    );

  return (
    <div>
      <div className="mb-2 flex w-full flex-row justify-between">
        <Heading>Challenge Budgets</Heading>
        <Button
          disabled={false}
          color="white"
          onClick={() => {
            setChallengeBudgetDialog(true);
          }}
        >
          <PlusIcon />
        </Button>
      </div>
      {props.challenges.length > 0 ? (
        <>
          <Divider className="mb-4" />
          <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
            <TableHead>
              <TableRow>
                <TableHeader>Challenge</TableHeader>
                <TableHeader>Allocated Budget</TableHeader>
                <TableHeader>Utilized Budget</TableHeader>
                <TableHeader className="relative w-0">
                  <span className="sr-only">Actions</span>
                </TableHeader>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.challenges.map((challenge: SageChallengeBudget) => (
                <TableRow>
                  <TableCell>{challenge.challengeId}</TableCell>
                  <TableCell>
                    {formatCurrency(challenge.allocatedBudget)}
                  </TableCell>
                  <TableCell>
                    {formatCurrency(challenge.utilizedBudget)}
                  </TableCell>
                  <TableCell>
                    <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                      <Dropdown>
                        <DropdownButton
                          plain
                          aria-label="More options"
                        >
                          <EllipsisHorizontalIcon />
                        </DropdownButton>
                        <DropdownMenu anchor="bottom end">
                          <DropdownItem
                            onClick={() => {
                              setEditingFields({
                                currentChallenge: {
                                  id: challenge.challengeId,
                                  name: challengeToTitle(
                                    challenge.challengeId,
                                    challenge.title,
                                    challenge.description
                                  ),
                                },
                                currentBudget: challenge.allocatedBudget,
                              });
                              setChallengeBudgetDialog(true);
                            }}
                          >
                            <PencilSquareIcon />
                            <DropdownLabel>Edit</DropdownLabel>
                          </DropdownItem>
                          <DropdownSeparator />
                          <DropdownItem
                            onClick={async () => {
                              await removeChallengeBudget({
                                variables: {
                                  brandId: props.brandId,
                                  challengeId: challenge.challengeId,
                                },
                              });
                            }}
                          >
                            <TrashIcon />
                            <DropdownLabel>Delete</DropdownLabel>
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </>
      ) : (
        <EmptyState text="No challenge budgets" />
      )}
      <ChallengeBudgetDialog
        isOpen={challengeBudgetDialogOpen}
        effectiveBudget={props.effectiveBudget}
        onSubmit={async (budget) => {
          if (!editingFields) {
            await addChallengeBudget({
              variables: {
                id: props.brandId,
                challengeId: budget.challengeId,
                budget: budget.budget,
              },
            });
          } else {
            await updateChallengeBudget({
              variables: {
                brandId: props.brandId,
                challengeId: budget.challengeId,
                budget: budget.budget,
              },
            });
          }
          setChallengeBudgetDialog(false);
          setEditingFields(undefined);
        }}
        onClose={() => setChallengeBudgetDialog(false)}
        challenges={props.availableChallenges}
        editingFields={editingFields}
      />
    </div>
  );
}

interface ChallengeBudget {
  challengeId: number;
  budget: number;
}

interface EditingFields {
  currentChallenge: AvailableChallenge;
  currentBudget: number;
}

interface ChallengeBudgetDialogProps {
  isOpen: boolean;
  editingFields?: EditingFields;
  onClose: () => void;
  onSubmit: (budget: ChallengeBudget) => void;
  challenges: AvailableChallenge[];
  effectiveBudget: number;
}

function ChallengeBudgetDialog(props: ChallengeBudgetDialogProps) {
  const [selectedChallenge, setSelectedChallenge] = useState<
    number | undefined
  >(undefined);
  const [challengeBudget, setChallengeBudget] = useState<number | undefined>(
    undefined
  );

  useEffect(() => {
    setSelectedChallenge(props.editingFields?.currentChallenge.id);
    setChallengeBudget(props.editingFields?.currentBudget);
  }, [props]);

  const invalidChallengeBudget =
    challengeBudget !== null &&
    (challengeBudget <= 0 || challengeBudget > props.effectiveBudget);

  return (
    <Dialog
      onClose={props.onClose}
      open={props.isOpen}
    >
      {props.editingFields ? (
        <DialogTitle>Edit challenge budget</DialogTitle>
      ) : (
        <DialogTitle>Create challenge budget</DialogTitle>
      )}
      <DialogDescription>
        This will decrease the brands effective budget by the amount specified.
        Additionally, challenge budgets are not effected by the brands pacing
        schedule.
      </DialogDescription>
      <DialogBody>
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label>Challenge</Label>
              {props.editingFields ? (
                <Listbox
                  name="challenge"
                  value={props.editingFields.currentChallenge.id}
                  disabled={true}
                >
                  <ListboxOption
                    value={props.editingFields.currentChallenge.id}
                  >
                    <ListboxLabel>
                      {props.editingFields.currentChallenge.name}
                    </ListboxLabel>
                  </ListboxOption>
                </Listbox>
              ) : (
                <Listbox
                  name="challenge"
                  placeholder="Select a challenge"
                  value={selectedChallenge}
                  onChange={(id) => setSelectedChallenge(id)}
                >
                  {props.challenges.map((challenge) => (
                    <ListboxOption value={challenge.id}>
                      <ListboxLabel>{challenge.name}</ListboxLabel>
                    </ListboxOption>
                  ))}
                </Listbox>
              )}
            </Field>
            <Field>
              <Label>Challenge Allocation</Label>
              <InputGroup>
                <CurrencyDollarIcon />
                <Input
                  name="challenge Allocation"
                  placeholder="Challenge allocation"
                  aria-label="Challenge Allocation"
                  invalid={invalidChallengeBudget}
                  type="number"
                  min="0"
                  step="100"
                  value={challengeBudget && challengeBudget / 100}
                  onChange={(e) => {
                    setChallengeBudget(Number(e.target.value) * 100);
                  }}
                />
                {invalidChallengeBudget && (
                  <ErrorMessage>Invalid budget</ErrorMessage>
                )}
              </InputGroup>
              <Description>
                The value must be less than or equal to the brands effective
                budget: {formatCurrency(props.effectiveBudget)}
              </Description>
            </Field>
          </FieldGroup>
        </Fieldset>
      </DialogBody>
      <DialogActions>
        <Button
          plain
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          color="green"
          onClick={() => {
            props.onSubmit({
              challengeId: selectedChallenge!,
              budget: challengeBudget!,
            });
            setSelectedChallenge(undefined);
            setChallengeBudget(undefined);
          }}
          disabled={
            !(!invalidChallengeBudget && selectedChallenge && challengeBudget)
          }
        >
          {!props.editingFields ? 'Create' : 'Update'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
