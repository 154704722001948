import { BrandPaymentStructure } from "@kalecard/common";
import BrandPaymentStructureComponent from "./BrandPaymentStructureComponent";

export default function BrandPaymentStructureTable({
  paymentStructuresData,
  header,
}: {
  paymentStructuresData: {};
  header: string;
}) {
  const formatKey = (key: string) => {
    return key === "DEFAULT" ? "all content types" : key.toLowerCase();
  };

  return (
    <div className="pt-2">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            {header}
          </h1>
        </div>
      </div>
      <div className="flow-root pt-2">
        <div className="md rounded border border-2 border-gray-200">
          <div className="inline-block min-w-full align-middle">
            <table className="min-w-full">
              <thead className="bg-white">
                <tr>
                  <th
                    scope="col"
                    className="py-3 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                  >
                    Quality Score
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Kale Origination Fee
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Creator Origination Fee
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Kale CPM Fee
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Creator CPM Fee
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white">
                {Object.keys(paymentStructuresData).map((key, index) => {
                  if (Object.keys(paymentStructuresData[key]).length > 0) {
                    const formattedKey = formatKey(key);
                    return (
                      <>
                        <tr className="border-t border-gray-200" key={index}>
                          <th
                            colSpan={6}
                            className="bg-kale-mint-300 py-2 pl-4 pr-3 text-left text-sm font-semibold capitalize text-gray-900 sm:pl-3"
                          >
                            {formattedKey}
                          </th>
                        </tr>
                        {Object.keys(paymentStructuresData[key]).map(
                          (valIndex) => {
                            const paymentStructure: BrandPaymentStructure =
                              paymentStructuresData[key][valIndex];
                            const previousPaymentStructure =
                              valIndex === "0"
                                ? null
                                : paymentStructuresData[key][
                                    Number(valIndex) - 1
                                  ];

                            const threshold =
                              valIndex === "0"
                                ? `${paymentStructure.minQualityScore}+`
                                : `${paymentStructure.minQualityScore} - ${
                                    previousPaymentStructure.minQualityScore -
                                    0.25
                                  }`;
                            return (
                              <BrandPaymentStructureComponent
                                key={valIndex}
                                paymentStructure={paymentStructure}
                                valIndex={valIndex}
                                threshold={threshold}
                              />
                            );
                          }
                        )}
                      </>
                    );
                  } else {
                    return null;
                  }
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}
