import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import { auth } from "../../config/firebase";
import { CREATOR_TRANSACTIONS } from "../../graphql/queries";
import {
  BrandReferral,
  Creator,
  CreatorTransaction,
  Deal,
  Edge,
  Spinner,
  TextDetails,
  prettyDecimal,
  timeDifferenceForDate
} from "@kalecard/common";
import { Link, useLocation } from "react-router-dom";

export default function CreatorTransactions({ creator }: { creator: Creator }) {
  const [edges, setEdges] = useState([]);
  const [after, setAfter] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (edges.length === 0) {
      loadMore();
    }
    return () => { };
  }, []);

  const loadMore = async () => {
    setLoading(true);
    const tokenId = await auth.currentUser.getIdToken();
    try {
      const result = await apolloClient.query({
        query: CREATOR_TRANSACTIONS,
        variables: {
          first: 20,
          after,
          userId: creator.id,
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
      console.log(result);
      setHasNextPage(result.data.creatorTransactions.pageInfo.hasNextPage);
      setAfter(
        result.data.creatorTransactions.edges[result.data.creatorTransactions.edges.length - 1]?.cursor
      );
      setEdges([...edges, ...result.data.creatorTransactions.edges]);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };


  var listFooter = null;
  if (hasNextPage) {
    listFooter = (
      <button
        className="rounded border border-green-500 bg-transparent py-2 px-6 text-center font-semibold text-green-700 hover:border-transparent hover:bg-green-500 hover:text-white"
        onClick={() => loadMore()}
      >
        Load more
      </button>
    );
  }

  if (loading) {
    listFooter = (
      <div className="flex flex-wrap content-center">
        <Spinner size="h-6 w-6" />
      </div>
    );
  }

  return (
    <>
      <div className="mt-4 flex flex-col items-center">
        <div className="mb-2 w-full overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-y-visible border-2 border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Transaction type
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Amount
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Date
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {edges.map((edge: Edge<CreatorTransaction>, index) => {
                    return (
                      <tr key={edge.node.id}>
                        <td className="whitespace-nowrap px-6 py-4 text-sm">{edge.node.id}</td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm">
                          <TransactionDescription type={edge.node.type} details={edge.node.details} />
                        </td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm">${prettyDecimal(edge.node.amount)}</td>
                        <td className="whitespace-nowrap px-6 py-4 text-sm">{timeDifferenceForDate(Number(edge.node.createdAt))}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {listFooter}
      </div>
    </>
  );
}

function TransactionDescription({ type, details }: { type: string, details: Deal | BrandReferral | TextDetails }) {
  const location = useLocation();
  const { pathname } = location;
  switch (details?.__typename) {
    case "Deal":
      const deal = details as Deal;
      return (
        <>
          <Link
            className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
            to={`${pathname.split("/").splice(0, 3).join("/")}/post?dealId=${deal.id}`}
            rel="noreferrer"
          >
            {deal?.merchantName} deal.
          </Link>
        </>

      );
    case "BrandReferral":
      const brandReferral = details as BrandReferral;
      const referralDate = new Date(Number(brandReferral.referralTimestamp));
      return (
        <p>Brand referral for referring {brandReferral?.brand?.name} to Kale. ({referralDate.getMonth() + 1}/{referralDate.getFullYear()})</p>
      );
    case "TextDetails":
      const textDetails = details as TextDetails;
      return (
        <p>{textDetails?.text}</p>
      );
    default:
      return (
        <>
          {type}
        </>
      );
  }
}