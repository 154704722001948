import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { API_URL } from "@kalecard/common";
import { auth } from "./firebase";
import { createFragmentRegistry } from "@apollo/client/cache";
import { SAGE_SETTINGS_FRAGMENT } from "../graphql/fragments";

const authLink = setContext((_, { headers }) => {
  // If there is already an authorization header, do nothing
  if (headers && headers.authorization) {
    return {
      headers: {
        ...headers,
      },
    };
  }

  //it will always get unexpired version of the token
  return auth && auth.currentUser
    ? auth.currentUser.getIdToken().then((token) => {
        return {
          headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
          },
        };
      })
    : {
        headers: {
          ...headers,
        },
      };
});

const httpLink = createHttpLink({
  uri: `${API_URL}/graphql/`,
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    fragments: createFragmentRegistry(SAGE_SETTINGS_FRAGMENT),
  }),
  link: authLink.concat(httpLink),
});

export default client;
