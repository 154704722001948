import { SageEvent, SageSchedule } from "../../__generated__/graphql";
import { useState } from "react";
import { useMutation } from "@apollo/client";
import { SET_BRAND_SAGE_CUSTOM_SCHEDULE } from "../../graphql/mutations";
import { Heading } from "../catalyst/heading";
import { Button } from "../catalyst/button";
import { EllipsisHorizontalIcon, PlusIcon } from "@heroicons/react/16/solid";
import { Divider } from "../catalyst/divider";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";
import { Badge } from "../catalyst/badge";
import { formatDateTime } from "../../utils/time";
import { formatCurrency } from "../../utils/currency";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from "../catalyst/dropdown";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from "../catalyst/dialog";
import {
  Description,
  Field,
  FieldGroup,
  Fieldset,
  Label,
} from "../catalyst/fieldset";
import { Input, InputGroup } from "../catalyst/input";
import { CurrencyDollarIcon } from "@heroicons/react/24/outline";
import { EmptyState } from "./SageForm";

export function BudgetScheduleRow(props: {
  brandId: string;
  schedule?: SageSchedule;
  addScheduleEnabled: boolean;
}) {
  const [customScheduleDialogOpen, setCustomScheduleDialog] = useState(false);
  const [setBrandCustomSchedule] = useMutation(SET_BRAND_SAGE_CUSTOM_SCHEDULE);

  return (
    <>
      <div>
        <>
          <div className="mb-2 mt-4 flex w-full flex-row justify-between">
            <Field>
              <Heading>Pacing Schedule</Heading>
              <Description>
                Enabling pacing creates a default schedule to distribute the
                content budget through month-end
              </Description>
            </Field>
            <div className="flex flex-col">
              <div className="h-full" />
              <Button
                disabled={!props.addScheduleEnabled}
                onClick={() => setCustomScheduleDialog(true)}
                color="white"
                className="max-h-9"
              >
                <PlusIcon />
              </Button>
            </div>
          </div>
        </>
        {props.schedule ? (
          <>
            <Divider className={"mb-4"} />
            <Table className="[--gutter:theme(spacing.6)] sm:[--gutter:theme(spacing.8)]">
              <TableHead>
                <TableRow>
                  <TableHeader>Type</TableHeader>
                  <TableHeader>Schedule Start</TableHeader>
                  <TableHeader>Schedule End</TableHeader>
                  <TableHeader>End Budget</TableHeader>
                  <TableHeader className="relative w-0">
                    <span className="sr-only">Actions</span>
                  </TableHeader>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.schedule.events.map((event: SageEvent) => (
                  <TableRow>
                    <TableCell>
                      <Badge color={"green"} className="capitalize">
                        {event.type.toLowerCase()}
                      </Badge>
                    </TableCell>
                    <TableCell>{formatDateTime(event.startAt)}</TableCell>
                    <TableCell>{formatDateTime(event.endAt)}</TableCell>
                    <TableCell className="font-medium">
                      {formatCurrency(event.endBudget)}
                    </TableCell>
                    {event.type !== "MONTHLY" && (
                      <TableCell>
                        <div className="-mx-3 -my-1.5 sm:-mx-2.5">
                          <Dropdown>
                            <DropdownButton plain aria-label="More options">
                              <EllipsisHorizontalIcon />
                            </DropdownButton>
                            <DropdownMenu anchor="bottom end">
                              <DropdownItem
                                onClick={async () => {
                                  await setBrandCustomSchedule({
                                    variables: {
                                      id: props.brandId,
                                      schedule: null,
                                    },
                                  });
                                }}
                              >
                                Delete
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        ) : (
          <EmptyState text="No pacing schedule" />
        )}
      </div>
      <CustomScheduleDialog
        isOpen={customScheduleDialogOpen}
        onClose={() => setCustomScheduleDialog(false)}
        onSubmit={async (budgetEnd, endDate) => {
          await setBrandCustomSchedule({
            variables: {
              id: props.brandId,
              schedule: {
                endAt: new Date(endDate),
                endBudget: budgetEnd,
              },
            },
          });
          setCustomScheduleDialog(false);
        }}
      />
    </>
  );
}

interface CustomScheduleDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (budgetEnd: number, endDate: string) => void;
}

function CustomScheduleDialog(props: CustomScheduleDialogProps) {
  const [budgetEnd, setBudgetEnd] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);

  return (
    <Dialog onClose={props.onClose} open={props.isOpen}>
      <DialogTitle>Create custom schedule</DialogTitle>
      <DialogDescription>
        The custom schedule will begin immediately, and return to monthly pacing
        after the custom schedule ends.
      </DialogDescription>
      <DialogBody>
        <Fieldset>
          <FieldGroup>
            <Field>
              <Label>Budget ending amount</Label>
              <InputGroup>
                <CurrencyDollarIcon />
                <Input
                  name="ending_amount"
                  placeholder="Ending Amount"
                  aria-label="Ending Amount"
                  type="number"
                  min="0"
                  step="100"
                  value={budgetEnd && budgetEnd / 100}
                  onChange={(e) => setBudgetEnd(Number(e.target.value) * 100)}
                />
              </InputGroup>
            </Field>
            <Field className="-mt-2">
              <Label>End date</Label>
              <Input
                type="datetime-local"
                name="end_date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Field>
          </FieldGroup>
        </Fieldset>
      </DialogBody>
      <DialogActions>
        <Button plain onClick={props.onClose}>
          Cancel
        </Button>
        <Button
          color="green"
          disabled={budgetEnd === undefined || !endDate}
          onClick={() => {
            props.onSubmit(budgetEnd, endDate);
            setBudgetEnd(undefined);
            setEndDate(undefined);
          }}
        >
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
}
