import { useLazyQuery } from "@apollo/client";
import { BuildingStorefrontIcon } from "@heroicons/react/24/solid";
import {
  Dropdown,
  Edge,
  OptionInterface,
  WhitelistContent,
} from "@kalecard/common";
import { useEffect, useState } from "react";
import { WHITELIST_CONTENT } from "../graphql/queries";

export default function WhitelistRequestDropdown({
  brandId = null,
  creatorId = null,
  requestId,
  states = ["IN_REVIEW"],
  isBrandView = false,
  setRequestId,
}: {
  brandId?: string;
  creatorId?: string;
  requestId: string;
  states?: string[];
  isBrandView?: boolean;
  setRequestId: (requestId: string) => void;
}) {
  const [brandOptions, setBrandOptions] = useState<OptionInterface[]>();
  const brandClicked = (brandOption: OptionInterface) =>
    setRequestId(brandOption.id);
  const [whitelistContent] = useLazyQuery(WHITELIST_CONTENT);

  useEffect(() => {
    whitelistContent({
      variables: {
        brandId: brandId,
        states: states,
        creatorId,
      },
      fetchPolicy: "network-only",
    }).then((result) => {
      console.log(result);
      setBrandOptions(
        result.data.whitelistContent.edges.map(
          // @ts-ignore
          (edge: Edge<WhitelistContent>) => {
            let name = edge.node.post?.deal?.creatorBrand?.name;
            let imageUrl = edge.node.post?.deal?.creatorBrand?.logoUrl;
            if (isBrandView) {
              name = `${edge.node.post?.externalUser?.username} - $${edge.node.budget}`;
              imageUrl = edge.node.post?.externalUser?.pictureUrl;
            }
            return {
              id: edge.node.id,
              name,
              imageUrl,
            };
          }
        )
      );
    });
  }, []);

  return (
    <>
      <Dropdown
        options={brandOptions}
        activeOptionId={requestId}
        optionClicked={brandClicked}
        label={"Whitelisting Request:"}
        placeholderImage={
          <BuildingStorefrontIcon className="h-6 w-6 flex-shrink-0 rounded-full" />
        }
      />
    </>
  );
}
