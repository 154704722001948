import { useEffect, useState } from 'react';
import BrandsTable from '../components/brands/BrandsTable';
import { FunnelIcon } from '@heroicons/react/24/solid';
import { Dropdown } from '@kalecard/common';
import { getKaleBrands } from '../utils/brands';
import { Brand } from '../__generated__/graphql';

export default function BrandsPage() {
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [autoRechargeEnabledFilter, setAutoRechargedEnabledFilter] =
    useState(null);
  const [challengesPausedFilter, setChallengesPausedFilter] = useState(null);
  const [pacingWithSageFilter, setPacingWithSageFilter] = useState(null);
  const [brands, setBrands] = useState<Brand[]>([]);

  const brandsSortingTypes = [{ name: 'Name', id: 'NAME_ASC' }];

  const [sortingType, setSortingType] = useState(brandsSortingTypes[0]);

  const toggleBooleanFilter = (
    enabled: boolean,
    filter: boolean,
    filterSet: (val: boolean) => void
  ) => {
    if (filter !== enabled) {
      filterSet(enabled);
    } else {
      filterSet(null);
    }
  };

  const clearFilters = () => {
    setAutoRechargedEnabledFilter(null);
    setChallengesPausedFilter(null);
    setPacingWithSageFilter(null);
  };

  async function getAllKaleBrands(
    autoRechargeEnabled?: boolean,
    challengesPaused?: boolean,
    sortingType?: string,
    pacingWithSage?: boolean
  ) {
    const first = 20;
    let after = 0;
    let hasNextPage = true;
    let newBrands = [];

    while (hasNextPage) {
      const result = await getKaleBrands(
        first,
        String(after),
        autoRechargeEnabled,
        challengesPaused,
        sortingType,
        pacingWithSage
      );
      after = after + result.data.brands.edges.length;
      hasNextPage = result.data.brands.pageInfo.hasNextPage;
      newBrands = [
        ...newBrands,
        ...result.data.brands.edges.map((edge) => edge.node),
      ];
      setBrands(newBrands);
    }
  }

  useEffect(() => {
    getAllKaleBrands(
      autoRechargeEnabledFilter,
      challengesPausedFilter,
      sortingType?.id,
      pacingWithSageFilter
    );
  }, [
    autoRechargeEnabledFilter,
    challengesPausedFilter,
    sortingType,
    pacingWithSageFilter,
  ]);

  const renderFilters = () => {
    return (
      <div className="mb-4 bg-white">
        <section
          aria-labelledby="filter-heading"
          className="grid items-center border-b border-t border-gray-200"
        >
          <h2
            id="filter-heading"
            className="sr-only"
          >
            Filters
          </h2>
          <div className="relative col-start-1 row-start-1 py-4">
            <div className="mx-auto flex space-x-6 divide-x divide-gray-200 text-sm">
              <div onClick={() => setFiltersOpen(!filtersOpen)}>
                <button
                  type="button"
                  className="group flex items-center space-x-2 font-medium text-gray-700"
                  aria-controls="disclosure-1"
                  aria-expanded="false"
                >
                  <FunnelIcon className="h-4 w-4" />
                  <p>Filters</p>
                </button>
              </div>
              <div
                className="pl-6"
                onClick={clearFilters}
              >
                <button
                  type="button"
                  className="text-gray-500"
                >
                  Clear all
                </button>
              </div>
            </div>
          </div>
          {filtersOpen && (
            <div
              className="border-t border-gray-200 py-10"
              id="disclosure-1"
            >
              <div className="mx-auto gap-x-4 px-8 text-sm">
                <div className="grid auto-rows-min grid-cols-3 gap-y-10">
                  <fieldset>
                    <legend className="block font-medium">
                      Content Budget Billing Cycle
                    </legend>
                    <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                      <div className="flex items-center text-base sm:text-sm">
                        <input
                          id="autoRechargeEnabledTrue"
                          value="true"
                          type="checkbox"
                          className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          checked={autoRechargeEnabledFilter === true}
                          onChange={() =>
                            toggleBooleanFilter(
                              true,
                              autoRechargeEnabledFilter,
                              setAutoRechargedEnabledFilter
                            )
                          }
                        />
                        <label
                          htmlFor="autoRechargeEnabledTrue"
                          className="ml-3 min-w-0 flex-1 text-gray-600"
                        >
                          Monthly
                        </label>
                      </div>
                      <div className="flex items-center text-base sm:text-sm">
                        <input
                          id="autoRechargeEnabledFalse"
                          value="false"
                          type="checkbox"
                          className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          checked={autoRechargeEnabledFilter === false}
                          onChange={() =>
                            toggleBooleanFilter(
                              false,
                              autoRechargeEnabledFilter,
                              setAutoRechargedEnabledFilter
                            )
                          }
                        />
                        <label
                          htmlFor="autoRechargeEnabledFalse"
                          className="ml-3 min-w-0 flex-1 text-gray-600"
                        >
                          Campaign
                        </label>
                      </div>
                    </div>
                  </fieldset>
                  <fieldset>
                    <legend className="block font-medium">
                      Challenges State
                    </legend>
                    <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                      <div className="flex items-center text-base sm:text-sm">
                        <input
                          id="activeChallenges"
                          value="white"
                          type="checkbox"
                          className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          checked={challengesPausedFilter === false}
                          onChange={() =>
                            toggleBooleanFilter(
                              false,
                              challengesPausedFilter,
                              setChallengesPausedFilter
                            )
                          }
                        />
                        <label
                          htmlFor="activeChallenges"
                          className="ml-3 min-w-0 flex-1 text-gray-600"
                        >
                          Active
                        </label>
                      </div>
                      <div className="flex items-center text-base sm:text-sm">
                        <input
                          id="pausedChallenges"
                          value="beige"
                          type="checkbox"
                          className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          checked={challengesPausedFilter === true}
                          onChange={() =>
                            toggleBooleanFilter(
                              true,
                              challengesPausedFilter,
                              setChallengesPausedFilter
                            )
                          }
                        />
                        <label
                          htmlFor="pausedChallenges"
                          className="ml-3 min-w-0 flex-1 text-gray-600"
                        >
                          Paused
                        </label>
                      </div>
                    </div>
                  </fieldset>

                  <fieldset>
                    <legend className="block font-medium">
                      Pacing With Sage
                    </legend>
                    <div className="space-y-6 pt-6 sm:space-y-4 sm:pt-4">
                      <div className="flex items-center text-base sm:text-sm">
                        <input
                          id="pacingWithSageOn"
                          value="beige"
                          type="checkbox"
                          className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          checked={pacingWithSageFilter === true}
                          onChange={() =>
                            toggleBooleanFilter(
                              true,
                              pacingWithSageFilter,
                              setPacingWithSageFilter
                            )
                          }
                        />
                        <label
                          htmlFor="pacingWithSageOn"
                          className="ml-3 min-w-0 flex-1 text-gray-600"
                        >
                          On
                        </label>
                      </div>
                      <div className="flex items-center text-base sm:text-sm">
                        <input
                          id="pacingWithSageOff"
                          value="white"
                          type="checkbox"
                          className="h-4 w-4 flex-shrink-0 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                          checked={pacingWithSageFilter === false}
                          onChange={() =>
                            toggleBooleanFilter(
                              false,
                              pacingWithSageFilter,
                              setPacingWithSageFilter
                            )
                          }
                        />
                        <label
                          htmlFor="pacingWithSageOff"
                          className="ml-3 min-w-0 flex-1 text-gray-600"
                        >
                          Off
                        </label>
                      </div>
                    </div>
                  </fieldset>
                </div>
              </div>
            </div>
          )}
          <div className="col-start-1 row-start-1 py-4">
            <div className="mx-auto flex justify-end">
              <div className="relative inline-block">
                <div className="flex">
                  <Dropdown
                    options={brandsSortingTypes.map((sortingType) => {
                      return {
                        id: sortingType.id,
                        name: sortingType.name,
                        imageUrl: null,
                      };
                    })}
                    activeOptionId={sortingType?.id}
                    optionClicked={(state) => setSortingType(state)}
                    label={'Sort By:'}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  };

  return (
    <>
      <div className="mt-4 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Kale Brands</h1>
          </div>
        </div>
        <div className="mt-4">
          {renderFilters()}
          <BrandsTable
            isKaleBrand={true}
            brands={brands}
          />
        </div>
      </div>
    </>
  );
}
