export default function MessageMediaComponent({ messageMedia }) {
  if (messageMedia.mediaType === "IMAGE") {
    return (
      <img
        key={messageMedia.id}
        src={
          messageMedia.twilioMediaUrl
            ? messageMedia.twilioMediaUrl
            : messageMedia.mediaUrl
        }
        className="flex:1 hover:opacity-50 max-w-15 pt-5 px-5"
      ></img>
    );
  } else if (messageMedia.mediaType === "VIDEO") {
    return (
      <video
        controls
        key={messageMedia.id}
        src={
          messageMedia.twilioMediaUrl
            ? messageMedia.twilioMediaUrl
            : messageMedia.mediaUrl
        }
        className="flex:1 hover:opacity-50 max-w-15 pt-5 px-5"
      ></video>
    );
  } else {
    return null;
  }
}
