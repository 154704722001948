import { Brand } from '../../__generated__/graphql';
import BrandDetails from '../brands/setup/BrandDetails';

interface NewBrandFormInterface {
  newBrand: (newBrand: Brand) => void;
}

export default function NewBrandForm(props: NewBrandFormInterface) {
  return (
    <BrandDetails
      isCreationFlow={true}
      newBrand={props.newBrand}
    />
  );
}
