import { BonsaiQueue } from "../BonsaiModels";
import { BonsaiActionPanel } from "../BonsaiContainer";
import React, { useState } from "react";
import {
  BonsaiPanel,
  BonsaiContextLinkType,
  BonsaiLink,
  creatorAdminLink,
  ipLink,
  stripeSearchLink,
} from "@kalecard/common";

enum UserVerificationOutcome {
  APPROVE = "APPROVE",
  BLOCK = "BLOCK",
}

interface ExternalUserMetadata {
  username: string;
  platform: string;
  external_url: string;
  suspicious_posts_by_day: Map<String, String[]>;
}

interface StripeExternalAccount {
  creator_id: string;
  fingerprint: string;
}

interface UserVerificationInput {
  creator_id: string;
  phone_number: string;
  email: string;
  ip_address: string;
  external_users: ExternalUserMetadata[];
  phone_fraud_score: number;
  phone_risky: boolean;
  phone_spammer: boolean;
  phone_recent_abuse: boolean;
  email_fraud_score: number;
  ip_fraud_score: number;
  ip_country_code: string;
  stripe_external_accounts: StripeExternalAccount[];
}

interface UserVerificationOutput {
  outcome: UserVerificationOutcome;
}

interface UserVerificationToolProps {
  queue: BonsaiQueue;
  onComplete: (outcome: UserVerificationOutput) => void;
  readOnly: boolean;
  taskId: number;
  input: UserVerificationInput;
}

export default function UserVerificationTool({
  queue,
  onComplete,
  readOnly,
  taskId,
  input,
}: UserVerificationToolProps) {
  const [selected, setSelected] = useState<UserVerificationOutcome>(null);

  return (
    <div>
      <BonsaiPanel>
        <div className={"p-4"}>
          <p>
            Creator ID:{" "}
            <a
              className={"text-blue-500 underline hover:text-blue-400"}
              href={creatorAdminLink(input.creator_id)}
              target="_blank"
              rel="noreferrer"
            >
              {input.creator_id}
            </a>
          </p>
          <p>
            Phone Number{" "}
            <span className={"font-bold"}>({input.phone_fraud_score})</span>:
            {` ${input.phone_number}  -  is risky: ${input.phone_risky}, is spammer: ${input.phone_spammer}, recent abuse: ${input.phone_recent_abuse}`}
          </p>
          <p>
            Email{" "}
            <span className={"font-bold"}>({input.email_fraud_score})</span>:{" "}
            <span>{input.email}</span>
          </p>
          {input.ip_address ? (
            <div className={"flex flex-row items-center space-x-4"}>
              <p>
                IP Address{" "}
                <span className={"font-bold"}>({input.ip_fraud_score})</span>:{" "}
                <a
                  className={"text-blue-500 underline hover:text-blue-400"}
                  href={ipLink(input.ip_address)}
                  target="_blank"
                  rel="noreferrer"
                >
                  {input.ip_address}
                </a>
                {` - Country: ${input.ip_country_code}`}
              </p>
            </div>
          ) : null}
          {input.external_users.map((externalUser: ExternalUserMetadata) => {
            return (
              <div className={"flex flex-col space-y-2"}>
                <div className={"flex flex-row items-center space-x-4"}>
                  <p>@{externalUser.username}</p>
                  <BonsaiLink
                    type={BonsaiContextLinkType[externalUser.platform]}
                    url={externalUser.external_url}
                  />
                </div>
                {externalUser.suspicious_posts_by_day
                  ? Object.keys(externalUser.suspicious_posts_by_day).map(
                      (day: string) => {
                        return (
                          <div>
                            <p>{day}</p>
                            <ul>
                              {externalUser.suspicious_posts_by_day[day].map(
                                (externalUrl: string) => {
                                  return (
                                    <li>
                                      <a
                                        className={
                                          "text-blue-500 underline hover:text-blue-400"
                                        }
                                        href={externalUrl}
                                        target="_blank"
                                        rel="noreferrer"
                                      >
                                        {externalUrl}
                                      </a>
                                    </li>
                                  );
                                }
                              )}
                            </ul>
                          </div>
                        );
                      }
                    )
                  : null}
              </div>
            );
          })}
          {input.stripe_external_accounts.map(
            (stripeAccount: StripeExternalAccount) => {
              return (
                <div className={"flex flex-row items-center space-x-8"}>
                  <div>
                    <p>Blocked Creator ID:</p>
                    <a
                      className={"text-blue-500 underline hover:text-blue-400"}
                      href={creatorAdminLink(stripeAccount.creator_id)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {stripeAccount.creator_id}
                    </a>
                  </div>
                  <div>
                    <p>Fingerprint:</p>
                    <a
                      className={"text-blue-500 underline hover:text-blue-400"}
                      href={stripeSearchLink(stripeAccount.fingerprint)}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {stripeAccount.fingerprint}
                    </a>
                  </div>
                </div>
              );
            }
          )}
        </div>
      </BonsaiPanel>
      <BonsaiActionPanel
        onSubmit={async () => {
          onComplete({ outcome: selected });
        }}
        taskId={taskId}
        taskDescription={queue.taskDescription}
        submitDisabled={selected === null}
      >
        <div className="isolate inline-flex rounded-md shadow-sm">
          <button
            type="button"
            className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
              selected === UserVerificationOutcome.BLOCK
                ? "bg-red-600 text-white hover:bg-red-600"
                : "bg-white hover:bg-gray-50"
            }`}
            onClick={() => setSelected(UserVerificationOutcome.BLOCK)}
          >
            Block
          </button>
          <button
            type="button"
            className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
              selected === UserVerificationOutcome.APPROVE
                ? "bg-green-500 text-white hover:bg-green-500 "
                : "bg-white hover:bg-gray-50"
            }`}
            onClick={() => setSelected(UserVerificationOutcome.APPROVE)}
          >
            Approve
          </button>
        </div>
      </BonsaiActionPanel>
    </div>
  );
}
