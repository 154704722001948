import React, { ReactNode } from 'react';
import { Heading, PageHeading } from '../catalyst/heading';
import { Divider } from '../catalyst/divider';
import clsx from 'clsx';
import { BookmarkIcon } from '@heroicons/react/24/outline';
import { Button } from '../catalyst/button';

interface PageLayoutProps {
  children?: ReactNode;
  isPending?: boolean;
  error?: Error | null;
}

export default function PageLayout({
  children,
  isPending,
  error,
}: PageLayoutProps) {
  if (isPending) {
    return (
      <svg
        className="... mr-3 h-5 w-5 animate-spin"
        viewBox="0 0 24 24"
      ></svg>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return <div className="flex flex-col gap-y-2">{children}</div>;
}

interface PageHeaderProps {
  divider?: boolean;
  children?: ReactNode;
}

export function PageHeader({ divider, children }: PageHeaderProps) {
  return (
    <div
      className={clsx(
        'flex w-full flex-wrap items-end justify-between gap-4 pb-6',
        divider && 'border-b border-zinc-950/10 dark:border-white/10'
      )}
    >
      {children}
    </div>
  );
}

interface PageTitleProps {
  children?: ReactNode;
}

export function PageTitle({ children }: PageTitleProps) {
  return (
    <PageHeading>
      <div className="flex flex-row items-center gap-x-2">{children}</div>
    </PageHeading>
  );
}

interface PageActionsProps {
  children?: ReactNode;
}

export function PageActions({ children }: PageActionsProps) {
  return <div className="flex gap-4">{children}</div>;
}

interface PageContentProps {
  children?: ReactNode;
}

export function PageContent({ children }: PageContentProps) {
  return <div>{children}</div>;
}

interface PageDetailedContentProps {
  primary: ReactNode;
  details: ReactNode;
}

export function PageDetailedContent({
  primary,
  details,
}: PageDetailedContentProps) {
  return (
    <div className="flex flex-row gap-14">
      <div className="flex-1">{primary}</div>
      <div className="w-72">{details}</div>
    </div>
  );
}

interface PageSectionListProps {
  children?: ReactNode;
}

export function PageSectionList({ children }: PageSectionListProps) {
  return <ul className="grid gap-y-6">{children}</ul>;
}

interface PageSectionProps {
  title: string;
  actions?: ReactNode;
  children?: ReactNode;
  empty?: boolean;
  emptyPrompt?: ReactNode;
}

export function PageSection({
  title,
  actions,
  children,
  empty,
  emptyPrompt,
}: PageSectionProps) {
  return (
    <div>
      <div className="flex min-h-9 flex-row items-center justify-between text-center">
        <Heading>{title}</Heading>
        {actions && <div className="flex flex-row gap-x-2">{actions}</div>}
      </div>
      {empty ? (
        <div className="text-medium mt-2 flex h-32 w-full items-center justify-center rounded-lg border-2 border-dashed border-gray-200 text-center text-sm text-gray-400">
          {emptyPrompt}
        </div>
      ) : (
        <>
          <Divider className="mt-1" />
          <div className="py-2">{children}</div>
        </>
      )}
    </div>
  );
}

interface DetailSectionListProps {
  children?: ReactNode;
}

export function DetailSectionList({ children }: DetailSectionListProps) {
  return <div className="flex flex-col gap-y-4">{children}</div>;
}

interface DetailSectionProps {
  title: string;
  children?: ReactNode;
}

export function DetailSection({ title, children }: DetailSectionProps) {
  return (
    <div className="flex flex-col gap-y-1">
      <div className="text-sm font-semibold">{title}</div>
      {children}
    </div>
  );
}
