import { createContext, useReducer } from "react";
import { ScoreReducer, ScoreState } from "../reducers/ScoreReducer";
import { Score } from "@kalecard/common";

export const ScoreContext = createContext<{
  state: ScoreState;
  dispatch: React.Dispatch<{}>;
}>(null);

export default function ScoreProvider({
  scores,
  children,
}: {
  scores?: Score[];
  children?: any;
}) {
  const [state, dispatch] = useReducer(ScoreReducer, getInitialState(scores));

  return (
    <ScoreContext.Provider value={{ state: state, dispatch }}>
      {children}
    </ScoreContext.Provider>
  );
}

function getInitialState(scores: Score[]): ScoreState {
  return {
    scores: scores || [],
    points:
      scores
        ?.filter((score) => score.isSelected)
        .reduce((acc, score) => acc + score.points, 0) || 0,
    setBy:
      scores
        ?.filter((score) => score.isSelected)
        .find((score) => score?.setBy !== null)?.setBy || "",
  };
}
