import { CommonToolProps } from '../BonsaiTypes';
import { BonsaiActionPanel } from '../BonsaiContainer';
import {
  bonsaiContextLinkTypeForExternalUrl,
  BonsaiLink,
  BonsaiPanel,
  BonsaiPanelHeader,
} from '@kalecard/common';
import React from 'react';
import { Textarea } from '../../catalyst/textarea';
import { clsx } from 'clsx';
import { TextLink } from '../../catalyst/text';

interface Account {
  url: string;
  platform: string;
}

interface AccountReviewInput {
  creator_id: string;
  accounts: Account[];
  reasons: string[];
  outcome?: AccountReviewOutput;
}

interface AccountReviewOutput {
  notes: string;
  selectedReasons: string[];
  outcome: AccountReviewOutcome;
}

enum AccountReviewOutcome {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
  BLOCKED = 'BLOCKED',
}

interface AccountReviewProps
  extends CommonToolProps<AccountReviewInput, AccountReviewOutput> {}

export default function AccountReviewTool(props: AccountReviewProps) {
  const [state, setState] = React.useState<{
    selectedReasons: Set<string>;
    notes: string;
    outcome?: AccountReviewOutcome;
  }>(() => ({
    selectedReasons: new Set(props.input.outcome?.selectedReasons ?? []),
    notes: props.input.outcome?.notes ?? '',
    outcome: props.input.outcome?.outcome,
  }));

  const readonly = !!props.input.outcome;
  const blockDisabled = !!props.input.outcome;

  React.useEffect(() => {
    if (props.input.outcome) {
      setState({
        selectedReasons: new Set(props.input.outcome.selectedReasons),
        notes: props.input.outcome.notes,
        outcome: props.input.outcome.outcome,
      });
    }
  }, [props.input.outcome]);

  const resetState = () => {
    setState({
      selectedReasons: new Set(),
      notes: '',
      outcome: undefined,
    });
  };

  console.log('state', state);

  const handleReasonToggle = (option: string) => {
    setState((prev) => {
      const newSelectedReasons = new Set(prev.selectedReasons);
      if (newSelectedReasons.has(option)) {
        newSelectedReasons.delete(option);
      } else {
        newSelectedReasons.add(option);
      }
      return { ...prev, selectedReasons: newSelectedReasons };
    });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex-1">
        <div className="flex min-h-[560px] flex-row gap-6">
          <div className="flex-1">
            <BonsaiPanel>
              <div className="h-full flex-row justify-center p-4">
                <BonsaiPanelHeader>Social Accounts</BonsaiPanelHeader>
                <div className="mt-8 flex flex-col gap-6">
                  {props.input.accounts.map((account) => (
                    <div
                      key={account.url}
                      className="flex flex-row items-center"
                    >
                      <BonsaiLink
                        type={bonsaiContextLinkTypeForExternalUrl(account.url)}
                        url={account.url}
                      />
                      <TextLink
                        className="pl-2"
                        href={account.url}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {account.url}
                      </TextLink>
                    </div>
                  ))}
                </div>
              </div>
            </BonsaiPanel>
          </div>

          <div className="flex-1">
            <BonsaiPanel>
              <div className="h-full flex-row justify-center p-4">
                <BonsaiPanelHeader>Reasons</BonsaiPanelHeader>
                <div className="mt-4 grid grid-cols-2 gap-4">
                  {props.input.reasons?.map((option) => (
                    <OptionButton
                      key={option}
                      disabled={readonly}
                      selected={state.selectedReasons.has(option)}
                      onClick={() => handleReasonToggle(option)}
                    >
                      {option}
                    </OptionButton>
                  ))}
                </div>
              </div>
            </BonsaiPanel>
          </div>

          <div className="flex-1">
            <Textarea
              className="h-full"
              disabled={readonly}
              placeholder="Add some review notes here"
              resizable={false}
              value={state.notes}
              onChange={(e) =>
                setState((prev) => ({ ...prev, notes: e.target.value }))
              }
            />
          </div>
        </div>
      </div>

      <BonsaiActionPanel
        submitDisabled={!state.outcome}
        onSubmit={async () => {
          props.onComplete({
            notes: state.notes,
            selectedReasons: Array.from(state.selectedReasons),
            outcome: state.outcome!,
          });
          if (!props.input.outcome) {
            resetState();
          }
        }}
        taskDescription={props.queue.taskDescription}
        taskId={props.taskId}
      >
        <div className="flex flex-row gap-2">
          <AccountReviewOutcomeControls
            selected={state.outcome}
            onSelected={(outcome) => setState((prev) => ({ ...prev, outcome }))}
            blockDisabled={blockDisabled}
          />
        </div>
      </BonsaiActionPanel>
    </div>
  );
}

interface AccountReviewOutcomeControlsProps {
  selected?: AccountReviewOutcome;
  onSelected: (outcome: AccountReviewOutcome) => void;
  disabled?: boolean;
  blockDisabled?: boolean;
}

export function AccountReviewOutcomeControls(
  props: AccountReviewOutcomeControlsProps
) {
  const accepted = props.selected === AccountReviewOutcome.ACCEPTED;
  const rejected = props.selected === AccountReviewOutcome.REJECTED;
  const blocked = props.selected === AccountReviewOutcome.BLOCKED;
  return (
    <>
      <span className="isolate mr-1 inline-flex rounded-md shadow-sm">
        <button
          disabled={props.disabled}
          type="button"
          className={clsx(
            'relative inline-flex items-center rounded-l-lg px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10',
            accepted ? 'bg-green-500 text-white' : 'bg-white',
            !props.disabled && !accepted && 'hover:bg-gray-50',
            props.disabled && 'cursor-not-allowed opacity-50'
          )}
          onClick={() => props.onSelected(AccountReviewOutcome.ACCEPTED)}
        >
          Accept
        </button>
        <button
          disabled={props.disabled}
          type="button"
          className={clsx(
            'relative -ml-px inline-flex items-center px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10',
            rejected ? 'bg-amber-500 text-white' : 'bg-white',
            !props.disabled && !rejected && 'hover:bg-gray-50',
            props.disabled && 'cursor-not-allowed opacity-50'
          )}
          onClick={() => props.onSelected(AccountReviewOutcome.REJECTED)}
        >
          Reject
        </button>
        <button
          disabled={props.disabled || props.blockDisabled}
          type="button"
          className={clsx(
            'relative -ml-px inline-flex items-center rounded-r-lg px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10',
            blocked ? 'bg-red-500 text-white' : 'bg-white',
            !props.disabled && !blocked && 'hover:bg-gray-50',
            props.disabled && 'cursor-not-allowed opacity-50'
          )}
          onClick={() => props.onSelected(AccountReviewOutcome.BLOCKED)}
        >
          Block creator
        </button>
      </span>
    </>
  );
}

function OptionButton({
  children,
  selected,
  disabled,
  onClick,
}: {
  children: React.ReactNode;
  selected: boolean;
  disabled: boolean;
  onClick: () => void;
}) {
  return (
    <button
      onClick={onClick}
      className={clsx(
        'flex h-16 items-center justify-center rounded-md border border-slate-300 p-2 text-sm font-bold',
        !selected && 'bg-slate-50 text-slate-600',
        selected && 'border-slate-400 bg-slate-300 text-slate-700 shadow-inner',
        !disabled && 'hover:bg-slate-200',
        disabled && 'cursor-not-allowed opacity-70'
      )}
    >
      {children}
    </button>
  );
}
