import { useMutation } from '@apollo/client';
import BrandTransactionForm from '../components/forms/BrandTransactionForm';
import { Spinner } from '@kalecard/common';
import { SET_BRAND_PAUSE_CHALLENGES } from '../graphql/mutations';
import { useState } from 'react';
import { Brand } from '../__generated__/graphql';

export default function BrandActionsPage({ brand }: { brand: Brand }) {
  return (
    <div className="flex flex-col space-y-2">
      <PauseBrandButton brand={brand} />
      <BrandTransactionForm brand={brand} />
    </div>
  );
}

function PauseBrandButton({ brand }: { brand: Brand }) {
  const [isLoading, setIsLoading] = useState(false);
  const [setBrandPauseChallenges] = useMutation(SET_BRAND_PAUSE_CHALLENGES);
  const [isChallengesPaused, setIsChallengesPaused] = useState(
    brand.challengesPaused
  );
  return (
    <div className="space-y-2 rounded-md border-2 p-2">
      <h3 className="text-base">Pause/Resume brand challenges</h3>
      <div className="flex space-x-2">
        <button
          disabled={isLoading}
          onClick={async () => {
            setIsLoading(true);
            const result = await setBrandPauseChallenges({
              variables: {
                brandId: brand.id,
                shouldPause: !isChallengesPaused,
              },
            });
            if (result.data?.setBrandPauseChallenges) {
              setIsChallengesPaused(!isChallengesPaused);
            }
            setIsLoading(false);
          }}
          className={`inline-flex justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-white shadow-sm focus:outline-none focus:ring-2 ${
            isChallengesPaused
              ? 'bg-green-600 hover:bg-green-700 focus:ring-green-500 focus:ring-offset-2 disabled:bg-green-300'
              : 'bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-2 disabled:bg-red-300'
          }`}
        >
          {isChallengesPaused
            ? 'Resume brand challenges'
            : 'Pause brand challenges'}
        </button>
        {isLoading && <Spinner size="h-5 w-5" />}
      </div>
    </div>
  );
}
