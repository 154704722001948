import { useQuery } from "@apollo/client";
import { Spinner, TemplatedMessage } from "@kalecard/common";
import { useState } from "react";
import { TEMPLATED_MESSAGES } from "../../graphql/queries";

interface TemplatedMessagesInterface {
  userId: string;
  templatedMessage: (message: string) => void;
}

export default function TemplatedMessages({
  userId,
  templatedMessage,
}: TemplatedMessagesInterface) {
  const [templatedMessages, setTemplatedMessages] =
    useState<TemplatedMessage[]>();

  const onQueryComplete = (data) => {
    console.log(data.templatedMessages);
    setTemplatedMessages(data.templatedMessages);
  };

  const { loading } = useQuery<TemplatedMessage[]>(TEMPLATED_MESSAGES, {
    variables: {
      creatorId: userId,
    },
    onCompleted: (data) => onQueryComplete(data),
  });

  var footer = null;
  if (loading) {
    footer = (
      <div className="flex flex-wrap content-center">
        <Spinner size="h-6 w-6" />
      </div>
    );
  }

  return (
    <>
      <div className="flex w-full justify-between space-x-1">
        {templatedMessages &&
          templatedMessages.map((message, index) => {
            return (
              <button
                key={index}
                type="button"
                onClick={() => {
                  templatedMessage(message.templateText);
                }}
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-xs font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
              >
                {message.buttonText}
              </button>
            );
          })}
      </div>
      {footer}
    </>
  );
}
