export default function DealStateComponent({ state }) {
  switch (state) {
    case "NEW":
      return (
        <span className="inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-sm font-medium text-yellow-800">
          New
        </span>
      );
    case "ACCEPTED":
      return (
        <span className="inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-sm font-medium text-yellow-800">
          Accepted
        </span>
      );
    case "DECLINED":
      return (
        <span className="inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800">
          Declined
        </span>
      );
    case "EXPIRED":
      return (
        <span className="inline-flex items-center rounded-md bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800">
          Expired
        </span>
      );
    case "POSTED":
      return (
        <span className="inline-flex items-center rounded-md bg-purple-100 px-2.5 py-0.5 text-sm font-medium text-purple-800">
          Posted
        </span>
      );
    case "PAID":
      return (
        <span className="inline-flex items-center rounded-md bg-green-100 px-2.5 py-0.5 text-sm font-medium text-green-800">
          Complete
        </span>
      );
    case "PENDING_RECEIPT":
      return (
        <span className="inline-flex items-center rounded-md bg-orange-100 px-2.5 py-0.5 text-sm font-medium text-orange-800">
          Pending Receipt
        </span>
      );
    case "UNDONE":
      return (
        <span className="inline-flex items-center rounded-md bg-orange-100 px-2.5 py-0.5 text-sm font-medium text-orange-800">
          Deal refunded
        </span>
      );
    case "PENDING_REVIEW":
      return (
        <span className="inline-flex items-center rounded-md bg-orange-100 px-2.5 py-0.5 text-sm font-medium text-orange-800">
          Pending Review
        </span>
      );
    default:
      return (
        <span className="inline-flex items-center rounded-md bg-yellow-100 px-2.5 py-0.5 text-sm font-medium text-yellow-800">
          {state}
        </span>
      );
  }
}
