import { CommonToolProps } from "../BonsaiTypes";
import {
  BonsaiContextLinkType,
  bonsaiContextLinkTypeForExternalUrl,
  BonsaiOutcome,
  BonsaiPanel,
  BonsaiVideoPanel,
  OutcomeControls,
} from "@kalecard/common";
import React, { useState } from "react";
import { BonsaiActionPanel } from "../BonsaiContainer";
import Markdown from "react-markdown";

interface ReviewGeminiCompletionOutput {
  outcome: BonsaiOutcome;
}

interface ReviewGeminiCompletionInput {
  dealId: string;
  videoSource: string;
  creatorId: string;
  completion: string;
  platformPermalink: string;
}

interface ReviewGeminiCompletionToolProps
  extends CommonToolProps<
    ReviewGeminiCompletionInput,
    ReviewGeminiCompletionOutput
  > {}

export function ReviewGeminiCompletionTool(
  props: ReviewGeminiCompletionToolProps
) {
  const [selectedOutcome, setSelectedOutcome] =
    useState<BonsaiOutcome>(undefined);

  const links = [
    {
      type: BonsaiContextLinkType.CREATOR,
      url: `/creators/${props.input.creatorId}/messages`,
    },
    {
      type: bonsaiContextLinkTypeForExternalUrl(props.input.platformPermalink),
      url: props.input.platformPermalink,
    },
  ];

  return (
    <>
      <div className="flex flex-row gap-4">
        <div className={"flex-1"}>
          <BonsaiPanel>
            <div className="flex h-full flex-col justify-between p-4">
              <div className="flex-row justify-center space-y-4">
                <div className="prose">
                  <Markdown>{props.input.completion}</Markdown>
                </div>
              </div>
            </div>
          </BonsaiPanel>
        </div>
        <div className={"flex-1"}>
          <BonsaiVideoPanel
            source={props.input.videoSource}
            links={links}
            autoPlay={false}
          />
        </div>
      </div>
      <BonsaiActionPanel
        submitDisabled={selectedOutcome === undefined}
        onSubmit={() => {
          props.onComplete({ outcome: selectedOutcome });
          setSelectedOutcome(undefined);
        }}
        taskDescription={props.queue.taskDescription}
        taskId={props.taskId}
      >
        <OutcomeControls
          onSelected={(outcome) => {
            setSelectedOutcome(outcome);
          }}
          selected={selectedOutcome}
        />
      </BonsaiActionPanel>
    </>
  );
}
