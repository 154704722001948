import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-quartz.css'; // Optional Theme applied to the Data Grid
import { useEffect, useRef, useState } from 'react';
import { getKaleBrands } from '../utils/brands';
import { Badge } from '../components/catalyst/badge';
import { prettyDecimal } from '@kalecard/common';
import { Link } from '../components/catalyst/link';
import { useLocation } from 'react-router-dom';
import { ColDef, SortDirection } from 'ag-grid-community';

type RowData = {
  name: string;
  normalizedIdealTargetDiff: string;
  currBudget: string;
  contentBudgetType: string;
  pacingWithSage: boolean;
  budgetUtilization: string;
  monthlyMaxCreatorChallenges: number;
  requiresHighQualityScore: boolean;
  instagramAutoDm: boolean;
  numActiveChallenges: number;
  isProofOfPurchaseRequired: boolean;
  numChallengesPerReceipt: number;
  hasChallengeBudgetAllocation: boolean;
  platformFee: string;
  maxBudget: string;
  status: string;
  accountManager: string;
  isPaused: boolean;
};

type KaleColDef = { index?: number } & ColDef;

type ColOrder = {
  [key: string]: number;
};

const boolCellRenderer = (params) => {
  if (params.value !== null) {
    return (
      <Badge color={params.value ? 'emerald' : 'red'}>
        {params.value.toString()}
      </Badge>
    );
  }
};

const statusCellRenderer = (params) => {
  switch (params.value) {
    case 'LIVE':
      return <Badge color="emerald">Live</Badge>;
    case 'MISSING_INFO':
      return <Badge color="amber">Missing info</Badge>;
    case 'READY_TO_GO_LIVE':
      return <Badge color="indigo">Ready to go live</Badge>;
    case 'OFF_BOARDING':
      return <Badge color="cyan">Offboarding</Badge>;
  }
};

const numberCellRenderer = (params) => {
  return <>{params.value.display}</>;
};

const numberComparator = (a, b) => {
  return a.value - b.value;
};

export default function HealthCheck() {
  const gridRef = useRef<AgGridReact>();
  const filterInputRef = useRef(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  let columns = queryParams.get('columns')?.split(',');
  let colOrder: ColOrder = null;
  if (columns) {
    colOrder = {};
    columns.forEach((col) => {
      const split = col.split(':');
      colOrder[split[0]] = Number(split[1]);
    });
  }
  const [rowData, setRowData] = useState<RowData[]>([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [quickFilterText, setQuickFilterText] = useState('');
  const sortColumn = queryParams.get('sortColumn');
  const sortDirection = queryParams.get('sortDirection') as SortDirection;
  const [loading, setLoading] = useState(true);

  const createColDef = ({
    field,
    headerName,
    flex = 1,
    comparator = null,
    hide = colOrder && !Object.keys(colOrder).includes(field),
    cellRenderer = null,
    getQuickFilterText = null,
  }: KaleColDef): KaleColDef => {
    return {
      index: colOrder?.[field],
      field: field as keyof RowData,
      headerName: headerName,
      flex: flex,
      comparator: comparator,
      hide: hide,
      cellRenderer: cellRenderer,
      sort: sortColumn === field ? sortDirection : undefined,
      getQuickFilterText: getQuickFilterText,
    };
  };

  const allColDefs = {
    name: createColDef({
      field: 'name',
      headerName: 'Name',
      flex: 1.5,
      hide: false,
      getQuickFilterText: (params) => {
        return params.data.name.name;
      },
      comparator: (a, b) => a.name.localeCompare(b.name),
      cellRenderer: (params) => (
        <Link href={`/brands/${params.value.id}/setup`}>
          <div className="flex items-center text-blue-500 hover:text-blue-900">
            <img
              className="mr-4 h-8 w-8 rounded-full"
              src={params.value.logoUrl}
              alt=""
            />
            <>
              <div className="mr-4 underline">{params.value.name}</div>
              <strong className="text-black">ID: {params.value.id}</strong>
            </>
          </div>
        </Link>
      ),
    }),
    normalizedIdealTargetDiff: createColDef({
      field: 'normalizedIdealTargetDiff',
      headerName: 'Normalized Ideal Target Budget Difference',
      comparator: numberComparator,
      cellRenderer: numberCellRenderer,
    }),
    currBudget: createColDef({
      field: 'currBudget',
      headerName: 'Current Content Budget',
      comparator: numberComparator,
      cellRenderer: numberCellRenderer,
    }),
    contentBudgetType: createColDef({
      field: 'contentBudgetType',
      headerName: 'Content Budget Type',
      cellRenderer: (params) => (
        <Badge
          color={params.value === 'Monthly Content Budget' ? 'emerald' : 'blue'}
        >
          {params.value}
        </Badge>
      ),
    }),
    pacingWithSage: createColDef({
      field: 'pacingWithSage',
      headerName: 'Pacing With Sage',
      cellRenderer: boolCellRenderer,
    }),
    budgetUtilization: createColDef({
      field: 'budgetUtilization',
      headerName: 'Budget Utilization',
      cellRenderer: numberCellRenderer,
      comparator: numberComparator,
    }),
    monthlyMaxCreatorChallenges: createColDef({
      field: 'monthlyMaxCreatorChallenges',
      headerName: 'Monthly Max Posts per Creator',
    }),
    requiresHighQualityScore: createColDef({
      field: 'requiresHighQualityScore',
      headerName: 'Requires High Quality Score',
      cellRenderer: boolCellRenderer,
    }),
    instagramAutoDm: createColDef({
      field: 'instagramAutoDm',
      headerName: 'Instagram Auto DM',
      cellRenderer: boolCellRenderer,
    }),
    numActiveChallenges: createColDef({
      field: 'numActiveChallenges',
      headerName: '# Active Challenges',
    }),
    isProofOfPurchaseRequired: createColDef({
      field: 'isProofOfPurchaseRequired',
      headerName: 'Proof of Purchase Required',
      cellRenderer: boolCellRenderer,
    }),
    numChallengesPerReceipt: createColDef({
      field: 'numChallengesPerReceipt',
      headerName: '# Challenges per Receipt',
      comparator: (a, b) => {
        const numA = a === '-' ? Number.NEGATIVE_INFINITY : parseFloat(a);
        const numB = b === '-' ? Number.NEGATIVE_INFINITY : parseFloat(b);
        return numA - numB;
      },
    }),
    hasChallengeBudgetAllocation: createColDef({
      field: 'hasChallengeBudgetAllocation',
      headerName: 'Has Challenge Budget Allocation',
      cellRenderer: boolCellRenderer,
    }),
    platformFee: createColDef({
      field: 'platformFee',
      headerName: 'Platform Fee',
      cellRenderer: numberCellRenderer,
      comparator: numberComparator,
    }),
    maxBudget: createColDef({
      field: 'maxBudget',
      headerName: 'Max Content Budget',
      cellRenderer: numberCellRenderer,
      comparator: numberComparator,
    }),
    status: createColDef({
      field: 'status',
      headerName: 'Status',
      cellRenderer: statusCellRenderer,
    }),
    accountManager: createColDef({
      field: 'accountManager',
      headerName: 'Account Manager',
    }),
    isPaused: createColDef({
      field: 'isPaused',
      headerName: 'Paused',
      cellRenderer: boolCellRenderer,
    }),
  };

  const [colDefs, setColDefs] = useState<Record<string, KaleColDef>>(() => {
    const defaultSettings = localStorage.getItem('defaultTableSettings');
    if (columns === undefined) {
      if (defaultSettings) {
        window.history.replaceState({}, '', defaultSettings);
        window.location.reload();
      } else return allColDefs;
    }
    const cols = {};
    Object.keys(allColDefs)
      .sort((keyA, keyB) => allColDefs[keyA].index - allColDefs[keyB].index)
      .forEach((key) => {
        cols[key] = allColDefs[key];
      });
    return cols;
  });

  const [width, setWidth] = useState(
    (Object.values(colDefs).filter((val) => !val.hide).length + 1) * 215
  );

  function CustomLoading({ width }) {
    return (
      <div style={{ width: width * 0.8 }}>
        <div className="bg-gray-200">
          <div className="h-2 animate-pulse bg-green-600"></div>
        </div>
      </div>
    );
  }

  async function getAllKaleBrands() {
    const response = await getKaleBrands(100000);
    const kaleBrands = response.data.brands.edges;
    const kaleBrandsMap = new Map();
    kaleBrands.forEach((brand) => {
      const currBudget = brand.node.contentBudget;
      const maxBudget = brand.node.contentBudgetMax;
      const effectiveBudget = brand.node.sageDetails.effectiveBudget;
      const targetBudget = brand.node.sageDetails.targetBudget;
      const budgetUtilization = Math.round(
        ((maxBudget - currBudget) / maxBudget) * 100
      );
      kaleBrandsMap.set(brand.node.id, {
        name: {
          name: brand.node.name,
          logoUrl: brand.node.logoUrl,
          id: brand.node.id,
        },
        normalizedIdealTargetDiff: {
          display:
            brand.node.isPacingWithSage &&
            effectiveBudget !== null &&
            targetBudget !== null &&
            maxBudget
              ? Math.round((effectiveBudget - targetBudget) / maxBudget) + '%'
              : '-',
          value:
            brand.node.isPacingWithSage &&
            effectiveBudget !== null &&
            targetBudget !== null &&
            maxBudget
              ? Math.round((effectiveBudget - targetBudget) / maxBudget)
              : Number.NEGATIVE_INFINITY,
        },
        currBudget: {
          display: currBudget
            ? `${currBudget < 0 ? '-$' : '$'}${prettyDecimal(
                Math.round(Math.abs(currBudget))
              )}`
            : '-',
          value: currBudget ? Math.round(currBudget) : Number.NEGATIVE_INFINITY,
        },
        contentBudgetType: brand.node.autoRechargeEnabled
          ? 'Monthly Content Budget'
          : 'Campaign Content Budget',
        pacingWithSage: brand.node.isPacingWithSage,
        budgetUtilization: {
          display: currBudget && maxBudget ? budgetUtilization + '%' : '-',
          value:
            currBudget && maxBudget
              ? budgetUtilization
              : Number.NEGATIVE_INFINITY,
        },
        monthlyMaxCreatorChallenges: brand.node.monthlyMaxCreatorChallenges,
        requiresHighQualityScore: brand.node.requiresHighQualityScore,
        instagramAutoDm: brand.node.isIgStoryReplyEnabled,
        numActiveChallenges: brand.node.activeChallenges.length,
        isProofOfPurchaseRequired: !!brand.node.proofOfPurchase,
        numChallengesPerReceipt:
          brand.node.proofOfPurchase?.numberOfChallenges ?? '-',
        hasChallengeBudgetAllocation:
          !!brand.node.sageDetails.challengeBudgetAllocation,
        platformFee: {
          display: `$${prettyDecimal(Math.round(brand.node.platformFee))}`,
          value: Math.round(brand.node.platformFee),
        },
        maxBudget: {
          display: `$${prettyDecimal(Math.round(maxBudget))}`,
          value: Math.round(maxBudget),
        },
        status: brand.node.status,
        accountManager: brand.node.accountManager?.name,
        isPaused: brand.node.challengesPaused || brand.node.paused,
      });
    });
    setRowData(Array.from(kaleBrandsMap.values()));
    setLoading(false);
  }

  useEffect(() => {
    getAllKaleBrands();
  }, []);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if Ctrl+F or Cmd+F is pressed
      const isCtrlOrCmd = event.ctrlKey || event.metaKey;
      const isFKey = event.key.toLowerCase() === 'f';

      if (isCtrlOrCmd && isFKey) {
        event.preventDefault(); // Prevent the browser's default search
        filterInputRef.current?.focus(); // Focus the input element
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const buildQueryString = (columns: Record<string, KaleColDef>) => {
    const visibleColumns = Object.entries(columns)
      .filter(([_, value]) => !value.hide)
      .map(([key], index) => `${key}:${index}`)
      .join(',');
    const sortedCols = Object.entries(columns).find(
      ([_, value]) => value.sort !== null
    );
    if (sortedCols) {
      const sortedCol = sortedCols[1];
      let string = sortedCol ? `?sortColumn=${sortedCol.field}` : '';
      string += sortedCol ? `&sortDirection=${sortedCol.sort}` : '';
      string += visibleColumns.length ? `&columns=${visibleColumns}` : '';
      return string;
    }
    return visibleColumns.length ? `?columns=${visibleColumns}` : '';
  };

  return (
    <div
      className="flex- ag-theme-quartz m-4 h-full w-full flex-col space-y-4"
      style={{ width: `${width}px` }}
    >
      <div className="flex flex-row space-x-2 text-left">
        <div className="flex items-center space-x-2">
          <span>Quick Filter:</span>
          <input
            ref={filterInputRef}
            className={'rounded-md border border-gray-300 p-2'}
            type="text"
            id="filter-text-box"
            placeholder="Filter..."
            onInput={(value) => {
              setQuickFilterText(value.currentTarget.value);
            }}
          />
        </div>
        <button
          onClick={() => setDropdownOpen(!dropdownOpen)}
          className="40 justify-center rounded-md border border-gray-300 px-4 py-2 text-gray-700 shadow-sm hover:bg-gray-50"
        >
          Toggle Columns
        </button>
        {dropdownOpen && (
          <div className="absolute left-[295px] top-[63px] z-10 w-72 bg-gray-200">
            {Object.values(allColDefs)
              .filter((val) => val.field !== 'name')
              .map((val) => (
                <label
                  key={val.field}
                  className="block px-4 py-2 text-sm text-gray-700"
                >
                  <input
                    type="checkbox"
                    checked={colDefs[val.field] && !colDefs[val.field].hide}
                    onChange={(event) => {
                      setColDefs((prev) => {
                        let newColumnDefs;
                        if (event.target.checked) {
                          newColumnDefs = {
                            ...prev,
                            [val.field]: {
                              ...allColDefs[val.field],
                              hide: false,
                              index: Object.keys(prev).filter(
                                (key) => !prev[key].hide
                              ).length,
                            },
                          };
                        } else {
                          const removedIndex = prev[val.field].index;
                          newColumnDefs = Object.fromEntries(
                            Object.entries(prev)
                              .map(([key, colDef]) => {
                                if (key === val.field) {
                                  return null;
                                }
                                if (colDef.index > removedIndex) {
                                  return [
                                    key,
                                    { ...colDef, index: colDef.index - 1 },
                                  ];
                                }
                                return [key, colDef];
                              })
                              .filter(Boolean)
                          );
                        }
                        setWidth(
                          (Object.values(newColumnDefs).filter(
                            (val: { hide: boolean }) => !val.hide
                          ).length +
                            1) *
                            215
                        );
                        const queryString = buildQueryString(newColumnDefs);
                        window.history.replaceState({}, '', queryString);
                        return newColumnDefs;
                      });
                    }}
                    className="mr-2 cursor-pointer"
                  />
                  {val.headerName}
                </label>
              ))}
          </div>
        )}
        <button
          onClick={() => {
            const queryString = window.location.search;
            localStorage.setItem('defaultTableSettings', queryString);
            alert('Default view set successfully!');
          }}
          className="40 ml-4 justify-center rounded-md border border-gray-300 px-4 py-2 text-gray-700 shadow-sm hover:bg-gray-50"
        >
          Set as Default View
        </button>
      </div>
      <AgGridReact
        ref={gridRef}
        quickFilterText={quickFilterText}
        suppressDragLeaveHidesColumns={true}
        rowData={rowData}
        loading={loading}
        loadingOverlayComponent={CustomLoading}
        loadingOverlayComponentParams={{ width: width }}
        columnDefs={Object.values(colDefs).sort((colDefA, colDefB) => {
          const valA = colDefA.index ?? Number.POSITIVE_INFINITY;
          const valB = colDefB.index ?? Number.POSITIVE_INFINITY;
          return valA - valB;
        })}
        defaultColDef={{ cellStyle: { textAlign: 'center' } }}
        onColumnMoved={(event) => {
          if (event.finished) {
            const allColumns = event.api.getColumnDefs() as KaleColDef[];
            const newColDefs = {};
            allColumns.forEach((col, index) => {
              newColDefs[col.field] = {
                index: index,
                field: col.field,
                headerName: col.headerName,
                flex: col.flex,
                hide: col.hide,
                comparator: col.comparator,
                cellRenderer: col.cellRenderer,
                sort: col.sort as SortDirection,
              };
            });
            const queryString = buildQueryString(newColDefs);
            window.history.replaceState({}, '', queryString);
            setColDefs(newColDefs);
          }
        }}
        onSortChanged={(event) => {
          if (event.source === 'uiColumnSorted') {
            const allColumns = event.api.getColumnDefs() as KaleColDef[];
            const newColDefs = {};
            allColumns.forEach((col, index) => {
              newColDefs[col.field] = {
                index: index,
                field: col.field,
                headerName: col.headerName,
                flex: col.flex,
                hide: col.hide,
                comparator: col.comparator,
                cellRenderer: col.cellRenderer,
                sort: col.sort as SortDirection,
              };
            });
            const queryString = buildQueryString(newColDefs);
            window.history.replaceState({}, '', queryString);
            setColDefs(newColDefs);
          }
        }}
      />
    </div>
  );
}
