import { createContext, useReducer } from "react";
import { BrandPaymentStructuresReducer, BrandPaymentStructuresState } from "../reducers/BrandPaymentStructuresReducer";

export const BrandPaymentStructuresContext = createContext<{
  state: BrandPaymentStructuresState;
  dispatch: React.Dispatch<{}>;
}>(null);

export default function BrandPaymentStructuresProvider({
  brandPaymentStructuresState,
  children,
}: {
  brandPaymentStructuresState?: BrandPaymentStructuresState;
  children?: any;
}) {
  const [state, dispatch] = useReducer(
    BrandPaymentStructuresReducer,
    getInitialState(brandPaymentStructuresState)
  );

  return (
    <BrandPaymentStructuresContext.Provider value={{ state: state, dispatch }}>
      {children}
    </BrandPaymentStructuresContext.Provider>
  );
}

function getInitialState(brandPaymentStructuresState: BrandPaymentStructuresState): BrandPaymentStructuresState {
  return {
    brandPaymentStructures: brandPaymentStructuresState.brandPaymentStructures || {},
    defaultPaymentStructures: brandPaymentStructuresState.defaultPaymentStructures || {}
  };
}