import { Spinner } from '@kalecard/common';
import {
  CHALLENGE_POST_REQUIREMENTS,
  ChallengePostRequirementsInterface,
} from '../graphql/queries';
import { useMutation, useQuery } from '@apollo/client';
import { useState } from 'react';
import {
  ADD_POST_REQUIREMENT_FOR_BRAND,
  REMOVE_POST_REQUIREMENT_FOR_BRAND,
} from '../graphql/mutations';
import {
  requirementForMention,
  RequirementsTableV2,
  TypeBadge,
} from '../components/shared/RequirementsTable';
import { Brand, PostRequirement } from '../__generated__/graphql';
import { Dialog } from '../components/catalyst/dialog';
import { Strong, Text } from '../components/catalyst/text';
import { Radio, RadioField, RadioGroup } from '../components/catalyst/radio';
import { Fieldset, Label, Legend } from '../components/catalyst/fieldset';
import { Button } from '../components/catalyst/button';
import { Badge } from '../components/catalyst/badge';

export default function BrandRequirementsPage({ brand }: { brand: Brand }) {
  const { data, loading, refetch } =
    useQuery<ChallengePostRequirementsInterface>(CHALLENGE_POST_REQUIREMENTS, {
      variables: { brandId: brand.id, onlyMappedToBrand: false },
      fetchPolicy: 'network-only',
    });

  const [selectedRequirement, setSelectedRequirement] = useState(null);
  const [isAddRequirementModalOpen, setIsAddRequirementModalOpen] =
    useState(false);
  const [isUpdateAppliedCountOpen, setIsUpdateAppliedCountOpen] =
    useState(false);
  const [isWarningOpen, setIsWarningOpen] = useState(false);

  const [removePostRequirementForBrand] = useMutation(
    REMOVE_POST_REQUIREMENT_FOR_BRAND,
    {
      refetchQueries: [CHALLENGE_POST_REQUIREMENTS],
    }
  );
  const [addPostRequirementForBrand] = useMutation(
    ADD_POST_REQUIREMENT_FOR_BRAND,
    {
      refetchQueries: [CHALLENGE_POST_REQUIREMENTS],
    }
  );

  console.log(data);

  return !loading ? (
    <div className="rounded-md border-2 p-2">
      <RequirementsTableV2
        requirements={
          (data?.challengePostRequirements as PostRequirement[]) ?? []
        }
        showBrandDetails={true}
        brand={brand}
        actions={[]}
        onAddRequirement={(postRequirement: PostRequirement) => {
          setSelectedRequirement(postRequirement);
          setIsAddRequirementModalOpen(true);
        }}
        onRemoveRequirement={() => {}}
        onNewRequirement={(postRequirement: PostRequirement) => {
          setSelectedRequirement(postRequirement);
          setIsAddRequirementModalOpen(true);
        }}
        onToggleBrandDefault={async (postRequirement: PostRequirement) => {
          if (
            postRequirement.isMappedToBrand &&
            postRequirement.mappedActiveChallengesCount === 0
          ) {
            setIsWarningOpen(true);
            return;
          }
          const variables = {
            brandId: brand.id,
            postRequirementId: postRequirement.id,
            applyToExistingChallenges: false,
            applyToFutureChallenges: true,
          };
          try {
            if (!postRequirement.isMappedToBrand) {
              await addPostRequirementForBrand({
                variables: variables,
              });
            } else {
              await removePostRequirementForBrand({
                variables: variables,
              });
            }
          } catch (ex) {
            console.log(ex);
          }
        }}
        onAppliedCountClick={(postRequirement: PostRequirement) => {
          setSelectedRequirement(postRequirement);
          setIsUpdateAppliedCountOpen(true);
        }}
      />
      <Dialog
        open={isAddRequirementModalOpen}
        onClose={setIsAddRequirementModalOpen}
      >
        <AddPostRequirementToChallenges
          postRequirement={selectedRequirement}
          brand={brand}
          onSubmit={() => setIsAddRequirementModalOpen(false)}
        />
      </Dialog>
      <Dialog
        open={isUpdateAppliedCountOpen}
        onClose={setIsUpdateAppliedCountOpen}
      >
        <UpdateMappedChallengesCount
          postRequirement={selectedRequirement}
          brand={brand}
          onSubmit={() => setIsUpdateAppliedCountOpen(false)}
          showWarning={() => {
            setIsUpdateAppliedCountOpen(false);
            setIsWarningOpen(true);
          }}
        />
      </Dialog>
      <Dialog
        open={isWarningOpen}
        onClose={setIsWarningOpen}
      >
        <RemoveBrandRequirementWarning
          postRequirement={selectedRequirement}
          brand={brand}
          onSubmit={() => setIsWarningOpen(false)}
        />
      </Dialog>
    </div>
  ) : (
    <div className="flex justify-center pt-10">
      <Spinner size={'h-8 w-8'} />
    </div>
  );
}

function AddPostRequirementToChallenges({
  postRequirement,
  brand,
  onSubmit,
}: {
  postRequirement: PostRequirement;
  brand: Brand;
  onSubmit: () => void;
}) {
  const [applyToExistingChallenges, setApplyToExistingChallenges] =
    useState(null);
  const [applyToFutureChallenges, setApplyToFutureChallenges] = useState(null);
  const [loading, setLoading] = useState(false);

  const [addPostRequirementForBrand] = useMutation(
    ADD_POST_REQUIREMENT_FOR_BRAND,
    {
      refetchQueries: [CHALLENGE_POST_REQUIREMENTS],
    }
  );

  const addRequirement = async () => {
    if (applyToExistingChallenges == null || applyToFutureChallenges == null) {
      return;
    }
    setLoading(true);
    try {
      await addPostRequirementForBrand({
        variables: {
          brandId: brand.id,
          postRequirementId: postRequirement.id,
          applyToExistingChallenges: applyToExistingChallenges,
          applyToFutureChallenges: applyToFutureChallenges,
        },
      });
      onSubmit();
    } catch (ex) {
      console.log(ex);
    }
    setLoading(false);
  };

  return (
    <div className="space-y-4">
      <div className="space-y-1">
        <Text>
          <Strong>Requirement:</Strong>
        </Text>
        <div className="space-y-1 rounded-md border bg-gray-50 p-4">
          <Text>
            {postRequirement.type == 'MENTION'
              ? requirementForMention(
                  postRequirement.referenceBrand,
                  postRequirement.externalPlatform
                )
              : postRequirement.requirement}
          </Text>
          <div className="w-fit">
            <TypeBadge type={postRequirement.type} />
          </div>
        </div>
      </div>

      <Fieldset className="flex">
        <div>
          <Legend>Add to existing challenges</Legend>
          <Text>
            If yes, this requirement will be added to all of {brand.name}'s
            existing challenges.
          </Text>
        </div>
        <PostRequirementSettingRadioGroup
          name="existingChallenges"
          value={applyToExistingChallenges}
          setValue={setApplyToExistingChallenges}
        />
      </Fieldset>

      <Fieldset className="flex">
        <div>
          <Legend>Add to future challenges</Legend>
          <Text>
            If yes, this requirement will be automatically added to all of{' '}
            {brand.name}'s future challenges.
          </Text>
        </div>
        <PostRequirementSettingRadioGroup
          name="futureChallenges"
          value={applyToFutureChallenges}
          setValue={setApplyToFutureChallenges}
        />
      </Fieldset>

      <div className="flex items-center space-x-2">
        <Button
          color="indigo"
          onClick={addRequirement}
        >
          Apply and add
        </Button>
        {loading && <Spinner size="h-5 w-5" />}
      </div>
    </div>
  );
}

function UpdateMappedChallengesCount({
  postRequirement,
  brand,
  onSubmit,
  showWarning,
}: {
  postRequirement: PostRequirement;
  brand: Brand;
  onSubmit: () => void;
  showWarning: () => void;
}) {
  const [loading, setLoading] = useState(false);

  const [removePostRequirementForBrand] = useMutation(
    REMOVE_POST_REQUIREMENT_FOR_BRAND,
    {
      refetchQueries: [CHALLENGE_POST_REQUIREMENTS],
    }
  );
  const [addPostRequirementForBrand] = useMutation(
    ADD_POST_REQUIREMENT_FOR_BRAND,
    {
      refetchQueries: [CHALLENGE_POST_REQUIREMENTS],
    }
  );

  const submit = async (isAdd: boolean) => {
    if (!isAdd && !postRequirement.isMappedToBrand) {
      showWarning();
      return;
    }

    setLoading(true);
    const variables = {
      brandId: brand.id,
      postRequirementId: postRequirement.id,
      applyToExistingChallenges: true,
      applyToFutureChallenges: false,
    };
    try {
      if (isAdd) {
        await addPostRequirementForBrand({
          variables: variables,
        });
      } else {
        await removePostRequirementForBrand({
          variables: variables,
        });
      }
      onSubmit();
    } catch (ex) {
      console.log(ex);
    }
    setLoading(false);
  };

  return (
    <div className="space-y-6">
      <div className="space-y-1">
        <Text>
          <Strong>Requirement:</Strong>
        </Text>
        <div className="space-y-1 rounded-md border bg-gray-50 p-4">
          <Text>
            {postRequirement.type == 'MENTION'
              ? requirementForMention(
                  postRequirement.referenceBrand,
                  postRequirement.externalPlatform
                )
              : postRequirement.requirement}
          </Text>
          <div className="w-fit">
            <TypeBadge type={postRequirement.type} />
          </div>
        </div>
      </div>

      <div className="flex items-center space-x-2">
        <Text>
          <Strong>Applies/Total:</Strong>
        </Text>
        <Badge
          color={
            postRequirement.mappedActiveChallengesCount <
            brand?.activeChallenges?.length
              ? 'fuchsia'
              : 'emerald'
          }
        >
          {postRequirement.mappedActiveChallengesCount}/
          {brand?.activeChallenges?.length}
        </Badge>
      </div>

      <div className="flex items-center justify-between">
        {postRequirement.mappedActiveChallengesCount <
          brand.activeChallenges?.length && (
          <Button
            color="emerald"
            className="hover:cursor-pointer"
            onClick={() => submit(true)}
          >
            Add to{' '}
            {brand.activeChallenges?.length -
              postRequirement.mappedActiveChallengesCount}{' '}
            challenge
            {brand.activeChallenges?.length -
              postRequirement.mappedActiveChallengesCount >
            1
              ? 's'
              : ''}
          </Button>
        )}
        {postRequirement.mappedActiveChallengesCount > 0 && (
          <Button
            color="red"
            className="hover:cursor-pointer"
            onClick={() => submit(false)}
          >
            Remove from {postRequirement.mappedActiveChallengesCount} challenge
            {postRequirement.mappedActiveChallengesCount > 1 ? 's' : ''}
          </Button>
        )}
        {loading && <Spinner size="h-5 w-5" />}
      </div>
    </div>
  );
}

function PostRequirementSettingRadioGroup({
  name,
  value,
  setValue,
}: {
  name: string;
  value: boolean;
  setValue: (value: React.SetStateAction<boolean>) => void;
}) {
  const handleChange = (val) => {
    const newValue = val === 'yes';
    setValue(newValue);
  };

  const defaultValue = value === true ? 'yes' : value === false ? 'no' : null;
  return (
    <RadioGroup
      name={name}
      defaultValue={defaultValue}
      onChange={handleChange}
    >
      <div className="flex flex-col">
        <RadioField>
          <Label>Yes</Label>
          <Radio
            value="yes"
            color="emerald"
          />
        </RadioField>
        <RadioField>
          <Label>No</Label>
          <Radio
            value="no"
            color="red"
          />
        </RadioField>
      </div>
    </RadioGroup>
  );
}

function RemoveBrandRequirementWarning({
  postRequirement,
  brand,
  onSubmit,
}: {
  postRequirement: PostRequirement;
  brand: Brand;
  onSubmit: () => void;
}) {
  const [removePostRequirementForBrand] = useMutation(
    REMOVE_POST_REQUIREMENT_FOR_BRAND,
    {
      refetchQueries: [CHALLENGE_POST_REQUIREMENTS],
    }
  );
  const [loading, setLoading] = useState(false);

  const removeRequirement = async () => {
    setLoading(true);
    try {
      removePostRequirementForBrand({
        variables: {
          postRequirementId: postRequirement.id,
          brandId: brand.id,
          applyToExistingChallenges: true,
          applyToFutureChallenges: true,
        },
      });
      onSubmit();
    } catch (err) {
      console.log(err);
    }
    setLoading(false);
  };

  return (
    <div className="space-y-6">
      <div className="space-y-1">
        <Text>
          <Strong>Requirement:</Strong>
        </Text>
        <div className="space-y-1 rounded-md border bg-gray-50 p-4">
          <Text>
            {postRequirement.type == 'MENTION'
              ? requirementForMention(
                  postRequirement.referenceBrand,
                  postRequirement.externalPlatform
                )
              : postRequirement.requirement}
          </Text>
          <div className="w-fit">
            <TypeBadge type={postRequirement.type} />
          </div>
        </div>
      </div>
      <div className="space-y-1">
        <Text>
          <Strong>Warning 🚨:</Strong>
        </Text>
        <Text>
          By clicking "Yes, remove" below, you acknowledge that this requirement
          will no longer be applied to any existing or future challenges for
          this brand. You will no longer see it in this requirements table.
        </Text>
      </div>
      <div className="flex items-center space-x-2">
        <Button
          color="red"
          className="hover:cursor-pointer"
          onClick={removeRequirement}
        >
          Yes, remove
        </Button>
        {loading && <Spinner size="h-5 w-5" />}
      </div>
    </div>
  );
}
