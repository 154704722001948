import PostScoreComponent from "./PostScoreComponent";
import { useContext } from "react";
import { ScoreContext } from "../../providers/ScoreProvider";
import { prettyDecimal } from "@kalecard/common";

export default function PostScoreList({
  postId,
}: {
  postId: string;
  isScoreDisabled?: boolean;
}) {
  const { state } = useContext(ScoreContext);

  return (
    <>
      {state?.scores && state.scores.length > 0 && (
        <div className="pb-2">
          <div className="flex flex-col border-b">
            <p className="text-base font-medium text-gray-900">
              Score - {prettyDecimal(state.points / 100)}
            </p>
            {state?.setBy && (
              <p className="text-xs font-medium text-gray-500">
                Scored by {state?.setBy}
              </p>
            )}
          </div>
        </div>
      )}
      {state?.scores?.map((score) => (
        <PostScoreComponent
          key={`${postId}-${score.id}`}
          score={score}
          postId={postId}
        />
      ))}
    </>
  );
}
