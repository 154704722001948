import { useEffect, useState } from 'react';
import { BonsaiQueue } from '../BonsaiModels';
import { BonsaiActionPanel } from '../BonsaiContainer';
import { Strong, TextLink } from '../../catalyst/text';
import {
  BonsaiPanel,
  BonsaiOutcome,
  BonsaiContextLinkType,
  BonsaiLink,
} from '@kalecard/common';
import { Text } from '../../catalyst/text';

import {} from '@heroicons/react/24/outline';
import { RejectionReason, RequirementItem } from './PostScoringTool';
import { Listbox, ListboxLabel, ListboxOption } from '../../catalyst/listbox';
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
} from '../../catalyst/dialog';
import { Checkbox, CheckboxField } from '../../catalyst/checkbox';
import { Label } from '../../catalyst/fieldset';
import { Button } from '../../catalyst/button';

interface PhotoUploadReviewInput {
  photoUrls: string[];
  rejectionReasons: RejectionReason[];
  challengeId: number;
  challengeTitle: string;
  challengeDescription: string;
  requirements: RequirementItem[];
  brandName: string;
  brandLogoUrl: string;
  creatorId: string;
}

interface PhotoUploadReviewOutput {
  outcome: BonsaiOutcome;
  rejectionReasonId: string;
  unmetRequirements?: string[];
}

interface PhotoUploadReviewToolProps {
  queue: BonsaiQueue;
  onComplete: (outcome: PhotoUploadReviewOutput) => void;
  readOnly: boolean;
  taskId: number;
  input: PhotoUploadReviewInput;
}

const REQUIREMENTS_NOT_MET_REJECTION_REASON = 'REQUIREMENTS_NOT_MET';

export function PhotoReviewTool(props: PhotoUploadReviewToolProps) {
  const [selectedOutcome, setSelectedOutcome] =
    useState<BonsaiOutcome>(undefined);
  const [rejectionReason, setRejectionReason] = useState<string>(undefined);
  const [requirementsDialogOpen, setRequirementsDialogOpen] = useState(false);

  return (
    <>
      <div className="flex flex-row gap-4">
        <div className="flex-1">
          <BonsaiPanel>
            <div className="flex-row justify-center space-y-4 p-4">
              <div className="flex items-center space-x-2">
                <Strong>Brand: {props.input.brandName}</Strong>
                <img
                  src={props.input.brandLogoUrl}
                  className="w-8 rounded-full"
                />
              </div>
              <div>
                <Strong>Challenge: </Strong> {props.input.challengeId}
              </div>
              <div className="flex-row justify-center space-y-4">
                <Text>
                  <Strong>Title</Strong> {props.input.challengeTitle}
                </Text>
                <Text>
                  <Strong>Description</Strong>{' '}
                  {props.input.challengeDescription}
                </Text>
                <Text></Text>
                <div>
                  <Text>
                    <Strong>Requirements: </Strong>
                  </Text>
                  <ul>
                    {props.input.requirements.map((requirement) => (
                      <li key={requirement.id}>
                        <Text>• {requirement.name}</Text>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </BonsaiPanel>
        </div>
        <div className="flex-1">
          <BonsaiPanel>
            <div className={'flex justify-between'}>
              <BonsaiLink
                type={BonsaiContextLinkType.CREATOR}
                url={`/creators/${props.input.creatorId}/messages`}
              />
            </div>
            <div>
              {props.input.photoUrls.map((photoUrl) => (
                <div
                  onClick={() => {
                    // Open Image in new tab
                    window.open(photoUrl);
                  }}
                  className="flex justify-center space-x-4"
                >
                  <img src={photoUrl} />
                </div>
              ))}
            </div>
          </BonsaiPanel>
        </div>
      </div>

      {!props.readOnly && (
        <BonsaiActionPanel
          submitDisabled={
            selectedOutcome === undefined ||
            (selectedOutcome === BonsaiOutcome.REJECTED && !rejectionReason)
          }
          onSubmit={() => {
            // If the rejection reason is REQUIREMENTS_NOT_MET, we show the requirements dialog instead, and the onComplete will happen inside there
            if (
              selectedOutcome === BonsaiOutcome.REJECTED &&
              rejectionReason === REQUIREMENTS_NOT_MET_REJECTION_REASON
            ) {
              setRequirementsDialogOpen(true);
              return;
            }
            props.onComplete({
              outcome: selectedOutcome,
              rejectionReasonId: rejectionReason,
            });

            setRejectionReason(null);
            setSelectedOutcome(undefined);
          }}
          taskDescription={props.queue.taskDescription}
          taskId={props.taskId}
        >
          <OutcomeControls
            selected={selectedOutcome}
            onSelected={(outcome) => {
              setSelectedOutcome(outcome);
              if (outcome === BonsaiOutcome.ACCEPTED) {
                setRejectionReason(null);
              }
            }}
            rejectionReason={rejectionReason}
            onRejectionReason={setRejectionReason}
            rejectionReasons={props.input.rejectionReasons}
          />
          <RequirementsDialog
            requirements={props.input.requirements}
            isOpen={requirementsDialogOpen}
            onClose={() => setRequirementsDialogOpen(false)}
            onComplete={(outcome: PhotoUploadReviewOutput) => {
              props.onComplete(outcome);

              // Reset the state of this PostScoringTool after the requirements dialog submits
              setRejectionReason(null);
              setSelectedOutcome(undefined);
              setRequirementsDialogOpen(false);
            }}
          />
        </BonsaiActionPanel>
      )}
    </>
  );
}

interface OutcomeControlsProps {
  selected?: BonsaiOutcome;
  onSelected: (outcome: BonsaiOutcome) => void;
  rejectionReason?: string;
  onRejectionReason: (reason: string) => void;
  rejectionReasons: RejectionReason[];
}

function OutcomeControls(props: OutcomeControlsProps) {
  return (
    <>
      <span className="isolate mr-1 inline-flex rounded-md shadow-sm">
        <button
          type="button"
          className={`relative inline-flex items-center rounded-l-lg px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
            props.selected === BonsaiOutcome.ACCEPTED
              ? 'bg-green-500 text-white'
              : 'bg-white hover:bg-gray-50'
          }`}
          onClick={() => props.onSelected(BonsaiOutcome.ACCEPTED)}
        >
          Accept
        </button>
        <button
          type="button"
          className={`relative -ml-px inline-flex items-center rounded-r-lg px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
            props.selected === BonsaiOutcome.REJECTED
              ? 'bg-red-600 text-white'
              : 'bg-white hover:bg-gray-50'
          }`}
          onClick={() => props.onSelected(BonsaiOutcome.REJECTED)}
        >
          Reject
        </button>
      </span>
      {props.selected === BonsaiOutcome.REJECTED && (
        <Listbox
          name="rejectionReason"
          placeholder="Set Rejection Reason"
          value={props.rejectionReason}
          onChange={props.onRejectionReason}
          anchorTo="top start"
        >
          {props.rejectionReasons?.map((reason) => (
            <ListboxOption
              key={reason.id}
              value={reason.id}
            >
              <ListboxLabel>{reason.name}</ListboxLabel>
            </ListboxOption>
          ))}
        </Listbox>
      )}
    </>
  );
}

interface RequirementsDialogProps {
  requirements: RequirementItem[];
  isOpen: boolean;
  onClose: () => void;
  onComplete: (outcome: PhotoUploadReviewOutput) => void;
}

function RequirementsDialog(props: RequirementsDialogProps) {
  const [checked, setChecked] = useState<Set<string>>(new Set());
  const translateCheckedToRequirements = (checked: Set<string>) => {
    return Array.from(checked).map((id) => {
      return props.requirements.find((requirement) => requirement.id === id)
        .name;
    });
  };
  return (
    <Dialog
      onClose={props.onClose}
      open={props.isOpen}
    >
      <DialogTitle>
        Which requirements were not met? (Select all that apply):
      </DialogTitle>
      <DialogDescription></DialogDescription>
      <DialogBody>
        {props.requirements.map((requirement) => (
          <CheckboxField key={requirement.id}>
            <Checkbox
              name="requirements"
              color="green"
              checked={checked.has(requirement.id)}
              onChange={() => {
                let newChecked = new Set(checked);
                if (newChecked.has(requirement.id)) {
                  newChecked.delete(requirement.id);
                } else {
                  newChecked.add(requirement.id);
                }
                setChecked(newChecked);
              }}
            />
            <Label>{requirement.name}</Label>
          </CheckboxField>
        ))}
      </DialogBody>
      <DialogActions>
        <Button
          plain
          onClick={() => {
            props.onClose();
            setChecked(new Set());
          }}
        >
          Cancel
        </Button>
        <Button
          color="green"
          onClick={() => {
            props.onComplete({
              outcome: BonsaiOutcome.REJECTED,
              rejectionReasonId: REQUIREMENTS_NOT_MET_REJECTION_REASON,
              unmetRequirements: translateCheckedToRequirements(checked),
            });
            setChecked(new Set());
          }}
          disabled={checked.size === 0}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}
