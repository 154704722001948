import React, { useEffect, useState } from "react";
import { BonsaiQueue } from "../BonsaiModels";
import { BonsaiActionPanel } from "../BonsaiContainer";
import { Fieldset } from "../../catalyst/fieldset";
import { Strong, Text, TextLink } from "../../catalyst/text";
import { Tooltip } from "flowbite-react";
import {
  BonsaiPanel,
  BonsaiVideoPanel,
  BonsaiOutcome,
  BonsaiContextLinkType,
  bonsaiContextLinkTypeForExternalUrl,
  timeDifferenceForDate,
} from "@kalecard/common";
import { Listbox, ListboxLabel, ListboxOption } from "../../catalyst/listbox";

interface RubricItem {
  name: string;
  options: string[];
  description: string;
}

interface RequirementItem {
  id: string;
  name: string;
}

interface RejectionReason {
  id: string;
  name: string;
}

interface PostScoringV2Input {
  creatorId: number;
  postId: number;
  postCaption: string;
  postExternalUrl: string;
  postUrl: string;
  postedAt: number;
  postedBy: string;
  brandName: string;
  brandLogoUrl: string;

  challengeId: number;
  challengeDescription: string;
  challengeExampleUrl?: string;
  challengeDifficulty: number;

  rubric: RubricItem[];
  requirements: RequirementItem[];
  rejectionReasons: RejectionReason[];
}

interface RubricResponse {
  name: string;
  selectedOption: string;
}

interface PostScoringV2Output {
  outcome: BonsaiOutcome;
  rejectionReasonId?: string;
  responses?: RubricResponse[];
}

interface PostScoringV2ToolProps {
  queue: BonsaiQueue;
  onComplete: (outcome: PostScoringV2Output) => void;
  readOnly: boolean;
  taskId: number;
  input: PostScoringV2Input;
}

export function PostScoringV2Tool(props: PostScoringV2ToolProps) {
  const rubric = props.input.rubric;
  const [responses, setResponses] = useState<RubricResponse[]>(
    rubric.map((item) => ({ name: item.name, selectedOption: null }))
  );
  const [acceptDisabled, setAcceptDisabled] = useState(true);

  const [selectedOutcome, setSelectedOutcome] =
    useState<BonsaiOutcome>(undefined);
  const [rejectionReason, setRejectionReason] = useState<string>(undefined);

  const links = [
    {
      type: bonsaiContextLinkTypeForExternalUrl(props.input.postExternalUrl),
      url: props.input.postExternalUrl,
    },
    {
      type: BonsaiContextLinkType.CREATOR,
      url: `/creators/${props.input.creatorId}/messages`,
    },
  ];

  const leafString = (count: number) => {
    return "🥬 ".repeat(count);
  };

  const onOptionSelected = (newResponse: RubricResponse) => {
    const updatedResponses = responses.map((response) =>
      response.name === newResponse.name ? newResponse : response
    );

    setResponses(updatedResponses);
  };

  useEffect(() => {
    const missingResponse = responses.find(
      (response) => !response.selectedOption
    );
    setAcceptDisabled(!!missingResponse);
  }, [responses]);

  return (
    <>
      <div className="flex flex-row gap-4">
        <div className={"flex-1"}>
          <BonsaiPanel>
            <div className="flex h-full flex-col justify-between p-4">
              <div className="flex-row justify-center space-y-4">
                <div className="text-md text-center font-semibold text-slate-700">
                  Scoring Information
                </div>
                <div className="flex items-center space-x-2">
                  <img
                    src={props.input.brandLogoUrl}
                    className="w-8 rounded-full"
                  />
                  <Strong>{props.input.brandName}</Strong>
                </div>
                <Text>
                  <Strong>Challenge ({props.input.challengeId}):</Strong>{" "}
                  {props.input.challengeDescription}
                </Text>
                <Text>
                  <Strong>Difficulty: </Strong>{" "}
                  {leafString(props.input.challengeDifficulty)}
                </Text>
                <div>
                  <Text>
                    <Strong>Requirements: </Strong>
                  </Text>
                  <ul>
                    {props.input.requirements.map((requirement) => (
                      <li key={requirement.id}>
                        <Text>• {requirement.name}</Text>
                      </li>
                    ))}
                  </ul>
                </div>
                {props.input.challengeExampleUrl && (
                  <Text>
                    <Strong>
                      <TextLink
                        href={props.input.challengeExampleUrl}
                        target="_blank"
                      >
                        Example Post
                      </TextLink>
                    </Strong>
                  </Text>
                )}
                <div />
                <div className={"h-0.5"} />
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm font-semibold text-gray-500">
                      Post Details
                    </span>
                  </div>
                </div>
                <Text>
                  <Strong>Caption:</Strong> {props.input.postCaption}
                </Text>
                <Text>
                  Posted {timeDifferenceForDate(props.input.postedAt)} by{" "}
                  {props.input.postedBy}
                </Text>
              </div>
            </div>
          </BonsaiPanel>
        </div>
        <div className={"flex-1"}>
          <BonsaiVideoPanel
            source={props.input.postUrl}
            links={links}
            autoPlay={false}
          />
        </div>
        <div className={"flex-1"}>
          <BonsaiPanel>
            <div className="h-full flex-row justify-center p-4">
              <div className="flex h-full flex-col space-y-4">
                <div className="text-md text-center font-semibold text-slate-700">
                  Rubric
                </div>
                <ScoringRubric
                  rubric={rubric}
                  onOptionSelected={onOptionSelected}
                  disabled={
                    selectedOutcome === BonsaiOutcome.REJECTED || props.readOnly
                  }
                  responses={responses}
                />
              </div>
            </div>
          </BonsaiPanel>
        </div>
      </div>

      {!props.readOnly && (
        <BonsaiActionPanel
          submitDisabled={
            selectedOutcome === undefined ||
            (selectedOutcome === BonsaiOutcome.REJECTED && !rejectionReason) ||
            (selectedOutcome === BonsaiOutcome.ACCEPTED && acceptDisabled)
          }
          onSubmit={() => {
            props.onComplete({
              outcome: selectedOutcome,
              rejectionReasonId: rejectionReason,
              responses:
                selectedOutcome === BonsaiOutcome.ACCEPTED ? responses : null,
            });

            setRejectionReason(null);
            setSelectedOutcome(undefined);
            setResponses(
              responses.map((response) => ({
                name: response.name,
                selectedOption: null,
              }))
            );
          }}
          taskDescription={props.queue.taskDescription}
          taskId={props.taskId}
        >
          <OutcomeControls
            selected={selectedOutcome}
            onSelected={(outcome) => {
              setSelectedOutcome(outcome);
              if (outcome === BonsaiOutcome.ACCEPTED) {
                setRejectionReason(null);
              }
            }}
            rejectionReason={rejectionReason}
            onRejectionReason={setRejectionReason}
            rejectionReasons={props.input.rejectionReasons}
          />
        </BonsaiActionPanel>
      )}
    </>
  );
}

interface ScoringRubricProps {
  rubric: RubricItem[];
  onOptionSelected: (response: RubricResponse) => void;
  disabled: boolean;
  responses: RubricResponse[];
}

function ScoringRubric(props: ScoringRubricProps) {
  return (
    <div className="flex h-full flex-col justify-between">
      <Fieldset className="space-y-6">
        {props.rubric.map((item) => (
          <div className="flex flex-col">
            <Tooltip
              content={<div className="text-wrap">{item.description}</div>}
            >
              <Strong className="text-sm">{item.name}</Strong>
            </Tooltip>
            <div className="flex">
              {item.options.map((option, index) => {
                const firstIndex = index === 0;
                const lastIndex = index === item.options.length - 1;
                const existingResponse = props.responses.find(
                  (response) => response.name === item.name
                );
                const isSelected = existingResponse?.selectedOption === option;

                return (
                  <button
                    type="button"
                    className={`relative inline-flex items-center px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
                      isSelected
                        ? "bg-kale-green-500 text-white"
                        : "bg-white hover:bg-gray-50"
                    } ${firstIndex ? "rounded-l-lg" : ""} ${
                      lastIndex ? "rounded-r-lg" : ""
                    }`}
                    onClick={() =>
                      props.onOptionSelected({
                        name: item.name,
                        selectedOption: option,
                      })
                    }
                  >
                    {option}
                  </button>
                );
              })}
            </div>
          </div>
        ))}
      </Fieldset>
    </div>
  );
}

interface OutcomeControlsProps {
  selected?: BonsaiOutcome;
  onSelected: (outcome: BonsaiOutcome) => void;
  rejectionReason?: string;
  onRejectionReason: (reason: string) => void;
  rejectionReasons: RejectionReason[];
}

function OutcomeControls(props: OutcomeControlsProps) {
  return (
    <>
      <span className="isolate mr-1 inline-flex rounded-md shadow-sm">
        <button
          type="button"
          className={`relative inline-flex items-center rounded-l-lg px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
            props.selected === BonsaiOutcome.ACCEPTED
              ? "bg-green-500 text-white"
              : "bg-white hover:bg-gray-50"
          }`}
          onClick={() => props.onSelected(BonsaiOutcome.ACCEPTED)}
        >
          Accept
        </button>
        <button
          type="button"
          className={`relative -ml-px inline-flex items-center rounded-r-lg px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
            props.selected === BonsaiOutcome.REJECTED
              ? "bg-red-600 text-white"
              : "bg-white hover:bg-gray-50"
          }`}
          onClick={() => props.onSelected(BonsaiOutcome.REJECTED)}
        >
          Reject
        </button>
      </span>
      {props.selected === BonsaiOutcome.REJECTED && (
        <Listbox
          name="rejectionReason"
          placeholder="Set Rejection Reason"
          value={props.rejectionReason}
          onChange={props.onRejectionReason}
          anchorTo="top start"
        >
          {props.rejectionReasons?.map((reason) => (
            <ListboxOption key={reason.id} value={reason.id}>
              <ListboxLabel>{reason.name}</ListboxLabel>
            </ListboxOption>
          ))}
        </Listbox>
      )}
    </>
  );
}
