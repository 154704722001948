import { useMutation } from "@apollo/client";
import { Deal, Challenge, XMark, CheckMark } from "@kalecard/common";
import { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
import { UPDATE_CHALLENGE_ID_FOR_DEAL } from "../../graphql/mutations";
import { TemporalDealWorkflowLink } from "../Links";
import RefundDealComponent from "../RefundDealComponent";

export default function DealHeaderItem({ deal }: { deal: Deal }) {
  const { pathname } = useLocation();
  const [challenge, setChallenge] = useState<Challenge>(deal.challenge);
  const [challenges, setChallenges] = useState<Challenge[]>([]);
  const [updateChallengeIdForDeal] = useMutation(UPDATE_CHALLENGE_ID_FOR_DEAL);

  const [dealState, setDealState] = useState(deal.state);

  useEffect(() => {
    const newChallenges: Challenge[] = [];
    deal.challenge?.brand?.activeChallenges?.forEach((challenge) => {
      newChallenges.push(challenge);
    });
    if (deal.challenge) {
      const isChallengeInList = newChallenges.find((challenge) => {
        return challenge.id === deal.challenge.id;
      });
      if (!isChallengeInList) {
        newChallenges.push(deal.challenge);
      }
    }
    setChallenges(newChallenges);
  }, []);

  return (
    <div key={deal.id}>
      <div key={deal.id} className="my-2 w-full rounded-lg border p-2">
        <div className="mb-2 text-sm">
          DEAL ID <TemporalDealWorkflowLink dealId={deal.id} /> - Deal for{" "}
          <a
            className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
            href={`https://www.instagram.com/${deal.merchantInstagramHandle}`}
            target="_blank"
            rel="noreferrer"
          >
            @{deal.merchantInstagramHandle}
          </a>
          <div>
            <Link
              className="text-sm font-semibold text-indigo-600 hover:text-indigo-900"
              to={`${
                deal.creator
                  ? "/creators/" + deal.creator.id
                  : pathname.split("/").splice(0, 3).join("/")
              }/post?dealId=${deal.id}`}
              rel="noreferrer"
            >
              View media
            </Link>
          </div>
        </div>

        <div className="flex space-x-4">
          {challenge && (
            <div className="mb-2 flex w-1/2 flex-col rounded-md border p-4">
              <p className="">
                Challenge ({challenge?.id}): {challenge?.description}
              </p>
              <p className="text-sm">
                Difficulty:{" "}
                {Array.from({ length: challenge.difficulty ?? 0 }, (_, i) => (
                  <span className="text-base" key={i}>
                    {" "}
                    🥬{" "}
                  </span>
                ))}
              </p>
              <div className="flex space-x-2 text-sm">
                <p className="">{challenge?.tagRequirements}</p>
                {challenge?.exampleUrl && (
                  <a
                    className="whitespace-nowrap font-semibold text-indigo-600 hover:text-indigo-900"
                    href={challenge?.exampleUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Example url
                  </a>
                )}
              </div>
              {challenge.isCommercialSoundsOnly === true && (
                <div className="my-2 inline-flex w-fit items-center rounded-full bg-kale-mint-500 px-4 py-1 text-sm font-medium text-kale-green-500">
                  <p className="font-bold">Commercial Sounds Only</p>
                </div>
              )}
              {challenge.postRequirements?.length > 0 && (
                <div className="space-y-2 pt-2">
                  <p className="text-sm font-medium">Post Requirements:</p>
                  {challenge.postRequirements?.map((postRequirement) => {
                    return (
                      <div
                        key={postRequirement.id}
                        className="flex items-center space-x-2"
                      >
                        <span>
                          {postRequirement.iconFilename === "xmark.svg" ? (
                            <XMark size="h-4 w-4" />
                          ) : (
                            <CheckMark size="h-4 w-4" />
                          )}
                        </span>
                        <p className="text-xs text-gray-800">
                          {postRequirement.requirement}
                        </p>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}
          <div className="w-1/2">
            <label
              htmlFor="challenge"
              className="block text-sm font-medium text-gray-700"
            >
              Challenge:
            </label>
            {challenges?.length > 0 && (
              <select
                id="challenge"
                name="challenge"
                onChange={async (event) => {
                  const selectedChallenge = challenges?.find((edge) => {
                    return edge.id === event.target.value;
                  });
                  const result = await updateChallengeIdForDeal({
                    variables: {
                      dealId: deal.id,
                      challengeId: selectedChallenge?.id,
                    },
                  });
                  if (result.data?.updateChallengeIdForDeal) {
                    setChallenge(selectedChallenge);
                  } else {
                    console.error(
                      "Error updating challenge to challenge id: " +
                        selectedChallenge?.id
                    );
                  }
                }}
                defaultValue={challenge?.id}
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              >
                {challenges?.map((edge) => {
                  return (
                    <option key={edge.id} value={edge.id}>
                      {edge.id} - {edge.description}
                    </option>
                  );
                })}
                ;
              </select>
            )}
          </div>
        </div>
        {dealState === "PAID" && (
          <div className="mt-2 flex justify-end">
            <RefundDealComponent
              deal={deal}
              setDealState={(state) => {
                console.log("setting state", state);
                setDealState(state);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
