import { ChallengeTemplate } from '@kalecard/common';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '../catalyst/table';
import ChallengeTemplateRow from './ChallengeTemplateRow';

export default function ChallengeTemplateTable({
  templateType,
  challengeTemplates,
}: {
  templateType: string;
  challengeTemplates: ChallengeTemplate[];
}) {
  return challengeTemplates.length > 0 ? (
    <Table
      striped
      className="rounded-md border p-4"
    >
      <TableHead>
        <TableRow className="text-black">
          <TableHeader>Title</TableHeader>
          <TableHeader>Status</TableHeader>
          <TableHeader>Activation Date</TableHeader>
          <TableHeader>Links</TableHeader>
          <TableHeader>Active Challenges</TableHeader>
          <TableHeader>Tags</TableHeader>
          <TableHeader></TableHeader>
        </TableRow>
      </TableHead>

      <TableBody>
        {challengeTemplates &&
          challengeTemplates.map((challengeTemplate) => (
            <ChallengeTemplateRow
              key={challengeTemplate.id}
              challengeTemplate={challengeTemplate}
            />
          ))}
      </TableBody>
    </Table>
  ) : (
    <div className="rounded-md border border-dashed border-gray-300 pb-10 pt-10">
      <div className="text-center text-sm text-gray-400">
        No {templateType.toLowerCase()}s
      </div>
    </div>
  );
}
