import { Score } from "@kalecard/common";

export enum ScoreActionType {
  ADD,
  UPDATE,
  ADD_POINTS,
  REMOVE_POINTS,
}

// An interface for our actions
export interface ScoreAction {
  type: ScoreActionType;
  score: Score;
}

// An interface for our state
export interface ScoreState {
  scores: Score[];
  points: number;
  setBy?: string;
}

export function ScoreReducer(
  state: ScoreState,
  action: ScoreAction
): ScoreState {
  switch (action.type) {
    case ScoreActionType.ADD:
      return {
        ...state,
        scores: [...state.scores, action.score],
      };
    case ScoreActionType.UPDATE:
      return {
        ...state,
        scores: state.scores.map((score) => {
          if (score.id === action.score.id) {
            return action.score;
          } else {
            return score;
          }
        }),
      };
    case ScoreActionType.ADD_POINTS:
      return {
        ...state,
        points: state.points + action.score.points,
      };
    case ScoreActionType.REMOVE_POINTS:
      return {
        ...state,
        points: state.points - action.score.points,
      };
    default:
      throw new Error();
  }
}
