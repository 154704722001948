import { useMutation } from "@apollo/client";
import { Spinner } from "@kalecard/common";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { NEW_POST } from "../../graphql/mutations";
import { POSTS } from "../../graphql/queries";

type NewPostFormInputs = {
  userId: string;
  caption?: string;
  productType: string;
  mediaType: string;
  externalPermalink: string;
  username: string;
  externalPlatform: string;
};

export default function NewPostForm({ userId }: { userId: string }) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [newPost] = useMutation(NEW_POST, {
    refetchQueries: [POSTS],
  });

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<NewPostFormInputs>({
    defaultValues: {
      userId,
      externalPlatform: "INSTAGRAM",
    },
  });

  const onSubmit: SubmitHandler<NewPostFormInputs> = async (data) => {
    setIsLoading(true);
    try {
      const result = await newPost({ variables: data });
      console.log(result);
      if (result.data.newPost) {
        setError(null);
        reset();
      } else {
        // Set an error
        console.error("Error creating post");
        setError(
          "Something went wrong, try refreshing this page or try again later."
        );
      }
    } catch (err) {
      console.error(err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
          <div className="sm:col-span-3">
            <label
              htmlFor="userId"
              className="block text-sm font-medium text-gray-700"
            >
              User ID
            </label>
            <div className="mt-1">
              <input
                disabled={userId !== undefined}
                type="text"
                name="userId"
                {...register("userId", { required: true })}
                id="userId"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="externalPlatform"
              className="block text-sm font-medium text-gray-700"
            >
              ExternalPlatform
            </label>
            <div className="mt-1">
              <select
                id="externalPlatform"
                name="externalPlatform"
                {...register("externalPlatform", { required: true })}
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                defaultValue="INSTAGRAM"
              >
                <option>INSTAGRAM</option>
                <option>TIKTOK</option>
              </select>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="caption"
              className="block text-sm font-medium text-gray-700"
            >
              Caption
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="caption"
                {...register("caption", { required: false })}
                id="caption"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="productType"
              className="block text-sm font-medium text-gray-700"
            >
              Product Type
            </label>
            <div className="mt-1">
              <select
                name="productType"
                {...register("productType", { required: true })}
                id="productType"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              >
                <option>STORY</option>
                <option>CLIPS</option>
                <option>FEED</option>
                <option>IGTV</option>
              </select>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="mediaType"
              className="block text-sm font-medium text-gray-700"
            >
              Media Type
            </label>
            <div className="mt-1">
              <select
                name="mediaType"
                {...register("mediaType", { required: true })}
                id="mediaType"
                className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              >
                <option>IMAGE</option>
                <option>VIDEO</option>
              </select>
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="externalPermalink"
              className="block text-sm font-medium text-gray-700"
            >
              External Permalink
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="externalPermalink"
                {...register("externalPermalink", { required: true })}
                id="externalPermalink"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
              />
            </div>
          </div>

          <div className="sm:col-span-3">
            <label
              htmlFor="username"
              className="block text-sm font-medium text-gray-700"
            >
              Username
            </label>
            <div className="mt-1">
              <input
                type="text"
                name="username"
                {...register("username", { required: true })}
                id="username"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-5">
          {isLoading && (
            <div className="flex flex-wrap content-center justify-center">
              <Spinner size="h-6 w-6" />
            </div>
          )}
          <div className="flex justify-end">
            <button
              type="submit"
              disabled={isLoading}
              className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-400"
            >
              Create New Post
            </button>
          </div>
        </div>
        <div className="mt-2 flex justify-end">
          <p className="font-bold text-red-500">{error}</p>
        </div>
      </form>
    </>
  );
}
