import { useLazyQuery } from "@apollo/client";
import { POST_SCORES, PostScoresData } from "../graphql/queries";
import ScoreComponent, { ScoreUpdateView } from "../components/scores/ScoreComponent";
import { useEffect, useState } from "react";
import ScoreProvider from "../providers/ScoreProvider";
import { Spinner } from "flowbite-react";
import { Score } from "@kalecard/common";

export default function ScoresPage() {
  const [scores, setScores] = useState<Score[]>([]);
  const [fetchScores, { loading }] = useLazyQuery<PostScoresData>(POST_SCORES, {
    variables: {
      fetchAll: true,
    },
    onCompleted: (data) => {
      setScores(data.postScores);
    },
  });

  useEffect(() => {
    fetchScores();
  }, []);

  return (
    <ScoreProvider scores={scores}>
      <div className="mt-4 px-4 sm:px-6 lg:px-8">
        <div className="sm:flex sm:items-center">
          <div className="sm:flex-auto">
            <h1 className="text-xl font-semibold text-gray-900">Scores</h1>
            <p className="mt-2 text-sm text-gray-700">
              A list of the scoring rubric used to score posts by creators.
            </p>
          </div>
        </div>
      </div>
      <div className="flex px-4 sm:px-6 lg:px-8">
        <table className="divide-y min-w-full divide-gray-300">
          <thead>
            <tr>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                Description
              </th>
              <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3">
                Inverse Description
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Help text
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Points
              </th>
              <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                Is active?
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {loading && <tr><td className="flex mt-2 w-full justify-center row-span-full"><Spinner /></td></tr>}
            {scores?.map((score, scoreIdx) => (
              <ScoreComponent key={score.id} score={score} scoreIdx={scoreIdx} />
            ))}
            <tr className={scores.length % 2 === 0 ? undefined : 'bg-gray-50'}>
              <ScoreUpdateView isNew={true} />
            </tr>
          </tbody>
        </table>
      </div>
    </ScoreProvider>
  )
}