import React, { useState } from "react";
import { BonsaiQueue } from "../BonsaiModels";
import { BonsaiActionPanel } from "../BonsaiContainer";
import { Strong, Text, TextLink } from "../../catalyst/text";
import {
  BonsaiOutcome,
  BonsaiPanel,
  BonsaiVideoPanel,
  BonsaiContextLinkType,
  bonsaiContextLinkTypeForExternalUrl,
  timeDifferenceForDate,
} from "@kalecard/common";
import { Listbox, ListboxLabel, ListboxOption } from "../../catalyst/listbox";

interface RequirementItem {
  id: string;
  name: string;
}

interface RejectionReason {
  id: string;
  name: string;
}

interface PostEligibilityInput {
  creatorId: number;
  postId: number;
  postCaption: string;
  postExternalUrl: string;
  postUrl: string;
  postedAt: number;
  postedBy: string;

  challengeId: number;
  challengeDescription: string;
  challengeExampleUrl?: string;
  challengeDifficulty: number;

  requirements: RequirementItem[];
  rejectionReasons: RejectionReason[];
}

interface PostEligibilityOutput {
  outcome: BonsaiOutcome;
  rejectionReasonId?: string;
}

interface PostEligibilityToolProps {
  queue: BonsaiQueue;
  onComplete: (outcome: PostEligibilityOutput) => void;
  readOnly: boolean;
  taskId: number;
  input: PostEligibilityInput;
}

export function PostEligibilityTool(props: PostEligibilityToolProps) {
  const [selectedOutcome, setSelectedOutcome] =
    useState<BonsaiOutcome>(undefined);
  const [rejectionReason, setRejectionReason] = useState<string>(undefined);

  const links = [
    {
      type: bonsaiContextLinkTypeForExternalUrl(props.input.postExternalUrl),
      url: props.input.postExternalUrl,
    },
    {
      type: BonsaiContextLinkType.CREATOR,
      url: `/creators/${props.input.creatorId}/messages`,
    },
  ];

  const leafString = (count: number) => {
    return "🥬 ".repeat(count);
  };

  return (
    <>
      <div className="flex flex-row gap-4">
        <div className={"flex-1"}>
          <BonsaiPanel>
            <div className="flex h-full flex-col justify-between p-4">
              <div className="flex-row justify-center space-y-4">
                <div className="text-md text-center font-semibold text-slate-700">
                  Eligibility Information
                </div>
                <Text>
                  <Strong>Challenge ({props.input.challengeId}):</Strong>{" "}
                  {props.input.challengeDescription}
                </Text>
                <Text>
                  <Strong>Difficulty: </Strong>{" "}
                  {leafString(props.input.challengeDifficulty)}
                </Text>
                {props.input.requirements.length > 0 && (
                  <div>
                    <Text>
                      <Strong>Requirements: </Strong>
                    </Text>
                    <ul>
                      {props.input.requirements.map((requirement) => (
                        <li key={requirement.id}>
                          <Text>• {requirement.name}</Text>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                {props.input.challengeExampleUrl && (
                  <Text>
                    <Strong>
                      <TextLink
                        href={props.input.challengeExampleUrl}
                        target="_blank"
                      >
                        Example Post
                      </TextLink>
                    </Strong>
                  </Text>
                )}
                <div />
                <div className={"h-0.5"} />
                <div className="relative">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm font-semibold text-gray-500">
                      Post Details
                    </span>
                  </div>
                </div>
                <Text>
                  <Strong>Caption:</Strong> {props.input.postCaption}
                </Text>
                <Text>
                  Posted {timeDifferenceForDate(props.input.postedAt)} by{" "}
                  {props.input.postedBy}
                </Text>
              </div>
            </div>
          </BonsaiPanel>
        </div>
        <div className={"flex-1"}>
          <BonsaiVideoPanel
            source={props.input.postUrl}
            links={links}
            autoPlay={false}
          />
        </div>
      </div>

      {!props.readOnly && (
        <BonsaiActionPanel
          submitDisabled={
            selectedOutcome === undefined ||
            (selectedOutcome === BonsaiOutcome.REJECTED && !rejectionReason)
          }
          onSubmit={() => {
            props.onComplete({
              outcome: selectedOutcome,
              rejectionReasonId: rejectionReason,
            });

            setRejectionReason(null);
            setSelectedOutcome(undefined);
          }}
          taskDescription={props.queue.taskDescription}
          taskId={props.taskId}
        >
          <OutcomeControls
            selected={selectedOutcome}
            onSelected={(outcome) => {
              setSelectedOutcome(outcome);
              if (outcome === BonsaiOutcome.ACCEPTED) {
                setRejectionReason(null);
              }
            }}
            rejectionReason={rejectionReason}
            onRejectionReason={setRejectionReason}
            rejectionReasons={props.input.rejectionReasons}
          />
        </BonsaiActionPanel>
      )}
    </>
  );
}

interface OutcomeControlsProps {
  selected?: BonsaiOutcome;
  onSelected: (outcome: BonsaiOutcome) => void;
  rejectionReason?: string;
  onRejectionReason: (reason: string) => void;
  rejectionReasons: RejectionReason[];
}

function OutcomeControls(props: OutcomeControlsProps) {
  return (
    <>
      <span className="isolate mr-1 inline-flex rounded-md shadow-sm">
        <button
          type="button"
          className={`relative inline-flex items-center rounded-l-lg px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
            props.selected === BonsaiOutcome.ACCEPTED
              ? "bg-green-500 text-white"
              : "bg-white hover:bg-gray-50"
          }`}
          onClick={() => props.onSelected(BonsaiOutcome.ACCEPTED)}
        >
          Accept
        </button>
        <button
          type="button"
          className={`relative -ml-px inline-flex items-center rounded-r-lg px-3 py-2 text-sm text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
            props.selected === BonsaiOutcome.REJECTED
              ? "bg-red-600 text-white"
              : "bg-white hover:bg-gray-50"
          }`}
          onClick={() => props.onSelected(BonsaiOutcome.REJECTED)}
        >
          Reject
        </button>
      </span>
      {props.selected === BonsaiOutcome.REJECTED && (
        <Listbox
          name="rejectionReason"
          placeholder="Set Rejection Reason"
          value={props.rejectionReason}
          onChange={props.onRejectionReason}
          anchorTo="top start"
        >
          {props.rejectionReasons?.map((reason) => (
            <ListboxOption key={reason.id} value={reason.id}>
              <ListboxLabel>{reason.name}</ListboxLabel>
            </ListboxOption>
          ))}
        </Listbox>
      )}
    </>
  );
}
