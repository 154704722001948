import { useState } from "react";
import { BonsaiQueue } from "../BonsaiModels";
import { BonsaiActionPanel } from "../BonsaiContainer";
import { Strong, Text } from "../../catalyst/text";
import {
  BonsaiOutcome,
  BonsaiPanel,
  OutcomeControls,
  BonsaiVideoPanel,
  BonsaiContextLinkType,
  bonsaiContextLinkTypeForExternalUrl,
  timeDifferenceForDate,
} from "@kalecard/common";
import { Link } from "../../catalyst/link";

interface RequiredBrandProduct {
  id: number;
  url: string;
}

interface BrandProductCheckInput {
  creatorId: number;
  postId: number;
  dealId: number;
  brandId: number;
  challengeId: number;
  productId: number;
  postExternalUrl: string;
  postUrl: string;
  postedAt: number;
  postedBy: string;
  requiredBrandProducts: RequiredBrandProduct[];
}

interface BrandProductCheckOutput {
  outcome: BonsaiOutcome;
}

interface BrandProductCheckToolProps {
  queue: BonsaiQueue;
  onComplete: (outcome: BrandProductCheckOutput) => void;
  readOnly: boolean;
  taskId: number;
  input: BrandProductCheckInput;
}

export function BrandProductCheckTool(props: BrandProductCheckToolProps) {
  const [selectedOutcome, setSelectedOutcome] =
    useState<BonsaiOutcome>(undefined);

  const links = [
    {
      type: bonsaiContextLinkTypeForExternalUrl(props.input.postExternalUrl),
      url: props.input.postExternalUrl,
    },
    {
      type: BonsaiContextLinkType.CREATOR,
      url: `/creators/${props.input.creatorId}/messages`,
    },
  ];

  return (
    <>
      <div className="flex flex-row gap-4">
        <div className={"flex-1"}>
          <BonsaiPanel>
            <div className="flex h-full flex-col justify-between p-4">
              <div className="flex-row justify-center space-y-4">
                <div>
                  <Strong>
                    Brand Product
                    {props.input.requiredBrandProducts.length > 1 ? "s" : ""}:
                  </Strong>
                  <div className="space-y-2">
                    {props.input.requiredBrandProducts.map(
                      (requiredBrandProduct) => {
                        return (
                          <div key={requiredBrandProduct.id}>
                            <Link
                              target="_blank"
                              className="text-sm text-kale-green-500 underline hover:text-kale-green-400"
                              href={requiredBrandProduct.url}
                            >
                              {requiredBrandProduct.url}
                            </Link>
                          </div>
                        );
                      }
                    )}
                  </div>
                </div>
                <div>
                  <Strong>Post Details:</Strong>
                  <Text>
                    Posted {timeDifferenceForDate(props.input.postedAt)} by{" "}
                    {props.input.postedBy}
                  </Text>
                </div>
              </div>
            </div>
          </BonsaiPanel>
        </div>
        <div className={"flex-1"}>
          <BonsaiVideoPanel
            source={props.input.postUrl}
            links={links}
            autoPlay={false}
          />
        </div>
      </div>

      {!props.readOnly && (
        <BonsaiActionPanel
          submitDisabled={selectedOutcome === undefined}
          onSubmit={() => {
            props.onComplete({
              outcome: selectedOutcome,
            });
            setSelectedOutcome(undefined);
          }}
          taskDescription={props.queue.taskDescription}
          taskId={props.taskId}
        >
          <OutcomeControls
            selected={selectedOutcome}
            onSelected={(outcome) => {
              setSelectedOutcome(outcome);
            }}
          />
        </BonsaiActionPanel>
      )}
    </>
  );
}
