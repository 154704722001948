import { gql } from '../__generated__';

import {
  AdminPermission,
  BrandPaymentStructure,
  ChallengeCategory,
  ChallengeTemplate,
  ChallengeTemplateTag,
  Connection,
  Deal,
  KaleAdmin,
  Offer,
  PostRequirement,
  Score,
} from '@kalecard/common';
import { Admin } from '../components/bonsai/BonsaiModels';
import { Brand, Challenge, MessageCampaign } from '../__generated__/graphql';

export const CREATOR_LOOKUP = gql(`
  query CreatorLookup(
    $userId: ID
    $email: String
    $phoneNumber: String
    $socialUsername: String
  ) {
    creatorLookup(
      userId: $userId
      email: $email
      phoneNumber: $phoneNumber
      socialUsername: $socialUsername
    ) {
      id
      name
      socialHandle
      phoneNumber
      lastDealTimestamp
      tiktokSocialHandle
      referralCode
      creatorSettings {
        isInstagramConnected
        isPlaidConnected
        isPlaidActive
        isTiktokConnected
      }
      activeDeal {
        id
        relevance
        reward
        state
        transactionAmount
        createdAt
        merchant
        merchantName
        merchantInstagramHandle
        rewardAmount
      }
      externalUsers {
        id
        externalPlatform
        source
        externalId
        biography
        username
        businessEmail
        externalUrl
        followers
        following
        name
        isPrivate
        isVerified
        likeCount
        postCount
        pictureUrl
      }
    }
  }
`);

export const CREATOR_PROFILE_DATA = gql(`
  query CreatorProfileData($userId: ID) {
    creatorProfileData(userId: $userId) {
      creator {
        id
        name
        phoneNumber
        email
        birthdate
        balance
        referralCode
        socialHandle
        lastDealTimestamp
        tiktokSocialHandle
        creatorSettings {
          isInstagramConnected
          isPlaidConnected
          isPlaidActive
          isTiktokConnected
          experienceLevel
          requiresApproval
          isBlocked
        }
        accountState
        externalUsers {
          id
          externalPlatform
          source
          externalId
          biography
          username
          businessEmail
          externalUrl
          followers
          following
          name
          isPrivate
          isVerified
          likeCount
          postCount
          pictureUrl
          status
        }
      }
      totalEarnings
      dealsCompleted
      pendingWithdrawalRequests
      pendingWithdrawalBalance
    }
  }
`);

export const CREATOR_TRANSACTIONS = gql(`
  query CreatorTransactions($first: Int, $after: String, $userId: ID) {
    creatorTransactions(first: $first, after: $after, userId: $userId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          createdAt
          amount
          type
          details {
            ... on Deal {
              id
              merchantName
            }
            ... on BrandReferral {
              id
              createdAt
              brand {
                name
              }
              referralTimestamp
            }
            ... on TextDetails {
              text
            }
          }
        }
      }
    }
  }
`);

export const DEALS = gql(`
  query Deals($first: Int, $after: String, $userId: ID) {
    deals(first: $first, after: $after, userId: $userId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          relevance
          reward
          state
          transactionAmount
          createdAt
          merchant
          merchantName
          merchantInstagramHandle
          rewardAmount
          brandId
          mediaEngagementRate {
            instagramStory
            instagramFeed
          }
          mediaLink
        }
      }
    }
  }
`);
export const MESSAGES = gql(`
  query Messages($first: Int, $after: String, $userId: ID) {
    messages(first: $first, after: $after, userId: $userId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          text
          from
          to
          createdAt
          hasMedia
          admin {
            id
            username
          }
          messageMedia {
            id
            mediaType
            mediaUrl
            twilioMediaUrl
          }
          twilioStatus
        }
      }
    }
  }
`);
export const BRANDS = gql(`
  query Brands(
    $first: Int
    $after: String
    $isKaleBrand: Boolean
    $onlyRelevantBrands: Boolean
    $sortingType: String
  ) {
    brands(
      first: $first
      after: $after
      isKaleBrand: $isKaleBrand
      onlyRelevantBrands: $onlyRelevantBrands
      sortingType: $sortingType
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          logoUrl
          requiresHighQualityScore
          socialHandle
          tiktokSocialHandle
          hashtagRequirement
          kaleHashtagRequirements
          shouldRequireCommercialSound
          isChallengeReviewRequired
          postRequirements {
            id
            requirement
            emoji
            type
            externalPlatform
            isDefault
            referenceBrand {
              id
              name
              socialHandle
              tiktokSocialHandle
            }
          }
        }
      }
    }
  }
`);

export const BRAND_PRODUCTS = gql(`
  query BrandProducts($brandId: ID!) {
    brandProducts(brandId: $brandId) {
      id
      externalUrl
    }
  }
`);

export const SIMPLE_BRANDS = gql(`
  query SimpleBrands($first: Int, $after: String, $isKaleBrand: Boolean) {
    brands(first: $first, after: $after, isKaleBrand: $isKaleBrand) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          logoUrl
          activeChallenges {
            id
          }
        }
      }
    }
  }
`);

export const KALE_BRANDS = gql(`
  query KaleBrands(
    $first: Int
    $after: String
    $isKaleBrand: Boolean
    $autoRechargeEnabled: Boolean
    $challengesPaused: Boolean
    $sortingType: String
    $pacingWithSage: Boolean
  ) {
    brands(
      first: $first
      after: $after
      isKaleBrand: $isKaleBrand
      autoRechargeEnabled: $autoRechargeEnabled
      challengesPaused: $challengesPaused
      sortingType: $sortingType
      pacingWithSage: $pacingWithSage
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          url
          name
          logoUrl
          socialHandle
          contentBudget
          isKaleStoreEnabled
          platformFee
          contentBudgetThreshold
          tiktokSocialHandle
          isIgStoryReplyEnabled
          contentBudgetMax
          autoRechargeEnabled
          autoChargeDefaultPaymentMethod
          autoChargeDefaultPaymentMethod
          maxCreatorPayout
          challengesPaused
          paused
          recurringChargesEnabled
          requiresHighQualityScore
          isKaleOffsetEnabled
          monthlyMaxCreatorPayout
          paymentPeriodDays
          monthlyMaxCreatorChallenges
          isOffersActive
          isChallengeReviewRequired
          shouldRequireCommercialSound
          isPacingWithSage
          isSelfService
          sageDetails {
            contentBudget
            predictedOutstanding
            challengeBudgetAllocation
            effectiveBudget
            targetBudget
          }
          activeChallenges {
            id
          }
          proofOfPurchase {
            numberOfChallenges
          }
          status
          accountManager {
            id
            name
          }
        }
      }
    }
  }
`);

export interface PostScoresData {
  postScores?: Score[];
}

export const POST_SCORES = gql(`
  query PostScores($brandId: ID, $postId: ID, $fetchAll: Boolean) {
    postScores(brandId: $brandId, postId: $postId, fetchAll: $fetchAll) {
      id
      description
      inverseDescription
      isSelected
      helpText
      points
      isActive
    }
  }
`);
export const POSTS = gql(`
  query Posts($first: Int, $after: String, $userId: ID, $dealId: ID) {
    posts(first: $first, after: $after, userId: $userId, dealId: $dealId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          externalId
          externalPlatform
          caption
          mediaType
          productType
          externalPermalink
          username
          createdAt
          likeCount
          commentCount
          shareCount
          playCount
          impressions
          reach
          forwardsCount
          backwardsCount
          exitCount
          replies
          engagement
          savedCount
          followers
          mediaUrls {
            url
            mediaType
            carouselItem {
              id
              isBrandPost
            }
          }
          externalUser {
            id
            externalPlatform
            source
            externalId
            biography
            username
            businessEmail
            externalUrl
            followers
            following
            name
            isPrivate
            isVerified
            likeCount
            postCount
          }
          deal {
            id
            relevance
            reward
            state
            transactionAmount
            createdAt
            merchant
            merchantName
            merchantInstagramHandle
            rewardAmount
          }
          qualityScore
          primaryQualityScore
          secondaryQualityScore
          externalMediaUrl
          rewardCategory
          scores {
            id
            description
            isSelected
            helpText
            points
            isActive
            setBy
          }
        }
      }
    }
  }
`);

export const KALE_POSTS = gql(`
  query KalePosts(
    $first: Int
    $after: String
    $onlyKaleBrands: Boolean
    $challengeId: ID
  ) {
    kalePosts(
      first: $first
      after: $after
      onlyKaleBrands: $onlyKaleBrands
      challengeId: $challengeId
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          externalId
          externalPlatform
          caption
          mediaType
          productType
          externalPermalink
          username
          createdAt
          likeCount
          commentCount
          shareCount
          playCount
          impressions
          reach
          forwardsCount
          backwardsCount
          exitCount
          replies
          engagement
          savedCount
          followers
          deal {
            creator {
              id
            }
          }
          mediaUrls {
            url
            mediaType
            carouselItem {
              id
              isBrandPost
            }
          }
          qualityScore
        }
      }
    }
  }
`);

export interface BrandData {
  brand: Brand;
}

export const BRAND = gql(`
  query Brand($id: ID!) {
    brand(id: $id) {
      id
      url
      name
      logoUrl
      coverUrl
      socialHandle
      shopifyStoreDomainName
      hasWhitelistPermission
      contentBudget
      isKaleStoreEnabled
      platformFee
      contentBudgetThreshold
      referralCode
      isKaleBrand
      tiktokSocialHandle
      isIgStoryReplyEnabled
      contentBudgetMax
      autoRechargeEnabled
      autoChargeDefaultPaymentMethod
      enableWireTransferInvoicePayment
      maxCreatorPayout
      shopTransactionFee
      shopCreatorIncentive
      shopUrl
      recurringChargesEnabled
      challengesPaused
      admins {
        id
        name
        email
        role
        administeredBrands {
          brand {
            id
          }
          role
        }
      }
      brandCategories {
        id
        name
      }
      hashtagRequirements
      mentionRequirements {
        id
        name
        socialHandle
        tiktokSocialHandle
      }
      deniedBrands {
        id
        name
      }
      isKaleOffsetEnabled
      monthlyMaxCreatorPayout
      monthlyMaxCreatorChallenges
      paymentMethodType
      invoiceEmailAddress
      paymentPeriodDays
      requiresHighQualityScore
      isOffersActive
      kaleHashtagRequirements
      isChallengeReviewRequired
      shouldRequireCommercialSound
      salesRep {
        id
        name
      }
      accountManager {
        id
        name
      }
      instagramAutomatedMessageSettings {
        title
        subtitle
        maxFollowerCount
        minFollowerCount
        imageUrl
      }
      countryCodeAllowList
      postRequirements {
        id
        requirement
        emoji
        type
        externalPlatform
        isDefault
        referenceBrand {
          id
          name
          socialHandle
          tiktokSocialHandle
        }
      }
      activeChallenges {
        id
        description
        title
      }
      isSelfService
      status
      maxCreatorAgeYears
      minCreatorAgeYears
    }
  }
`);

export interface ChallengesData {
  adminChallenges: Connection<Challenge>;
}

export const ADMIN_CHALLENGES = gql(`
  query AdminChallenges(
    $first: Int
    $after: String
    $brandId: ID
    $state: String
    $sortingType: String
    $keywords: [String!]
    $brandIds: [ID!]
    $challengeIds: [ID!]
    $commercialSoundsOnly: Boolean
    $containingFeedback: Boolean
    $source: String
    $challengeTemplateId: ID
  ) {
    adminChallenges(
      first: $first
      after: $after
      brandId: $brandId
      state: $state
      sortingType: $sortingType
      keywords: $keywords
      brandIds: $brandIds
      challengeIds: $challengeIds
      commercialSoundsOnly: $commercialSoundsOnly
      containingFeedback: $containingFeedback
      source: $source
      challengeTemplateId: $challengeTemplateId
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          description
          mentionRequirements
          tagRequirements
          createdAt
          state
          audienceType
          exampleUrl
          difficulty
          brand {
            id
            name
            logoUrl
            isChallengeReviewRequired
            postRequirements {
              id
              requirement
              emoji
              type
              externalPlatform
              isDefault
              referenceBrand {
                id
                name
                socialHandle
                tiktokSocialHandle
              }
            }
          }
          audience {
            brands {
              id
              name
            }
            brandCategories {
              id
              name
            }
            creators {
              id
              name
            }
          }
          totalPosts
          totalClaims
          postRate
          startDate
          endDate
          isCommercialSoundsOnly
          category {
            id
            name
          }
          feedback {
            id
            createdAt
            feedback
            actorName
            status
          }
          source
          requiredBrandProducts {
            id
            externalUrl
          }
          postRequirements {
            id
            requirement
            emoji
            type
            externalPlatform
            isDefault
            referenceBrand {
              id
              name
              socialHandle
              tiktokSocialHandle
            }
          }
          title
          challengeTemplate {
            id
            title
            template
            soundUrl
            exampleUrl
            templateType
          }
          type
        }
      }
    }
  }
`);

export interface ActiveDealsQuery {
  activeDeals: Connection<Deal>;
}

export const ACTIVE_DEALS = gql(`
  query ActiveDeals($userId: ID) {
    activeDeals(userId: $userId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          state
          merchantName
          merchantInstagramHandle
          brandId
          rejectionTypes {
            name
            value
          }
          creatorBrand {
            id
            name
            logoUrl
            socialHandle
          }
          challenge {
            id
            description
            tagRequirements
            mentionRequirements
            exampleUrl
            difficulty
            brand {
              id
              name
              socialHandle
              logoUrl
              activeChallenges {
                id
                description
                mentionRequirements
                tagRequirements
                createdAt
                state
                exampleUrl
                difficulty
              }
            }
            postRequirements {
              id
              requirement
              iconFilename
            }
            isCommercialSoundsOnly
            type
          }
          creator {
            id
          }
        }
      }
    }
  }
`);

export interface DealQuery {
  deal: Deal;
}

export const DEAL = gql(`
  query Deal($dealId: ID!) {
    deal(dealId: $dealId) {
      id
      state
      merchantName
      merchantInstagramHandle
      brandId
      rejectionTypes {
        name
        value
      }
      creatorBrand {
        id
        name
        logoUrl
        socialHandle
      }
      challenge {
        id
        description
        tagRequirements
        mentionRequirements
        exampleUrl
        brand {
          id
          name
          socialHandle
          logoUrl
          activeChallenges {
            id
            description
            mentionRequirements
            tagRequirements
            createdAt
            state
            exampleUrl
            difficulty
          }
        }
        isCommercialSoundsOnly
        postRequirements {
          id
          requirement
          iconFilename
        }
        type
      }
      creator {
        id
      }
    }
  }
`);

export const BRAND_CATEGORIES = gql(`
  query BrandCategories($first: Int, $after: String) {
    brandCategories(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
        }
      }
    }
  }
`);

export const BRAND_LANDING_PAGE = gql(`
  query BrandLandingPage($referralCode: String!) {
    brandLandingPage(referralCode: $referralCode) {
      title
      videos {
        url
        username
        externalUrl
      }
    }
  }
`);

export const TEMPLATED_MESSAGES = gql(`
  query TemplatedMessages($creatorId: ID!) {
    templatedMessages(creatorId: $creatorId) {
      buttonText
      templateText
    }
  }
`);

export const INSTAGRAM_AUTOMATED_MESSAGE_SETTINGS = gql(`
  query InstagramAutomatedMessageSettings($brandId: ID!) {
    instagramAutomatedMessageSettings(brandId: $brandId) {
      isEnabled
      title
      subtitle
      maxFollowerCount
      minFollowerCount
      imageUrl
    }
  }
`);

export const BRAND_TRANSACTIONS = gql(`
  query BrandTransactions($brandId: ID!, $first: Int, $after: String) {
    brandTransactions(brandId: $brandId, first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          createdAt
          amount
          type
          paymentMethodType
          description
          stripeUrl
          effectiveAt
        } 
      }
    }
  }
`);

export const WHITELIST_CONTENT = gql(`
  query WhitelistContent(
    $brandId: ID
    $states: [String]
    $creatorId: String
    $first: Int
    $after: String
  ) {
    whitelistContent(
      brandId: $brandId
      states: $states
      creatorId: $creatorId
      first: $first
      after: $after
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          budget
          state
          post {
            id
            externalPermalink
            externalUser {
              id
              externalPlatform
              username
              externalUrl
              pictureUrl
            }
            productType
            likeCount
            commentCount
            impressions
            savedCount
            caption
            mediaUrls {
              url
              mediaType
            }
            siblings {
              id
              externalPermalink
              likeCount
              commentCount
              impressions
              externalPlatform
              caption
            }
            deal {
              creator {
                id
              }
              creatorBrand {
                id
                name
                logoUrl
              }
            }
          }
        }
      }
    }
  }
`);

export interface ChallengeCategoriesInterface {
  challengeCategories: ChallengeCategory[];
}

export const CHALLENGE_CATEGORIES = gql(`
  query ChallengeCategories($brandCategoryIds: [ID!], $brandId: ID) {
    challengeCategories(
      brandCategoryIds: $brandCategoryIds
      brandId: $brandId
    ) {
      id
      name
      template
      brandCategories {
        id
        name
      }
    }
  }
`);

export interface ChallengesBadges {
  pendingReviewFromBrand?: number;
  newFeedbackFromBrand?: number;
}

export interface ChallengesBadgesInterface {
  challengesBadges: ChallengesBadges;
}

export const CHALLENGES_BADGES = gql(`
  query ChallengesBadges {
    challengesBadges {
      pendingReviewFromBrand
      newFeedbackFromBrand
    }
  }
`);

export interface AdminOffersInterface {
  adminOffers: Connection<Offer>;
}

export const ADMIN_OFFERS = gql(`
  query AdminOffers($first: Int, $after: String) {
    adminOffers(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          brand {
            id
            name
            logoUrl
          }
          cashbackPercentage
          createdAt
          state
          kaleFeePercentage
        }
      }
    }
  }
`);

export interface PostRequirementsInterface {
  postRequirements: PostRequirement[];
}

export const POST_REQUIREMENTS = gql(`
  query PostRequirements($brandId: ID, $types: [String!]) {
    postRequirements(brandId: $brandId, types: $types) {
      id
      requirement
      iconFilename
      emoji
      isMappedToBrand(brandId: $brandId)
      type
      externalPlatform
      isDefault
      referenceBrand {
        id
        name
        socialHandle
        tiktokSocialHandle
      }
      mappedActiveChallengesCount(brandId: $brandId)
    }
  }
`);

export interface ChallengePostRequirementsInterface {
  challengePostRequirements: PostRequirement[];
}

export const CHALLENGE_POST_REQUIREMENTS = gql(`
  query ChallengePostRequirements($brandId: ID!, $types: [String!], $onlyMappedToBrand: Boolean) {
    challengePostRequirements(brandId: $brandId, types: $types, onlyMappedToBrand: $onlyMappedToBrand) {
      id
      requirement
      emoji
      type
      externalPlatform
      isDefault
      referenceBrand {
        id
        name
        socialHandle
        tiktokSocialHandle
      }
      mappedActiveChallengesCount(brandId: $brandId)
      isMappedToBrand(brandId: $brandId)
    }
  }
`);

export interface BrandPaymentStructuresInterface {
  brandPaymentStructures: BrandPaymentStructure[];
}

export const BRAND_PAYMENT_STRUCTURES = gql(`
  query BrandPaymentStructures($brandId: ID!) {
    brandPaymentStructures(brandId: $brandId) {
      id
      brand {
        id
      }
      minQualityScore
      productType
      kaleOriginationFee
      creatorOriginationFee
      kaleCpmFee
      creatorCpmFee
    }
  }
`);

export interface NextTaskQuery {
  getNextTask: {
    taskId: number;
    metadata: any;
    remainingTasksInQueue: number;
  };
}

export const GET_NEXT_BONSAI_TASK = gql(`
  query GetNextTask($queueName: String!) {
    getNextTask(queueName: $queueName) {
      taskId
      metadata
      remainingTasksInQueue
    }
  }
`);

export interface GetBonsaiQueuesQuery {
  getQueues: {
    name: string;
    friendlyName: string;
    taskDescription: string;
    remainingTasks: number;
    oldestTaskCreationDate?: string;
    averageTaskAge?: string;
    activeAdmins?: Admin[];
  }[];
}

export const GET_BONSAI_QUEUES = gql(`
  query GetBonsaiQueues {
    getQueues {
      name
      friendlyName
      taskDescription
      remainingTasks
      oldestTaskCreationDate
      averageTaskAge
      activeAdmins {
        id
        name
      }
    }
  }
`);

export interface GetSharedBonsaiTaskQuery {
  getTask: {
    metadata: any;
    queueName: string;
  };
}

export const GET_SHARED_BONSAI_TASK = gql(`
  query GetSharedTask($taskId: Int!) {
    getTask(taskId: $taskId) {
      taskId
      metadata
      queueName
    }
  }
`);

export const CHALLENGE_HISTORY = gql(`
  query ChallengeHistory($first: Int, $after: String, $challengeId: ID!) {
    challengeHistory(first: $first, after: $after, challengeId: $challengeId) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          challengeId
          createdAt
          state
          tagRequirements
          exampleUrl
          difficulty
          startDate
          endDate
          description
          actorName
          actorType
          rank
          title
        }
      }
    }
  }
`);

export interface AdminsInterface {
  admins: KaleAdmin[];
}

export const ADMINS = gql(`
  query Admins($roles: [String!]) {
    admins(roles: $roles) {
      id
      name
      email
      role
    }
  }
`);

export interface AdminPermissionsInterface {
  adminPermissions: AdminPermission[];
}

export const ADMIN_PERMISSIONS = gql(`
  query AdminPermission($adminId: ID) {
    adminPermissions(adminId: $adminId) {
      id
      groupName
      permissionName
      description
      mappedToCurrentAdmin
      roles
    }
  }
`);

export const GET_BRAND_SAGE_SETTINGS = gql(`
  query GetSageSettings($brandId: ID!) {
    getSageSettings(brandId: $brandId) {
      ...SageSettingsFragment 
    }
  }
`);

export const GET_DOWNLOAD_SIGNED_URL = gql(`
   query GetDownloadSignedUrl($id: String!, $mimeType: String!, $type: SignedUrlType!) {
     getDownloadSignedUrl(id: $id, mimeType: $mimeType, type: $type)
   }
`);

export const CREATOR_STRIKES = gql(`
  query CreatorStrikes($creatorId: ID!) {
    creatorStrikes(creatorId: $creatorId) {
      id
      badActorType
      weight
      createdAt
      note
      actorAdmin {
        id
        name
        email
        role
      }
      undoneAt
      undoActorAdmin {
        id
        name
        email
        role
      }
      deal {
        id
      }
      post {
        id
        externalPermalink
      }
    }
  }
`);

export const LINKED_CREATORS = gql(`
  query LinkedCreators($creatorId: ID!) {
    linkedCreators(creatorId: $creatorId) {
      id 
      phoneNumber
      name
      socialHandle
      referralCode
      photoUrl
      balance
      tiktokSocialHandle
      activeDeal {
        id
        relevance
        reward
        state
        transactionAmount
        createdAt
        merchant
        merchantName
        merchantInstagramHandle
        rewardAmount
      }
      externalUsers {
        id
        username
        followers
      }
      email
      birthdate
      accountState
      pendingBalance
      countryCode
    }
  }
`);

export interface NotificationCampaignsData {
  notificationCampaigns: Connection<MessageCampaign>;
}

export const NOTIFICATION_CAMPAIGNS = gql(`
  query NotificationCampaigns($first: Int, $after: String) {
    notificationCampaigns(first: $first, after: $after) {
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          name
          createdAt
          createdBy {
            id
            name
            email
            role
          }
          textMessage
          targetType
          targetQueryId
          targetCreatorIds
        }
      }
    }
  }
`);

export interface ChallengeTemplatesData {
  challengeTemplates: ChallengeTemplate[];
}

export const CHALLENGE_TEMPLATES = gql(`
  query ChallengeTemplates($brandId: ID, $templateType: String, $status: String) {
    challengeTemplates(brandId: $brandId, templateType: $templateType, status: $status) {
      id
      title
      template
      soundUrl
      exampleUrl
      createdAt
      status
      templateType
      activeChallengesCount
      tags {
        id
        name
      }
    }
  }
`);

export const SEARCH_REQUIREMENTS = gql(`
  query SearchRequirements($query: String!) {
    searchRequirements(query: $query) {
      id
      requirement
      emoji
      type
      externalPlatform
      isDefault
      referenceBrand {
        id
        name
        socialHandle
        tiktokSocialHandle
      }
    }
  }
`);

export const GET_BRAND_PAYOUT_STRUCTURES = gql(`
  query GetBrandPayoutStructure($brandId: ID!) {
    brandPayoutStructures(brandId: $brandId) {
      id
      active
      structure {
         contentCommissionPercentage
         maxPayout
      }
    }
  }
`);

export interface ChallengeTemplateTagsData {
  challengeTemplateTags: ChallengeTemplateTag[];
}

export const CHALLENGE_TEMPLATE_TAGS = gql(`
  query ChallengeTemplateTags {
    challengeTemplateTags {
      id
      name
    }
  }  
`);