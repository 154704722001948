import { Controller, useForm } from 'react-hook-form';
import CountryPicker from '../../CountryPicker';
import { Button } from '../../catalyst/button';
import { useMutation } from '@apollo/client';
import { UPDATE_BRAND_AVAILABILITY_MUTATION } from '../../../graphql/mutations';
import {
  Brand,
  MutationUpdateBrandAvailabilityArgs,
} from '../../../__generated__/graphql';
import { useEffect } from 'react';

interface BrandAvailabilityProps {
  brand: Brand;
}

interface BrandAvailabilityInput {
  countryCodeAllowList: string[];
}

export default function BrandAvailability(props: BrandAvailabilityProps) {
  const {
    control,
    handleSubmit,
    reset,
    formState: { isDirty, isValid, isSubmitting },
  } = useForm<BrandAvailabilityInput>({
    defaultValues: {
      countryCodeAllowList: props.brand.countryCodeAllowList,
    },
  });

  const [updateBrandAvailabilityMutation] =
    useMutation<MutationUpdateBrandAvailabilityArgs>(
      UPDATE_BRAND_AVAILABILITY_MUTATION
    );

  const onSubmit = async (data: BrandAvailabilityInput) => {
    await updateBrandAvailabilityMutation({
      variables: {
        brandId: props.brand.id,
        countryCodeAllowList: data.countryCodeAllowList,
      },
    });
  };

  useEffect(() => {
    reset({
      countryCodeAllowList: props.brand.countryCodeAllowList,
    });
  }, [props.brand, reset]);

  return (
    <div className="flex flex-col gap-y-4">
      <div className="justify-cen flex flex-row justify-center">
        <h1 className="text-sm font-semibold">Brand Availability</h1>
      </div>
      <div>
        <h3 className="text-sm font-semibold leading-6 text-gray-900">
          Country Availability
        </h3>
      </div>
      <Controller
        name={'countryCodeAllowList'}
        control={control}
        rules={{ validate: (value) => value.length > 0 }}
        render={({ field: { onChange, value } }) => (
          <CountryPicker
            selectedCountries={value}
            setSelectedCountries={onChange}
          />
        )}
      />
      <div />
      <div className="flex flex-row justify-end">
        <Button
          color="purple"
          onClick={handleSubmit(onSubmit)}
          disabled={!isDirty || !isValid || isSubmitting}
        >
          Save Changes
        </Button>
      </div>
    </div>
  );
}
