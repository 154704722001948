import { useContext, useEffect, useState } from "react";
import { Loading } from "@kalecard/common";
import { auth } from "../config/firebase";
import { UserContext } from "../providers/UserProvider";

const ProfilePage = () => {
  const { user } = useContext(UserContext);

  useEffect(() => {
    return () => {};
  }, [user]);

  if (!user) {
    return <Loading />;
  }

  const photoURL = user?.photoURL;
  const displayName = user?.displayName;
  const email = user?.email;

  const avatar = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-11 w-11"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
      />
    </svg>
  );

  const photo = (
    <div
      style={{
        background: `url(${photoURL})  no-repeat center center`,
        backgroundSize: "cover",
        height: "60px",
        width: "60px",
      }}
      className="rounded rounded-full border border-kale-green-500"
    ></div>
  );

  return (
    <div className="py-6">
      <div className="mx-auto w-11/12 px-4 py-8 md:w-2/4 md:px-8">
        <div className="flex flex-col items-center rounded-lg border border-gray-200 p-4 md:flex-row md:items-start">
          {photoURL ? photo : avatar}
          <div className="md:pl-4">
            <h2 className="text-2xl font-semibold">{displayName}</h2>
            <h3 className="italic">{email}</h3>
          </div>
        </div>
        <button
          className="mt-4 w-full rounded bg-red-600 py-3 text-white"
          onClick={() => {
            auth.signOut();
          }}
        >
          Sign out
        </button>
      </div>
    </div>
  );
};
export default ProfilePage;
