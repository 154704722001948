import { useQuery } from "@apollo/client";
import { LINKED_CREATORS } from "../../graphql/queries";

import CreatorsList from "../lists/CreatorsList";
import { Creator } from "@kalecard/common";

interface LinkedCreatorsData {
  linkedCreators?: Creator[];
}
export default function CreatorLinkedAccounts({
  creatorId,
}: {
  creatorId: string;
}) {
  const { data, loading } = useQuery<LinkedCreatorsData>(LINKED_CREATORS, {
    variables: { creatorId },
  });

  return (
    <div className="flex flex-col gap-y-2 rounded-lg border p-4">
      <h1>Linked Accounts</h1>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <CreatorsList creators={data.linkedCreators} />
      )}
    </div>
  );
}
