import {
  MediaUrl,
  Post,
  prettyDecimal,
  timeDifferenceForDate,
} from "@kalecard/common";
import MediaComponent from "../MediaComponent";
import PostStatsComponent from "../PostStatsComponent";
import { Link } from "react-router-dom";
import PostRewardCategory from "./PostRewardCategory";

interface PostComponentProps {
  post: Post;
}

export default function SimplePostComponent({ post }: PostComponentProps) {
  const captionLink = (
    <a
      className="whitespace-pre-wrap break-all text-sm line-clamp-[10] font-semibold text-indigo-600 hover:text-indigo-900"
      href={post.externalPermalink}
      target="_blank"
      rel="noreferrer"
    >
      {post.caption ? post.caption : "(empty caption)"}
    </a>
  );

  const isFeedCarouselPost = post.mediaType === "CAROUSEL_ALBUM";

  return (
    <tr>
      <td className="px-6 py-4 text-sm">
        <div>{captionLink}</div>
        <div className="text-sm text-gray-500">
          Posted {timeDifferenceForDate(Number(post.createdAt))} by{" "}
          <a
            className="truncate text-sm font-semibold text-indigo-600 hover:text-indigo-900"
            href={`https://www.instagram.com/${post?.externalUser?.username
              ? post.externalUser.username
              : post.username
              }`}
            target="_blank"
            rel="noreferrer"
          >
            {post?.externalUser?.username
              ? post.externalUser.username
              : post.username}
          </a>
        </div>
        <div className="mt-4">
          Creator:{" "}
          <Link
            className="cursor-pointer text-sm font-semibold text-indigo-600 hover:text-indigo-900"
            to={"/creators/" + post.deal.creator.id + "/messages"}
            rel="noreferrer"
          >
            {post.deal.creator.id}
          </Link>
        </div>
        <div>
          <PostRewardCategory
            postId={post.id}
            rewardCategory={post.rewardCategory}
          />
        </div>
        <div className="flex flex-col justify-start space-y-1 text-sm text-gray-500">
          <p>
            Post ID: {post.id}{" "}
            <span className="font-bold text-black">
              - Score: {prettyDecimal(post.qualityScore / 100)}
            </span>
          </p>
        </div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <div>{post.externalPlatform}</div>
        <div>{post.mediaType}</div>
        <div>{post.productType}</div>
      </td>
      <td className="whitespace-nowrap px-6 py-4 text-sm">
        <PostStatsComponent post={post} />
      </td>
      <td className="whitespace-nowrap px-6 py-4">
        <div>
          <div className="flex flex-wrap items-center justify-between">
            {post.mediaUrls.map((mediaUrl: MediaUrl, index) => (
              <div key={index} className="flex items-center">
                {isFeedCarouselPost ? (
                  <>
                    {mediaUrl?.carouselItem?.isBrandPost && (
                      <MediaComponent
                        key={mediaUrl.url}
                        url={mediaUrl.url}
                        mediaType={mediaUrl.mediaType}
                      />
                    )}
                  </>
                ) : (
                  <MediaComponent
                    key={mediaUrl.url}
                    url={mediaUrl.url}
                    mediaType={mediaUrl.mediaType}
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      </td>
    </tr>
  );
}
