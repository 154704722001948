import { createContext, useReducer } from "react";
import {
  ChallengesReducer,
  ChallengesState,
} from "../reducers/ChallengesReducer";

export const ChallengesContext = createContext<{
  state: ChallengesState;
  dispatch: React.Dispatch<{}>;
}>(null);

export default function ChallengesProvider({
  challengesState,
  children,
}: {
  challengesState?: ChallengesState;
  children?: any;
}) {
  const [state, dispatch] = useReducer(
    ChallengesReducer,
    getInitialState(challengesState)
  );

  return (
    <ChallengesContext.Provider value={{ state: state, dispatch }}>
      {children}
    </ChallengesContext.Provider>
  );
}

function getInitialState(challengesState: ChallengesState): ChallengesState {
  return {
    challenges: challengesState.challenges || [],
    hasNextPage: challengesState.hasNextPage,
    after: challengesState.after,
    brand: challengesState.brand,
    challengeState: challengesState.challengeState,
    newChallenges: challengesState.newChallenges || [],
    sortingType: challengesState.sortingType,
    keywords: challengesState.keywords || [],
    fetchPolicy: challengesState.fetchPolicy || "cache-first",
    brands: challengesState.brands || [],
    challengeIds: challengesState.challengeIds || [],
    shouldUpdateChallenges: challengesState.shouldUpdateChallenges,
    pendingChallengesCount: challengesState.pendingChallengesCount,
    sortableColumns: challengesState.sortableColumns || [],
    feedbackCount: challengesState.feedbackCount,
    additionalFilters: challengesState.additionalFilters
  };
}
