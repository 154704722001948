import React from "react";
import { Heading } from "../catalyst/heading";
import { Divider } from "../catalyst/divider";
import { formatCurrency } from "../../utils/currency";
import { SageDetails } from "../../__generated__/graphql";
import { Tooltip } from "flowbite-react";

interface DetailsSideBarProps {
  details: SageDetails;
  effectiveBudget: number;
  pacingEnabled: boolean;
}

export function DetailsSideBar(props: DetailsSideBarProps) {
  const contentBudget = props.details.contentBudget;
  const predictedOutstanding = props.details.predictedOutstanding;
  const challengeBudgets = props.details.challengeBudgetAllocation;
  const targetBudget = props.details.targetBudget;
  const idealBudgetDifference = props.effectiveBudget - targetBudget;

  return (
    <div>
      <Heading className="mb-2">Details</Heading>
      <Divider />
      <div className="mt-4 flex flex-col gap-6">
        <DetailLabel
          label="Content budget"
          value={formatCurrency(contentBudget)}
          description={"The remaining content budget for this brand"}
        />
        <DetailLabel
          label={"Target budget"}
          value={props.pacingEnabled ? formatCurrency(targetBudget) : "–"}
          description={
            "The ideal effective budget for this brand based on the current pacing schedule"
          }
        />
        <DetailLabel
          label="Predicted outstanding deal cost"
          value={formatCurrency(predictedOutstanding)}
          description={
            "The total predicted cost of all deals that are outstanding (not paid or expired)"
          }
        />
        <DetailLabel
          label="Effective budget"
          value={formatCurrency(props.effectiveBudget)}
          description={
            "The content budget minus predicted outstanding and challenge budgets"
          }
        />
        <DetailLabel
          label="Challenge budgets"
          value={challengeBudgets ? formatCurrency(challengeBudgets) : "–"}
          description={
            "The sum of the budgets allocated to specific challenges"
          }
        />
        <DetailLabel
          label="Ideal Target Budget Difference"
          value={
            props.pacingEnabled ? formatCurrency(idealBudgetDifference) : "–"
          }
          description="The amount that the target budget differs from the effective budget"
          valueColor={
            props.pacingEnabled
              ? idealBudgetDifference >= 0
                ? "green"
                : "red"
              : undefined
          }
          fontWeight={props.pacingEnabled ? "bold" : "regular"}
        />
      </div>
    </div>
  );
}

interface DetailLabelProps {
  label: string;
  value: string;
  description?: string;
  valueColor?: "green" | "red"; // Restrict to 'green' or 'red'
  fontWeight?: "bold" | "regular"; // Add this line
}

function DetailLabel(props: DetailLabelProps) {
  return (
    <div>
      <Tooltip content={props.description}>
        <div className="text-sm font-bold text-slate-800">{props.label}</div>
        <div
          data-value-color={props.valueColor}
          data-font-weight={props.fontWeight}
          className="
            font-mono text-sm
            data-[font-weight=bold]:font-bold
            data-[font-weight=regular]:font-normal
            data-[value-color=green]:text-green-500
            data-[value-color=red]:text-red-500
          "
        >
          {props.value}
        </div>
      </Tooltip>
    </div>
  );
}
