import { Modal, Spinner } from "@kalecard/common";
import { useState } from "react";
import CreatorAccountState from "../CreatorAccountState";
import { UPDATE_CREATOR_ACCOUNT_STATE } from "../../../graphql/mutations";
import { useMutation } from "@apollo/client";
import BlockCreatorForm from "../../forms/BlockCreatorForm";

export default function UpdateCreatorAccountState({ creator }) {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [accountState, setAccountState] = useState<string>(
    creator.accountState
  );
  const [updateAccountState] = useMutation(UPDATE_CREATOR_ACCOUNT_STATE);
  const [isBlockCreatorModalOpen, setIsBlockCreatorModalOpen] = useState(false);
  const onUpdateAccountState = async (accountState: string) => {
    setIsLoading(true);
    await updateAccountState({
      variables: {
        creatorId: creator.id,
        accountState: accountState,
      },
    });
    setAccountState(accountState);
    setIsLoading(false);
  };

  const handleBlocked = () => {
    setAccountState("BLOCKED_BAD_ACTOR");
    setIsBlockCreatorModalOpen(false);
  };

  return (
    <div className="flex rounded-lg border p-4">
      <div className="flex w-full items-center space-x-4">
        <div className="block text-sm font-medium text-gray-700">
          Account State:
        </div>
        <div>
          <CreatorAccountState state={accountState} />
        </div>
      </div>

      <div className="flex w-fit items-center justify-end">
        {isLoading && <Spinner size={"h-6 w-6"} />}
        <button
          disabled={
            isLoading || accountState === "NEW" || accountState === "APPROVED"
          }
          onClick={() => onUpdateAccountState("NEW")}
          className="ml-3 inline-flex justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-300"
        >
          Reset Account Status
        </button>
        <button
          disabled={
            isLoading ||
            accountState === "BLOCKED_UNDERAGE" ||
            accountState === "BLOCKED_BAD_ACTOR"
          }
          onClick={() => setIsBlockCreatorModalOpen(true)}
          className="ml-3 inline-flex justify-center whitespace-nowrap rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-300"
        >
          Block
        </button>
      </div>
      <Modal
        isModalOpen={isBlockCreatorModalOpen}
        onClose={() => setIsBlockCreatorModalOpen(false)}
      >
        <div>
          <BlockCreatorForm creatorId={creator.id} blocked={handleBlocked} />
        </div>
      </Modal>
    </div>
  );
}
