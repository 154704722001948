import { BonsaiActionPanel } from "../BonsaiContainer";
import { BonsaiQueue } from "../BonsaiModels";
import React, { useState } from "react";
import {
  BonsaiContextLinkType,
  BonsaiPanel,
  BonsaiVideoPanel,
  creatorAdminLink,
} from "@kalecard/common";
import { Listbox, ListboxLabel, ListboxOption } from "../../catalyst/listbox";
import { useQuery } from "@apollo/client";
import { DEAL } from "../../../graphql/queries";

interface RejectionReason {
  id: string;
  name: string;
}

interface MapBrandRejectionToolInput {
  post_id: number;
  deal_id: number;
  post_media_url: string;
  rejection_reasons: RejectionReason[];
  note?: string;
}

interface MapBrandRejectionToolOutput {
  rejection_reason_id: string;
}

interface MapBrandRejectionToolProps {
  queue: BonsaiQueue;
  onComplete: (output: MapBrandRejectionToolOutput) => void;
  readOnly: boolean;
  taskId: number;
  input: MapBrandRejectionToolInput;
}

export default function MapBrandRejectionTool(
  props: MapBrandRejectionToolProps
) {
  const [selectedRejectionReason, setSelectedRejectionReason] =
    useState<string>(undefined);
  const { data, loading } = useQuery(DEAL, {
    variables: {
      dealId: props.input.deal_id.toString(),
    },
  });

  if (loading) return <div>Loading...</div>;

  return (
    <>
      <div className="flex justify-center">
        <div className="max-w-[360px]">
          <BonsaiVideoPanel
            source={props.input.post_media_url}
            links={[
              {
                type: BonsaiContextLinkType.CREATOR,
                url: `${creatorAdminLink(data?.deal?.creator?.id)}post?dealId=${
                  props.input.deal_id
                }`,
              },
            ]}
            autoPlay={false}
            title={`Deal ID: ${props.input.deal_id}`}
          />
        </div>
        {props.input.note ? (
          <div className=" ml-4 max-w-[360px] text-gray-900">
            <BonsaiPanel>
              <div className="px-4 py-2">
                <h3 className="text-lg font-semibold">Brand Note:</h3>
                <div className="text-sm">{props.input.note}</div>
              </div>
            </BonsaiPanel>
          </div>
        ) : null}
      </div>
      {!props.readOnly && (
        <BonsaiActionPanel
          submitDisabled={selectedRejectionReason === undefined}
          onSubmit={() => {
            props.onComplete({
              rejection_reason_id: selectedRejectionReason,
            });
            setSelectedRejectionReason(undefined);
          }}
          taskDescription={props.queue.taskDescription}
          taskId={props.taskId}
        >
          <Listbox
            name="rejectionReason"
            placeholder="Set Rejection Reason"
            value={selectedRejectionReason}
            onChange={(value) => setSelectedRejectionReason(value)}
            anchorTo="top start"
          >
            {props.input.rejection_reasons?.map((reason) => (
              <ListboxOption key={reason.id} value={reason.id}>
                <ListboxLabel>{reason.name}</ListboxLabel>
              </ListboxOption>
            ))}
          </Listbox>
        </BonsaiActionPanel>
      )}
    </>
  );
}
