import { useApolloClient } from "@apollo/client";
import { useEffect, useState } from "react";
import { auth } from "../../config/firebase";
import { DEALS } from "../../graphql/queries";
import DealListComponent from "../lists/elements/DealListComponent";
import { Creator, Deal, Edge, Spinner } from "@kalecard/common";

interface CreatorDealsInterface {
  creator: Creator;
}

export default function CreatorDeals({ creator }: CreatorDealsInterface) {
  const [edges, setEdges] = useState([]);
  const [after, setAfter] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [localDeal, setLocalDeal] = useState<Deal>(null);
  const apolloClient = useApolloClient();

  useEffect(() => {
    if (edges.length === 0) {
      loadMore();
    }
    return () => {};
  }, []);

  const loadMore = async () => {
    setLoading(true);
    const tokenId = await auth.currentUser.getIdToken();
    try {
      const result = await apolloClient.query({
        query: DEALS,
        variables: {
          first: 20,
          after,
          userId: creator.id,
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
      console.log(result);
      setHasNextPage(result.data.deals.pageInfo.hasNextPage);
      setAfter(
        result.data.deals.edges[result.data.deals.edges.length - 1]?.cursor
      );
      setEdges([...edges, ...result.data.deals.edges]);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  var listFooter = null;
  if (hasNextPage) {
    listFooter = (
      <button
        className="rounded border border-green-500 bg-transparent px-6 py-2 text-center font-semibold text-green-700 hover:border-transparent hover:bg-green-500 hover:text-white"
        onClick={() => loadMore()}
      >
        Load more
      </button>
    );
  }

  if (loading) {
    listFooter = (
      <div className="flex flex-wrap content-center">
        <Spinner size="h-6 w-6" />
      </div>
    );
  }

  return (
    <>
      <div className="mt-4 flex flex-col items-center">
        <div className="mb-2 w-full overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="overflow-y-visible border-2 border-gray-200 shadow sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      ID
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Merchant
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Payment
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      State
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium uppercase tracking-wider text-gray-500"
                    >
                      Offer Date
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {localDeal && (
                    <tr key={localDeal.id}>
                      <DealListComponent deal={localDeal} creator={creator} />
                    </tr>
                  )}
                  {edges.map((edge: Edge<Deal>, index) => {
                    return (
                      <tr key={edge.node.id}>
                        <DealListComponent deal={edge.node} creator={creator} />
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {listFooter}
      </div>
    </>
  );
}
