import { useLazyQuery, useMutation } from '@apollo/client';
import { POST_REQUIREMENTS, SEARCH_REQUIREMENTS } from '../../graphql/queries';
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableHeader,
} from '../catalyst/table';
import {
  ArrowPathIcon,
  EllipsisHorizontalIcon,
  FaceSmileIcon,
} from '@heroicons/react/24/outline';
import Picker from '@emoji-mart/react';
import data from '@emoji-mart/data';
import { useEffect, useState } from 'react';
import { Combobox } from '@headlessui/react';
import {
  CREATE_POST_REQUIREMENT,
  UPDATE_POST_REQUIREMENT,
} from '../../graphql/mutations';
import { Brand, PostRequirement } from '../../__generated__/graphql';
import { InstagramLogo, TiktokLogo } from '@kalecard/common';
import { Input } from '../catalyst/input';
import { Button } from '../catalyst/button';
import { Badge } from '../catalyst/badge';
import { Text } from '../catalyst/text';
import { Badge as FlowbiteBadge, Tooltip } from 'flowbite-react';
import KaleLogo from '../../kale-logo.svg';
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownMenu,
} from '../catalyst/dropdown';
import EmptyDataState from '../EmptyDataState';

export enum PostRequirementAction {
  EDIT,
  REMOVE_FROM_CHALLENGE,
}

const SearchRequirement = ({
  text,
  onSearch,
  onAdd,
}: {
  text: string;
  onSearch: (query: string) => void;
  onAdd(postRequirement: PostRequirement): void;
}) => {
  const [searchRequirements, { data: searchData }] =
    useLazyQuery(SEARCH_REQUIREMENTS);
  const [query, setQuery] = useState('');
  const [selectedRequirement, setSelectedRequirement] = useState(null);

  useEffect(() => {
    if (query.length > 0) {
      searchRequirements({
        variables: { query },
      });
    }
  }, [query]);

  return (
    <div className="flex flex-row items-center space-x-4 p-4">
      <div className="flex w-full items-center">
        <Combobox
          value={selectedRequirement}
          onChange={setSelectedRequirement}
        >
          <div className="relative w-full">
            <Combobox.Input
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              onChange={(event) => setQuery(event.target.value)}
              displayValue={(requirement: PostRequirement) =>
                requirement?.type == 'MENTION'
                  ? 'Mention ' +
                    requirementForMention(
                      requirement?.referenceBrand,
                      requirement?.externalPlatform
                    )
                  : requirement?.requirement
              }
              placeholder={text}
            />
            <Combobox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {searchData?.searchRequirements.length === 0 && query !== '' ? (
                <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                  No requirements found.
                </div>
              ) : (
                searchData?.searchRequirements.map((requirement: any) => (
                  <Combobox.Option
                    key={requirement.id}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 ${
                        active ? 'bg-indigo-600 text-white' : 'text-gray-900'
                      }`
                    }
                    value={requirement}
                  >
                    {({ selected, active }) =>
                      RequirementSearchItem(requirement, selected)
                    }
                  </Combobox.Option>
                ))
              )}
            </Combobox.Options>
          </div>
        </Combobox>
      </div>
      <Button
        color="indigo"
        disabled={!selectedRequirement}
        onClick={() => {
          if (selectedRequirement) {
            onAdd(selectedRequirement);
            setQuery('');
            setSelectedRequirement(null);
          }
        }}
      >
        Add
      </Button>
    </div>
  );
};

const RequirementSearchItem = (
  requirement: PostRequirement,
  selected: boolean
) => (
  <>
    {requirement.type && (
      <div className="inline-block">
        <TypeBadge type={requirement.type} />
      </div>
    )}
    {requirement.emoji && (
      <span className="absolute inset-y-0 left-0 flex items-center pl-3">
        {requirement.emoji}
      </span>
    )}
    <span
      className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}
    >
      {requirement.type == 'MENTION'
        ? `${requirementForMention(
            requirement.referenceBrand,
            requirement.externalPlatform
          )}`
        : requirement.requirement}
    </span>
  </>
);

const NEW_REQUIREMENT_TOGGLE = ['CONTENT', 'SOUND', 'HASHTAG'];

const NewRequirement = ({
  handleNewRequirement,
  isBrandPage,
}: {
  handleNewRequirement: (postRequirement: any) => void;
  isBrandPage: boolean;
}) => {
  const [createPostRequirement] = useMutation(CREATE_POST_REQUIREMENT, {
    refetchQueries: [POST_REQUIREMENTS],
  });

  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [emoji, setEmoji] = useState(null);

  const [requirementText, setRequirementText] = useState('');

  const [selectedType, setSelectedType] = useState('CONTENT');
  const [selectedTypeIndex, setSelectedTypeIndex] = useState(0);

  useEffect(() => {
    setEmoji(null);
    setRequirementText('');
    setSelectedType(NEW_REQUIREMENT_TOGGLE[selectedTypeIndex]);
  }, [selectedTypeIndex]);

  return (
    <TableRow>
      <TableCell className="w-24">
        <button
          type="button"
          className="border-1 inline-block rounded-md p-1 outline-dashed"
          onClick={() => {
            setSelectedTypeIndex(
              (selectedTypeIndex + 1) % NEW_REQUIREMENT_TOGGLE.length
            );
          }}
        >
          <TypeBadge type={selectedType} />
        </button>
      </TableCell>
      <TableCell className="whitespace-nowrap py-4 pl-3 text-sm">
        <div className="flex space-x-2">
          {selectedType != 'HASHTAG' ? (
            <div>
              <button
                type="button"
                onClick={() => {
                  setIsEmojiOpen(!isEmojiOpen);
                }}
              >
                {emoji ? (
                  <p className="text-lg">{emoji}</p>
                ) : (
                  <FaceSmileIcon className="h-5 w-5" />
                )}
              </button>
            </div>
          ) : null}

          {isEmojiOpen && (
            <div className="absolute z-10">
              <Picker
                data={data}
                onEmojiSelect={(emoji) => {
                  setEmoji(emoji.native);
                  setIsEmojiOpen(false);
                }}
              />
            </div>
          )}
        </div>
      </TableCell>
      <TableCell className="whitespace-nowrap text-sm">
        <Input
          type="text"
          name="requirement"
          id="requirement"
          placeholder="Requirement"
          value={requirementText}
          onChange={(e) => setRequirementText(e.target.value)}
        />
      </TableCell>
      {isBrandPage && (
        <>
          <TableCell />
          <TableCell />
        </>
      )}
      <TableCell className="whitespace-nowrap text-sm font-medium">
        <div className="flex space-x-2 text-right">
          <Button
            color="indigo"
            disabled={
              requirementText?.length == 0 ||
              (selectedType == 'HASHTAG' && !requirementText.startsWith('#'))
            }
            onClick={async () => {
              try {
                const result = await createPostRequirement({
                  variables: {
                    type: selectedType,
                    requirement: requirementText,
                    emoji: emoji,
                  },
                });
                console.log(result);
                const newRequirement = result?.data?.createPostRequirement;
                handleNewRequirement(newRequirement);
                setRequirementText('');
                setEmoji(null);
                setSelectedTypeIndex(0);
              } catch (err) {
                console.log(err);
              }
            }}
          >
            Create
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};

export const TypeBadge = ({ type }: { type: string }) => {
  switch (type) {
    case 'CONTENT':
      return <FlowbiteBadge color="blue">{type}</FlowbiteBadge>;
    case 'SOUND':
      return <FlowbiteBadge color="green">{type}</FlowbiteBadge>;
    case 'MENTION':
      return <FlowbiteBadge color="purple">{type}</FlowbiteBadge>;
    case 'HASHTAG':
      return <FlowbiteBadge color="yellow">{type}</FlowbiteBadge>;
    default:
      return <FlowbiteBadge color="zinc">{type}</FlowbiteBadge>;
  }
};

export const requirementForMention = (
  referenceBrand: Brand,
  externalPlatform: string
) => {
  return (
    (externalPlatform === 'INSTAGRAM'
      ? 'Instagram: @' + referenceBrand?.socialHandle
      : 'TikTok: @' + referenceBrand?.tiktokSocialHandle) +
    ` (${referenceBrand?.name})`
  );
};

const emojiForMention = (externalPlatform: string) => {
  return externalPlatform === 'INSTAGRAM' ? <InstagramLogo /> : <TiktokLogo />;
};

export function RequirementsTableV2({
  requirements,
  showBrandDetails,
  brand,
  actions,
  onAddRequirement,
  onRemoveRequirement,
  onNewRequirement,
  onToggleBrandDefault,
  onAppliedCountClick,
}: {
  requirements: PostRequirement[];
  showBrandDetails: boolean;
  brand?: Brand;
  actions?: PostRequirementAction[];
  onAddRequirement: (postRequirement: PostRequirement) => void;
  onRemoveRequirement: (postRequirement: PostRequirement) => void;
  onNewRequirement: (postRequirement: PostRequirement) => void;
  onToggleBrandDefault?: (postRequirement: PostRequirement) => void;
  onAppliedCountClick?: (postRequirement: PostRequirement) => void;
}) {
  console.log('table', actions);
  return (
    <div>
      {/* Search */}
      <SearchRequirement
        text="Search existing requirements"
        onSearch={(query) => {
          // Handle search
        }}
        onAdd={(postRequirement: PostRequirement) => {
          if (onAddRequirement) {
            onAddRequirement(postRequirement);
          }
        }}
      />

      {/* Existing Requirements */}
      <div className="mx-4 mb-4">
        {requirements.length > 0 ? (
          <Table
            striped
            className="rounded-md border p-4"
          >
            <RequirementsTableHeader showBrandDetails={showBrandDetails} />
            <TableBody>
              <NewRequirement
                isBrandPage={showBrandDetails}
                handleNewRequirement={onNewRequirement}
              />
              {requirements.map((requirement) => (
                <RequirementTableRow
                  requirement={requirement}
                  showBrandDetails={showBrandDetails}
                  brand={brand}
                  actions={actions}
                  onAdd={onAddRequirement}
                  onRemove={onRemoveRequirement}
                  onToggleBrandDefault={onToggleBrandDefault}
                  onAppliedCountClick={onAppliedCountClick}
                />
              ))}
            </TableBody>
          </Table>
        ) : (
          <EmptyDataState message="No requirements found." />
        )}
      </div>
    </div>
  );
}

function RequirementsTableHeader({
  showBrandDetails,
}: {
  showBrandDetails: boolean;
}) {
  return (
    <TableHead>
      <TableRow>
        <TableHeader>Type</TableHeader>
        <TableHeader>Emoji</TableHeader>
        <TableHeader>Requirement</TableHeader>
        {showBrandDetails && (
          <>
            <TableHeader>Applies/Total</TableHeader>
            <Tooltip
              content={
                'If yes, the requirement will automatically get applied to every new challenge for the brand.'
              }
            >
              <TableHeader>Brand-Level Requirement</TableHeader>
            </Tooltip>
          </>
        )}
        <TableHeader>Actions</TableHeader>
      </TableRow>
    </TableHead>
  );
}

function RequirementTableRow({
  requirement,
  showBrandDetails,
  brand,
  actions,
  onAdd,
  onRemove,
  onToggleBrandDefault,
  onAppliedCountClick,
}: {
  requirement: PostRequirement;
  showBrandDetails: boolean;
  brand?: Brand;
  actions: PostRequirementAction[];
  onAdd: (postRequirement: PostRequirement) => void;
  onRemove: (postRequirement: PostRequirement) => void;
  onToggleBrandDefault?: (postRequirement: PostRequirement) => void;
  onAppliedCountClick?: (postRequirement: PostRequirement) => void;
}) {
  const [isEditView, setIsEditView] = useState(false);

  return isEditView ? (
    <EditRequirement
      postRequirement={requirement}
      handleEditRequirement={() => setIsEditView(false)}
      isBrandPage={showBrandDetails}
    />
  ) : (
    <TableRow key={requirement.id}>
      {/* Type */}
      <TableCell>
        <div className="w-fit">
          <TypeBadge type={requirement.type} />
        </div>
      </TableCell>

      {/* Emoji */}
      <TableCell>
        {requirement.type == 'MENTION'
          ? emojiForMention(requirement.externalPlatform)
          : requirement.emoji}
      </TableCell>

      {/* Requirement */}
      <TableCell>
        <div className="flex items-center space-x-2 text-wrap">
          <Text>
            {requirement.type == 'MENTION'
              ? requirementForMention(
                  requirement.referenceBrand,
                  requirement.externalPlatform
                )
              : requirement.requirement}
          </Text>
          {requirement.isDefault && (
            <Tooltip content={'Kale default requirement'}>
              <div className="flex h-8 w-8 items-center justify-center rounded-full bg-kale-mint-400">
                <img
                  className="h-5 w-5"
                  src={KaleLogo}
                  alt="Kale"
                />
              </div>
            </Tooltip>
          )}
        </div>
      </TableCell>

      {showBrandDetails && (
        <>
          {/* Applies/Total */}
          <TableCell>
            <Badge
              color={
                requirement.mappedActiveChallengesCount <
                brand?.activeChallenges?.length
                  ? 'fuchsia'
                  : 'emerald'
              }
            >
              {requirement.mappedActiveChallengesCount}/
              {brand?.activeChallenges?.length}
            </Badge>
          </TableCell>

          {/* Brand Default */}
          <TableCell>
            <div className="flex items-center space-x-2">
              <Badge color={requirement.isMappedToBrand ? 'emerald' : 'red'}>
                {requirement.isMappedToBrand ? 'YES' : 'NO'}
              </Badge>
              <Button
                outline
                className="hover:cursor-pointer"
                onClick={() => onToggleBrandDefault(requirement)}
              >
                <ArrowPathIcon />
              </Button>
            </div>
          </TableCell>
        </>
      )}
      <TableCell>
        {/* Actions */}
        <Dropdown>
          <DropdownButton
            plain
            aria-label="More options"
            className="mx-auto flex hover:cursor-pointer"
            onClick={(e) => {
              if (showBrandDetails) {
                e.preventDefault();
                onAppliedCountClick(requirement);
              }
            }}
          >
            <EllipsisHorizontalIcon className="h-5 w-5 text-black" />
          </DropdownButton>
          <DropdownMenu>
            {actions.map((action) => {
              switch (action) {
                case PostRequirementAction.REMOVE_FROM_CHALLENGE:
                  return (
                    <DropdownItem
                      key={action}
                      className="hover:cursor-pointer"
                      onClick={() => {
                        onRemove(requirement);
                      }}
                    >
                      Remove from challenge
                    </DropdownItem>
                  );

                case PostRequirementAction.EDIT:
                  return (
                    <DropdownItem
                      key={action}
                      className="hover:cursor-pointer"
                      onClick={() => {
                        setIsEditView(true);
                      }}
                    >
                      Edit
                    </DropdownItem>
                  );
              }
            })}
          </DropdownMenu>
        </Dropdown>
      </TableCell>
    </TableRow>
  );
}

const EditRequirement = ({
  postRequirement,
  handleEditRequirement,
  isBrandPage,
}: {
  postRequirement: PostRequirement;
  handleEditRequirement: () => void;
  isBrandPage: boolean;
}) => {
  const [updatePostRequirement] = useMutation(UPDATE_POST_REQUIREMENT, {
    refetchQueries: [POST_REQUIREMENTS],
  });

  const [isEmojiOpen, setIsEmojiOpen] = useState(false);
  const [emoji, setEmoji] = useState(postRequirement.emoji);

  const [requirementText, setRequirementText] = useState(
    postRequirement.requirement
  );

  return (
    <TableRow>
      <TableCell className="w-24">
        <div className="w-fit">
          <TypeBadge type={postRequirement.type} />
        </div>
      </TableCell>
      <TableCell className="whitespace-nowrap py-4 pl-3 text-sm">
        <div className="flex space-x-2">
          {postRequirement.type != 'HASHTAG' ? (
            <div>
              <button
                type="button"
                onClick={() => {
                  setIsEmojiOpen(!isEmojiOpen);
                }}
              >
                {emoji ? (
                  <p className="text-lg">{emoji}</p>
                ) : (
                  <FaceSmileIcon className="h-5 w-5" />
                )}
              </button>
            </div>
          ) : null}

          {isEmojiOpen && (
            <div className="absolute z-10">
              <Picker
                data={data}
                onEmojiSelect={(emoji) => {
                  setEmoji(emoji.native);
                  setIsEmojiOpen(false);
                }}
              />
            </div>
          )}
        </div>
      </TableCell>
      <TableCell className="whitespace-nowrap text-sm">
        <Input
          type="text"
          name="requirement"
          id="requirement"
          placeholder="Requirement"
          value={requirementText}
          onChange={(e) => setRequirementText(e.target.value)}
        />
      </TableCell>
      {isBrandPage && (
        <>
          <TableCell />
          <TableCell />
        </>
      )}
      <TableCell className="whitespace-nowrap text-sm font-medium">
        <div className="flex space-x-2 text-right">
          <Button
            color="indigo"
            disabled={
              requirementText?.length == 0 ||
              (postRequirement.type == 'HASHTAG' &&
                !requirementText.startsWith('#'))
            }
            onClick={async () => {
              try {
                const result = await updatePostRequirement({
                  variables: {
                    postRequirementId: postRequirement.id,
                    requirement: requirementText,
                    emoji: emoji,
                  },
                });
                console.log(result);
                handleEditRequirement();
              } catch (err) {
                console.log(err);
              }
            }}
          >
            Update
          </Button>
        </div>
      </TableCell>
    </TableRow>
  );
};
