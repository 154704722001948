import { useLazyQuery } from "@apollo/client";
import { Deal } from "@kalecard/common";
import { useEffect, useState } from "react";
import { ActiveDealsQuery, ACTIVE_DEALS, DEAL, DealQuery } from "../../graphql/queries";
import DealHeaderItem from "./DealHeaderItem";

export default function DealHeader({ userId, dealId }: { userId: string, dealId?: string }) {
  const [deals, setDeals] = useState<Deal[]>([]);

  const loadData = (data: ActiveDealsQuery) => {
    const newDeals = data.activeDeals.edges.map((edge) => edge.node);
    setDeals(newDeals);
  };

  const loadDealData = (data: DealQuery) => {
    setDeals([data.deal]);
  };

  const [dealQuery] = useLazyQuery<DealQuery>(DEAL, {
    onCompleted: (data) => loadDealData(data),
    variables: {
      dealId,
    },
  });

  const [activeDealsQuery] = useLazyQuery<ActiveDealsQuery>(ACTIVE_DEALS, {
    onCompleted: (data) => loadData(data),
    variables: {
      userId,
    },
  });

  useEffect(() => {
    if (dealId) {
      dealQuery();
    } else {
      activeDealsQuery();
    }
  }, [dealId]);

  return (
    <>
      {deals.map((deal) => {
        return (
          <DealHeaderItem key={deal.id} deal={deal} />
        );
      })}
    </>
  );
}