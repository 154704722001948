import { ReactNode } from 'react';
export const ToolTip = ({
  message,
  children,
}: {
  message: string;
  children: ReactNode;
}) => {
  return (
    <div className="group relative flex flex-col items-center">
      {children}
      <div className="absolute bottom-0 mb-8 flex hidden w-60 flex-col  items-center group-hover:flex">
        <span className="relative z-10 whitespace-pre-wrap rounded-md bg-gray-600 p-2 text-xs  leading-none text-white shadow-lg">
          {message}
        </span>
        <div className="-mt-2 h-3 w-3 rotate-45 bg-gray-600"></div>
      </div>
    </div>
  );
};
