import { useMutation } from "@apollo/client";
import { Spinner } from "@kalecard/common";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ADD_NEW_SOCIAL_HANDLE } from "../../../graphql/mutations";

interface NewSocialHandleInputs {
  socialHandle: string;
  externalPlatform: string;
}

export default function AddNewSocialHandle({ creatorId }) {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [addNewSocialHandle] = useMutation(ADD_NEW_SOCIAL_HANDLE);
  const { reset, register, handleSubmit, watch } =
    useForm<NewSocialHandleInputs>();
  const onSubmit = async (data: NewSocialHandleInputs) => {
    setIsLoading(true);
    console.log(data);
    await addNewSocialHandle({
      variables: {
        creatorId: creatorId,
        socialHandle: data.socialHandle,
        externalPlatform: data.externalPlatform,
      },
    });
    reset();
    setIsLoading(false);
  };
  return (
    <form
      className="flex space-x-4 rounded-lg border p-4"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex w-full space-x-6">
        <div className="w-1/2">
          <label
            htmlFor="socialHandle"
            className="block text-sm font-medium text-gray-700"
          >
            Handle
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              type="text"
              {...register("socialHandle", { required: true, minLength: 3 })}
              name="socialHandle"
              id="socialHandle"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            />
          </div>
        </div>
        <label
          htmlFor="subtitle"
          className="mt-4 block text-sm font-medium text-gray-700"
        >
          Platform
        </label>
        <div className="mt-4 space-y-4">
          <div className="flex items-center">
            <input
              id="TIKTOK"
              name="externalPlatforms"
              type="radio"
              defaultChecked={true}
              value={"TIKTOK"}
              {...register("externalPlatform")}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label
              htmlFor="TIKTOK"
              className="ml-3 block text-sm font-medium text-gray-700"
            >
              TikTok
            </label>
          </div>
          <div className="flex items-center">
            <input
              id="INSTAGRAM"
              name="externalPlatforms"
              type="radio"
              value={"INSTAGRAM"}
              {...register("externalPlatform")}
              className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-500"
            />
            <label
              htmlFor="INSTAGRAM"
              className="ml-3 block text-sm font-medium text-gray-700"
            >
              Instagram
            </label>
          </div>
        </div>
      </div>
      <div className="pt-5">
        <div className="flex items-center justify-end">
          {isLoading && <Spinner size={"h-6 w-6"} />}
          <button
            disabled={isLoading}
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-300"
          >
            Submit
          </button>
        </div>
      </div>
    </form>
  );
}