import { BuildingStorefrontIcon } from "@heroicons/react/24/solid";
import { Dropdown, OptionInterface } from "@kalecard/common";
import { useEffect, useState } from "react";
import { SIMPLE_BRANDS } from "../../graphql/queries";
import { getAllBrands } from "../../utils/brands";

export default function BrandsDropdown({ brandId, setBrandId, setBrand, includeDefaultOption }: { brandId: string, setBrandId?: (brandId: string) => void, setBrand?: (brand: OptionInterface) => void, includeDefaultOption?: boolean }) {
  const [brandOptions, setBrandOptions] = useState<OptionInterface[]>(includeDefaultOption ? [{ id: "-1", name: "", imageUrl: null, hidden: true }] : []);
  const brandClicked = (brandOption: OptionInterface) => {
    setBrandId(brandOption.id);
    setBrand(brandOption);
  }

  useEffect(() => {
    getAllBrands(SIMPLE_BRANDS).then((brands) => {
      setBrandOptions([...brandOptions, 
        ...(brands.map((brand) => {
          return {
            id: brand.id,
            name: brand.name,
            imageUrl: brand.logoUrl,
          };
        }))]
      );
    });
  }, []);

  return (
    <>
      <Dropdown
        options={brandOptions}
        activeOptionId={brandId}
        optionClicked={brandClicked}
        label={"Brand:"}
        placeholderImage={
          <BuildingStorefrontIcon className="h-6 w-6 flex-shrink-0 rounded-full" />
        }
      />
    </>
  );
}
