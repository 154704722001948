import { KaleAdmin, Spinner } from "@kalecard/common";
import { ADMINS } from "../../graphql/queries";
import { UPDATE_ADMIN } from "../../graphql/mutations";
import { useMutation } from "@apollo/client";
import { SubmitHandler, useForm } from "react-hook-form";
import { useState } from "react";
import { Strong, Text } from "../catalyst/text";
import { Button } from "../catalyst/button";

interface RemoveAdminInput {}

export default function RemoveAdminForm({ admin, onSubmit } : { admin: KaleAdmin, onSubmit: () => void; }) {
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [error, setError] = useState(null);

  const [updateAdmin] = useMutation(UPDATE_ADMIN, {
    refetchQueries: [ADMINS]
  });

  const { handleSubmit } = useForm<RemoveAdminInput>();
  
  const submit: SubmitHandler<RemoveAdminInput> = async () => {
    setIsSaveLoading(true);
    try {
      await updateAdmin({
        variables: {
          adminId: admin.id,
          name: admin.name,
          role: "INACTIVE_ADMIN",
          permissions: []
        },
      });
      onSubmit();
    } catch (err) {
      console.log(err);
      setError("Failed to update this admin. Try again.");
    }
    setIsSaveLoading(false);
  };

  return (
    <form className="space-y-4 px-8 py-4">
      <Text>
        Are you sure you want to remove <Strong>{admin.name}</Strong>?
      </Text>

      <div className="flex justify-between">
        <Button color="indigo" className="hover:cursor-pointer" onClick={handleSubmit(submit)}>Yes, Remove</Button>
        {isSaveLoading && (
          <div className="flex flex-wrap content-center justify-center">
          <Spinner size="h-6 w-6" />
        </div>
        )}
        <Button color="red" className="hover:cursor-pointer" onClick={onSubmit}>Cancel</Button>
      </div>

      {error && <p className="text-sm font-medium text-red-500">{error}</p>}
    </form>
  );
}