import React from "react";
import { Checkbox } from "./catalyst/checkbox";
import { Label } from "./catalyst/fieldset";
import * as Headless from "@headlessui/react";
import { AS, CA, GU, MP, PR, UM, US, VI } from "country-flag-icons/react/3x2";

const defaultCountryCodes = {
  US: {
    title: "United States",
    component: (
      <US
        height={18}
        width={18}
        title="United States"
        className="overflow-clip rounded-md"
      />
    ),
  },
  CA: {
    title: "Canada",
    component: (
      <CA
        height={18}
        width={18}
        title="Canada"
        className="overflow-clip rounded-md"
      />
    ),
  },
  AS: {
    title: "American Samoa",
    component: (
      <AS
        height={18}
        width={18}
        title="American Samoa"
        className="overflow-clip rounded-md"
      />
    ),
  },
  GU: {
    title: "Guam",
    component: (
      <GU
        height={18}
        width={18}
        title="Guam"
        className="overflow-clip rounded-md"
      />
    ),
  },
  MP: {
    title: "Northern Mariana Islands",
    component: (
      <MP
        height={18}
        width={18}
        title="Northern Mariana Islands"
        className="overflow-clip rounded-md"
      />
    ),
  },
  PR: {
    title: "Puerto Rico",
    component: (
      <PR
        height={18}
        width={18}
        title="Puerto Rico"
        className="overflow-clip rounded-md"
      />
    ),
  },
  UM: {
    title: "US Minor Outlying Islands",
    component: (
      <UM
        height={18}
        width={18}
        title="United States Minor Outlying Islands"
        className="overflow-clip rounded-md"
      />
    ),
  },
  VI: {
    title: "Virgin Islands",
    component: (
      <VI
        height={18}
        width={18}
        title="Virgin Islands"
        className="overflow-clip rounded-md"
      />
    ),
  },
};

interface CountryPickerProps {
  selectedCountries: string[];
  setSelectedCountries: (countries: string[]) => void;
}

export default function CountryPicker(props: CountryPickerProps) {
  const { selectedCountries, setSelectedCountries } = props;

  return (
    <div className="grid grid-cols-2 gap-4 gap-y-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
      {Object.keys(defaultCountryCodes)
        .sort((a, b) =>
          defaultCountryCodes[a].title.localeCompare(
            defaultCountryCodes[b].title
          )
        )
        .map((code) => (
          <Headless.Field className="flex max-h-6 items-center gap-x-2">
            <Checkbox
              color="green"
              className={"mr-1"}
              checked={selectedCountries.includes(code)}
              onChange={(checked) => {
                if (checked) {
                  setSelectedCountries([...selectedCountries, code]);
                } else {
                  setSelectedCountries(
                    selectedCountries.filter((country) => country !== code)
                  );
                }
              }}
            />
            {defaultCountryCodes[code].component}
            <Label className={"text-md font-medium"}>
              {defaultCountryCodes[code].title}
            </Label>
          </Headless.Field>
        ))}
    </div>
  );
}
