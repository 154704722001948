import { Badge } from "flowbite-react";
import { XMarkIcon } from "@heroicons/react/24/outline";

export function FormattedRole({ role, showRemove, onRemove }: { role: string, showRemove?: boolean, onRemove?: (role) => void; }) {
  const color = () => {
    switch (role) {
      case "OWNER":
        return "indigo";
      case "ADMIN":
        return "lime";
      case "CONTRIBUTOR":
        return "cyan";
    }
  }

  return <Badge className="w-fit capitalize hover:cursor-pointer" color={color()} onClick={() => onRemove ? onRemove(role) : null}>
    <div className="flex space-x-2 items-center">
    <p className="text-sm">{role.toLowerCase()}</p>
    {showRemove && (
      <XMarkIcon className="h-4 w-4"/>
    )}
  </div>
  </Badge>
}