import CreatorLinkedAccounts from "./CreatorLinkedAccounts";
import CreatorStrikeHistory from "./CreatorStrikeHistory";

export default function CreatorHistory({ creator }) {
  return (
    <div className="mt-4 space-y-6 sm:space-y-5">
      <CreatorStrikeHistory creatorId={creator.id} />
      <CreatorLinkedAccounts creatorId={creator.id} />
    </div>
  );
}
