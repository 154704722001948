import { Loading } from "@kalecard/common";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { UploadRequestMetadata, upload } from "../../utils/upload";
import MediaComponent from "../MediaComponent";
import { ArrowPathIcon } from "@heroicons/react/24/solid";
import { useMutation } from "@apollo/client";
import { DOWNLOAD_MEDIA } from "../../graphql/mutations";

interface FormInput {
  mediaFiles?: File[];
}

export default function UploadMedia({ initialUrl, initialMediaType, uploadType, metadata }: { initialUrl?: string, initialMediaType?: string, uploadType: string, metadata?: UploadRequestMetadata }) {
  const [url, setUrl] = useState<string>(initialUrl);
  const [mediaType, setMediaType] = useState<string>(initialMediaType);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { register, watch } = useForm<FormInput>({
    defaultValues: {},
  });

  useEffect(() => {
    const subscription = watch(async (value, { name, type }) => {
      if (name === "mediaFiles") {
        if (
          FileReader && value.mediaFiles.length > 0
            ? value.mediaFiles[0].name
            : null
        ) {
          setIsLoading(true);
          setUrl(null);
          var fr = new FileReader();
          fr.readAsDataURL(value.mediaFiles[0]);
          var videoUploadResult = null;
          if (value.mediaFiles.length > 0) {
            videoUploadResult = await upload(
              value.mediaFiles[0],
              uploadType,
              metadata,
              () => {
                setIsLoading(false);
                setUrl(String(fr.result));
                setMediaType(value.mediaFiles[0].type.split("/")[0].toUpperCase());
              }
            );

          }
        }
      } else {

      }
    });
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return (
    <>
      <div className="flex flex-col items-center justify-center">
        <div
          className={`flex h-[473px] w-[266px] items-center justify-center ${!url && "rounded-md border border-dashed"
            }`}
        >
          {url ? (
            <MediaComponent url={url} mediaType={mediaType} />
          ) : isLoading ? (
            <div className="flex flex-col">
              <Loading />
              <p>Uploading...</p>
            </div>
          ) : (
            <>
              <label className="cursor-pointer rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                <span className="text-base leading-normal">Select media</span>
                <input
                  className="hidden"
                  {...register("mediaFiles")}
                  accept="video/*,image/*"
                  type="file"
                  name="mediaFiles"
                  disabled={isLoading}
                />
              </label>
            </>
          )}
        </div>
        {url ? (
          <div className="w-full flex justify-between items-center">
            <label className="cursor-pointer w-full rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
              <p className="text-base w-full text-center leading-normal">Select media</p>
              <input
                className="hidden"
                {...register("mediaFiles")}
                accept="video/*,image/*"
                type="file"
                name="mediaFiles"
                disabled={isLoading}
              />
            </label>
            <RetryDownload metadata={metadata} setUrl={setUrl} setMediaType={setMediaType} />
          </div>
        ) : (
          <div className="pt-2 w-full">
            <RetryDownload metadata={metadata} setUrl={setUrl} setMediaType={setMediaType} />
          </div>
        )}
      </div>
    </>
  );
}

function RetryDownload({
  metadata,
  setUrl,
  setMediaType,
}: {
  metadata: UploadRequestMetadata;
  setUrl: (url: string) => void;
  setMediaType: (mediaType: string) => void;
}) {
  const [downloadMedia, { loading }] = useMutation(DOWNLOAD_MEDIA);
  const postId = metadata.data.find((d) => d.key === "postId")?.value;
  if (postId === undefined) {
    return null;
  }

  return (
    <div className="flex w-full items-end justify-end">
      <button className="border rounded p-2"
        disabled={loading}
        onClick={() => {
          downloadMedia({
            variables: {
              postId: postId,
            },
          }).then((res) => {
            console.log(res.data.downloadMedia);
            if (res.data.downloadMedia !== null) {
              setUrl(res.data.downloadMedia);
              setMediaType("VIDEO");
            }
          });
        }}>
        <ArrowPathIcon className={`${loading ? "animate-spin text-gray-400" : "text-gray-800"} h-5 w-5 hover:text-gray-400`} />
      </button>
    </div>
  )
}

