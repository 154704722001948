import { useLazyQuery } from "@apollo/client";
import { Creator, Spinner } from "@kalecard/common";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { CREATOR_LOOKUP } from "../graphql/queries";
import CreatorsList from "../components/lists/CreatorsList";

interface CreatorLookupInputs {
  userId?: string;
  email?: string;
  phoneNumber?: string;
  socialUsername?: string;
}

export default function CreatorsPage() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);
  const [creators, setCreators] = useState<Creator[]>([]);
  const [creatorLookup] = useLazyQuery(CREATOR_LOOKUP);
  const { reset, register, handleSubmit } = useForm<CreatorLookupInputs>({
    defaultValues: {
      userId: null,
      email: null,
      phoneNumber: null,
      socialUsername: null,
    },
  });

  const onSubmit: SubmitHandler<CreatorLookupInputs> = async (data) => {
    setIsLoading(true);
    const variables = {
      userId: data.userId,
      email: data.email,
      phoneNumber: data.phoneNumber,
      socialUsername: data.socialUsername,
    };
    try {
      const result = await creatorLookup({ variables });
      if (result.data) {
        // @ts-ignore
        setCreators(result.data.creatorLookup);
        setError(null);
        reset();
      } else {
        // Set an error
        console.error("Error creating deal");
        setError(
          "Something went wrong, try refreshing this page or try again later."
        );
      }
    } catch (err) {
      console.error(err);
      setError(`Error: ${err.message}`);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="my-4 px-4 sm:px-6 lg:px-8">
        <h1 className="text-xl font-semibold text-gray-900">Creator Lookup</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-6">
            <div className="sm:col-span-3">
              <label
                htmlFor="userId"
                className="block text-sm font-medium text-gray-700"
              >
                User ID
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="userId"
                  {...register("userId")}
                  id="userId"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  name="email"
                  {...register("email")}
                  id="email"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="phoneNumber"
                className="block text-sm font-medium text-gray-700"
              >
                Phone number
              </label>
              <div className="mt-1">
                <input
                  type="tel"
                  name="phoneNumber"
                  {...register("phoneNumber")}
                  id="phoneNumber"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
                />
              </div>
            </div>

            <div className="sm:col-span-3">
              <label
                htmlFor="socialUsername"
                className="block text-sm font-medium text-gray-700"
              >
                Social handle
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  name="socialUsername"
                  {...register("socialUsername")}
                  id="socialUsername"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 disabled:bg-gray-200 sm:text-sm"
                />
              </div>
            </div>
          </div>

          <div className="flex justify-end pt-5">
            <div className="flex justify-end">
              <button
                type="submit"
                disabled={isLoading}
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-400"
              >
                Search
              </button>
            </div>
          </div>
          <div className="mt-2 flex justify-end">
            <p className="font-bold text-red-500">{error}</p>
          </div>
        </form>
      </div>
      <CreatorsList creators={creators} />
      {isLoading && (
        <div className="flex flex-wrap content-center justify-center">
          <Spinner size="h-6 w-6" />
        </div>
      )}
    </>
  );
}
