import { useMutation } from "@apollo/client";
import { XMarkIcon } from "@heroicons/react/24/solid";
import { REMOVE_POST_REWARD_CATEGORY } from "../../graphql/mutations";
import { useEffect } from "react";

export default function PostRewardCategory({
  postId,
  rewardCategory,
  setPostRewardCategory,
}: {
  postId: string;
  rewardCategory: string;
  setPostRewardCategory?: React.Dispatch<React.SetStateAction<string>>;
}) {
  const [removePostRewardCategory] = useMutation(REMOVE_POST_REWARD_CATEGORY);

  useEffect(() => {}, [rewardCategory]);

  const removeButton = setPostRewardCategory && (
    <button
      type="button"
      onClick={async () => {
        await removePostRewardCategory({ variables: { postId } });
        setPostRewardCategory(null);
      }}
      className="ml-1 inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full text-black hover:bg-gray-300"
    >
      <span className="sr-only">Remove</span>
      <XMarkIcon className="p-1" />
    </button>
  );

  switch (rewardCategory) {
    case "IMPRESSIONS_ONLY":
      return (
        <div className="inline-flex items-center rounded-xl bg-yellow-100 px-2.5 py-0.5 text-sm font-medium text-yellow-800">
          <p>Impressions Only</p>
          {removeButton}
        </div>
      );
    case "INELIGIBLE":
      return (
        <div className="inline-flex items-center rounded-xl bg-red-100 px-2.5 py-0.5 text-sm font-medium text-red-800">
          <p>Ineligible</p>
          {removeButton}
        </div>
      );
    default:
      return <></>;
  }
}
