import { BonsaiQueue } from "../BonsaiModels";
import {
  BonsaiContextLinkType,
  BonsaiOutcome,
  BonsaiPanel,
  BonsaiVideoPanel,
  OutcomeControls,
} from "@kalecard/common";
import React, { useState } from "react";
import { BonsaiActionPanel } from "../BonsaiContainer";

interface ValidateContentModerationOutput {
  outcome: BonsaiOutcome;
}

interface ContentModerationLabel {
  label: string;
  confidence: number;
  startsAtMillis: number;
  endsAtMillis: number;
  subLabels: [String];
}

interface ValidateContentModerationToolInput {
  videoSource: string;
  creatorId: string;
  postId: number;
  audioLabels: [ContentModerationLabel];
  videoLabels: [ContentModerationLabel];
}

interface ValidateContentModerationToolProps {
  queue: BonsaiQueue;
  onComplete: (outcome: ValidateContentModerationOutput) => void;
  readOnly: boolean;
  taskId: number;
  input: ValidateContentModerationToolInput;
}

export function ValidateContentModerationTool(
  props: ValidateContentModerationToolProps
) {
  const [selectedOutcome, setSelectedOutcome] =
    useState<BonsaiOutcome>(undefined);
  const [selectedTime, setSelectedTime] = useState<number>(0);

  const links = [
    {
      type: BonsaiContextLinkType.CREATOR,
      url: `/creators/${props.input.creatorId}/messages`,
    },
  ];

  return (
    <>
      <div className="flex flex-row gap-4">
        <div className={"flex-1"}>
          <BonsaiPanel>
            <div className="flex h-full flex-col justify-between p-4">
              <div className="flex-row justify-center space-y-4">
                <div className="text-md text-center font-semibold text-slate-700">
                  Video Labels
                </div>
                {props.input.videoLabels.map((label, index) => (
                  <Label
                    key={index}
                    startsAtMillis={label.startsAtMillis}
                    endsAtMillis={label.endsAtMillis}
                    confidence={label.confidence}
                    label={label.label}
                    subLabels={label.subLabels}
                    seekToTime={setSelectedTime}
                  />
                ))}
                <div className="text-md text-center font-semibold text-slate-700">
                  Audio Labels
                </div>
                {props.input.audioLabels.map((label, index) => (
                  <Label
                    key={index}
                    startsAtMillis={label.startsAtMillis}
                    endsAtMillis={label.endsAtMillis}
                    confidence={label.confidence}
                    label={label.label}
                    subLabels={label.subLabels}
                    seekToTime={setSelectedTime}
                  />
                ))}
              </div>
            </div>
          </BonsaiPanel>
        </div>
        <div className={"flex-1"}>
          <BonsaiVideoPanel
            source={props.input.videoSource}
            links={links}
            autoPlay={false}
            seekTime={selectedTime}
          />
        </div>
      </div>
      <BonsaiActionPanel
        submitDisabled={selectedOutcome === undefined}
        onSubmit={() => {
          props.onComplete({ outcome: selectedOutcome });
          setSelectedOutcome(undefined);
        }}
        taskDescription={props.queue.taskDescription}
        taskId={props.taskId}
      >
        <OutcomeControls
          onSelected={(outcome) => {
            setSelectedOutcome(outcome);
          }}
          selected={selectedOutcome}
        />
      </BonsaiActionPanel>
    </>
  );
}

const Label = ({
  startsAtMillis,
  endsAtMillis,
  confidence,
  label,
  subLabels,
  seekToTime,
}) => {
  const startsAtSeconds = (startsAtMillis / 1000).toFixed(1);
  const endsAtSeconds = (endsAtMillis / 1000).toFixed(1);

  const handleSeekToStart = () => {
    seekToTime(startsAtMillis / 1000);
  };

  return (
    <div className="mb-3 rounded-md border bg-white p-3">
      <div className="flex justify-between text-sm font-bold text-slate-900">
        <span
          onClick={handleSeekToStart}
          className="cursor-pointer text-kale-green-500 underline"
        >
          {startsAtSeconds}s{endsAtMillis && ` - ${endsAtSeconds}s`}
        </span>
        <span className="ml-auto">Confidence: {confidence}</span>
      </div>
      <div className="mt-1 text-sm text-slate-800">{label}</div>
      <br />
      {subLabels.map((subLabel, index) => (
        <div key={index} className="text-sm text-slate-800">
          {subLabel}
        </div>
      ))}
    </div>
  );
};
