import BrandRow from './BrandRow';
import {
  Table,
  TableBody,
  TableHead,
  TableHeader,
  TableRow,
} from '../catalyst/table';
import { Brand } from '../../__generated__/graphql';

export default function BrandsTable({
  isKaleBrand,
  brands,
}: {
  isKaleBrand: boolean;
  brands: Brand[];
}) {
  return (
    <Table className="rounded-md border p-4">
      <TableHead>
        <TableRow>
          <TableHeader>Name</TableHeader>
          {isKaleBrand && (
            <>
              <TableHeader>$$$</TableHeader>
              <TableHeader>Settings</TableHeader>
            </>
          )}
          <TableHeader></TableHeader>
        </TableRow>
      </TableHead>
      <TableBody>
        {brands.map((brand) => (
          <BrandRow
            key={brand.id}
            brand={brand as Brand}
            isKaleBrand={isKaleBrand}
          />
        ))}
      </TableBody>
    </Table>
  );
}
