interface RenderChallengeStateProps {
  state: string;
  source?: string;
  setIsEditChallengeModalOpen?: (isOpen: boolean) => void;
}

export function ChallengeState({ state, source, setIsEditChallengeModalOpen }: RenderChallengeStateProps) {
  switch (state) {
    case "ACTIVE":
      return (
        <span className="inline-flex rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800">
          Active
        </span>
      );
    case "INACTIVE":
      return (
        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
          Inactive
        </span>
      );

    case "REJECTED":
      return source === "BRAND_ADMIN" ? (
        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
          Rejected
        </span>
      ) : (
        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
          Rejected by Brand
        </span>
      );
    case "DELETED":
      return (
        <span className="inline-flex rounded-full bg-red-100 px-2 text-xs font-semibold leading-5 text-red-800">
          Deleted
        </span>
      );
    case "PENDING_REVIEW":
      return (
        <span
          className="inline-flex rounded-full bg-yellow-100 px-2 text-xs font-semibold leading-5 text-orange-800 hover:cursor-pointer"
          onClick={() => setIsEditChallengeModalOpen(true)}
        >
          Needs Review
        </span>
      );
    case "PENDING_BRAND_REVIEW":
      return (
        <span className="whitespace-wrap flex flex-col rounded-full bg-purple-100 px-4 text-center text-xs font-semibold leading-5 text-purple-800">
          <p>Pending Approval</p>
          <p>From Brand</p>
        </span>
      );
    default:
      return null;
  }
};
