import React, { useState } from "react";
import { Button } from "../catalyst/button";
import {
  CheckBadgeIcon,
  FlagIcon,
  InformationCircleIcon,
  EllipsisVerticalIcon,
  ShareIcon,
} from "@heroicons/react/16/solid";

import { QueueListIcon } from "@heroicons/react/20/solid";
import {
  Dropdown,
  DropdownButton,
  DropdownItem,
  DropdownLabel,
  DropdownMenu,
  DropdownSeparator,
} from "../catalyst/dropdown";
import { Dialog, DialogActions, DialogBody } from "../catalyst/dialog";
import Markdown from "react-markdown";
import { BonsaiQueue } from "./BonsaiModels";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";

interface RemainingTasksLabelProps {
  remainingTasks: number;
}

function RemainingTasksLabel(props: RemainingTasksLabelProps) {
  return (
    <div className="slant flex justify-center text-xl font-extrabold italic text-kale-green-500">
      {props.remainingTasks}{" "}
      {props.remainingTasks === 1 ? "Task Remaining" : "Tasks Remaining"}
    </div>
  );
}

interface BonsaiHeaderProps {
  setQueue: (queue: BonsaiQueue) => void;
  remainingTasks?: number;
  backDisabled: boolean;
}

export function BonsaiHeader(props: BonsaiHeaderProps) {
  return (
    <div className="flex flex-row justify-between align-middle">
      {!props.backDisabled && (
        <div
          className="text-md flex items-center space-x-2 hover:cursor-pointer hover:text-kale-green-500"
          onClick={() => props.setQueue(null)}
        >
          <ChevronLeftIcon className="h-4 w-4" />
          <p className="font-medium">Back</p>
        </div>
      )}
      {props.remainingTasks !== undefined && (
        <div className="flex justify-end">
          <RemainingTasksLabel remainingTasks={props.remainingTasks} />
        </div>
      )}
    </div>
  );
}

interface BonsaiTaskOptionsButtonProps {
  onDescriptionItemClick: () => void;
  onShareItemClick: () => void;
}

function BonsaiTaskOptionsButton(props: BonsaiTaskOptionsButtonProps) {
  return (
    <Dropdown>
      <DropdownButton plain aria-label="More options">
        <EllipsisVerticalIcon className={"w-8"} />
      </DropdownButton>
      <DropdownMenu>
        <DropdownItem onClick={props.onDescriptionItemClick}>
          <InformationCircleIcon />
          <DropdownLabel>Task Description</DropdownLabel>
        </DropdownItem>
        <DropdownSeparator />
        <DropdownItem onClick={props.onShareItemClick}>
          <ShareIcon />
          <DropdownLabel>Copy Share Link</DropdownLabel>
        </DropdownItem>
        <DropdownItem disabled={true}>
          <FlagIcon />
          <DropdownLabel>Escalate</DropdownLabel>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

interface BonsaiTaskDescriptionDialogProps {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  markdownBody: string;
}

function BonsaiTaskDescriptionDialog(props: BonsaiTaskDescriptionDialogProps) {
  return (
    <Dialog open={props.isOpen} onClose={props.setIsOpen} size={"3xl"}>
      <DialogBody className={"prose prose-sm prose-green max-w-none"}>
        <Markdown>{props.markdownBody}</Markdown>
      </DialogBody>
      <DialogActions>
        <Button onClick={() => props.setIsOpen(false)}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}

interface BonsaiActionPanelProps {
  submitDisabled: boolean;
  onSubmit: () => void;
  taskDescription: string;
  taskId: number;
  children?: React.ReactNode;
}

export function BonsaiActionPanel(props: BonsaiActionPanelProps) {
  let [isTaskDescriptionOpen, setIsTaskDescriptionOpen] =
    useState<boolean>(false);

  return (
    <>
      <div className="space-ar mt-8 flex justify-between rounded-lg bg-white p-4 shadow-md ring-1 ring-kale-green-400/25">
        <div className="flex w-full flex-row justify-between">
          <div className={"flex flex-row gap-2"}>
            <BonsaiTaskOptionsButton
              onDescriptionItemClick={() => {
                setIsTaskDescriptionOpen(true);
              }}
              // copy link to clipboard
              onShareItemClick={() => {
                navigator.clipboard
                  .writeText(`${window.location.origin}/bonsai/${props.taskId}`)
                  .then((r) => console.log("copied link to clipboard"));
              }}
            />
            {props.children}
          </div>
          <Button
            color="green"
            onClick={props.onSubmit}
            disabled={props.submitDisabled}
          >
            Submit
          </Button>
        </div>
      </div>
      {isTaskDescriptionOpen && (
        <BonsaiTaskDescriptionDialog
          isOpen={isTaskDescriptionOpen}
          setIsOpen={setIsTaskDescriptionOpen}
          markdownBody={props.taskDescription}
        />
      )}
    </>
  );
}

export function BonsaiEmptyState() {
  return (
    <div className="-mt-96 text-center">
      <div className={"flex justify-center"}>
        <CheckBadgeIcon className={"h-20 w-20 text-green-500"} />
      </div>
      <h3 className="text-md mt-2 font-semibold text-gray-900">
        All Tasks Complete!
      </h3>
      <p className="text-md mt-1 text-gray-500">
        No more tasks left for this queue. Please check back later.
      </p>
    </div>
  );
}

export function BonsaiNoQueueSelectedState() {
  return (
    <div className="-mt-96 text-center">
      <div className={"flex justify-center"}>
        <QueueListIcon className={"h-20 w-20 text-green-500"} />
      </div>
      <h3 className="text-md mt-2 font-semibold text-gray-900">
        No Queue Selected
      </h3>
      <p className="text-md mt-1 text-gray-500">
        Please select a queue from the dropdown above to get started.
      </p>
    </div>
  );
}
