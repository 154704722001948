import { useMutation } from "@apollo/client";
import { Creator, Modal, Spinner } from "@kalecard/common";
import { useState } from "react";
import { UPDATE_CREATOR_ACCOUNT_STATE } from "../../graphql/mutations";
import BlockCreatorForm from "../forms/BlockCreatorForm";

interface CreatorAccountStatusUpdateInterface {
  creator: Creator
  requiresApproval: (val: boolean) => void;
}

export default function CreatorAccountStatusUpdate(props: CreatorAccountStatusUpdateInterface) {
  const [loading, setLoading] = useState(false);
  const [updateAccountState] = useMutation(UPDATE_CREATOR_ACCOUNT_STATE);
  const [isBlockCreatorModalOpen, setIsBlockCreatorModalOpen] = useState(false);

  const onUpdateAccountState = async (accountState: string) => {
    setLoading(true);
    await updateAccountState({
      variables: {
        creatorId: props.creator.id,
        accountState: accountState
      },
    });
    setLoading(false);
    props.requiresApproval(false);
  };

  const handleBlocked = () => {
    setIsBlockCreatorModalOpen(false);
    props.requiresApproval(false);
  }

  return (
    <div className="flex space-x-2">
      {loading && (
        <div className="flex items-center">
          <Spinner size="h-6 w-6" />
        </div>
      )}
      <div className="flex flex-col items-center space-y-5">
        <button
          className="text-sm rounded-md border border-transparent bg-kale-green-500 text-white py-2 px-4 hover:bg-kale-green-400"
          onClick={() => onUpdateAccountState("APPROVED")}>
          Approve
        </button>
        <button
          className="text-sm rounded-md border border-transparent bg-red-500 text-white py-2 px-4 hover:bg-red-400 w-full"
          onClick={() => setIsBlockCreatorModalOpen(true)}>
          Block
        </button>
      </div>
      <Modal
        isModalOpen={isBlockCreatorModalOpen}
        onClose={() => setIsBlockCreatorModalOpen(false)}
      >
        <div>
          <BlockCreatorForm
            creatorId={props.creator.id}
            blocked={handleBlocked}
          />
        </div>
      </Modal>
    </div>
  );
}