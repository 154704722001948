import AdminPermissionsTable from "../components/admins/AdminPermissionsTable";
import AdminsTable from "../components/admins/AdminsTable";
import { NavLink, Redirect, Route, Switch } from "react-router-dom";

export default function UserManagementPage() {
  const tabs = [
    { name: "Admins", href: `/userManagement/admins`, current: true },
    { name: "Permissions", href: `/userManagement/permissions`, current: false }
  ];

  return (
    <div className="my-4 px-4 sm:px-6 lg:px-8 space-y-6">

      {/* Header */}
      <div>
        <h1 className="text-xl font-semibold text-gray-900">User Management</h1>
        <p className="mt-2 text-sm text-gray-700">
          A view of all Kale admins and permissions.
        </p>
      </div>

      <div className="border-b border-gray-200">
        <div className="block">
          <nav className="-mb-px flex space-x-8">
            {tabs.map((tab) => (
              <NavLink
                key={tab.name}
                to={tab.href}
                className={(isActive) =>
                  (isActive
                    ? "border-b-2 border-black text-black "
                    : "text-gray-500 hover:border-b-2 hover:border-gray-500 hover:text-gray-600") +
                  "whitespace-nowrap pb-4 text-sm font-medium"
                }
                aria-current={tab.current ? "page" : undefined}
              >
                {tab.name}
              </NavLink>
            ))}
          </nav>
        </div>
      </div>
      <div>
        <Switch>
          <Route path="/userManagement/admins">
            <AdminsTable />
          </Route>
          <Route exact path="/userManagement/permissions">
            <AdminPermissionsTable />
          </Route>
          <Route path="/userManagement">
            <Redirect to="/userManagement/admins" />
          </Route>
        </Switch>
      </div>
    </div>
  );
}