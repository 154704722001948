import { useEffect, useState } from "react";

export const useEmojiPickerForInput = ({
  setValue,
  getValues,
  inputName,
  querySelector,
  pickerRef,
  inputRef,
  setIsEmojiOpen,
}) => {
  const [cursorPos, setCursorPos] = useState(null);

  const handleCursorChange = () => {
    const input = inputRef.current.querySelector(querySelector);
    if (input) {
      setCursorPos(input.selectionStart);
    }
  };

  const handleClickOutside = (event) => {
    if (
      pickerRef.current &&
      !pickerRef.current.contains(event.target) &&
      inputRef.current &&
      !inputRef.current.contains(event.target)
    ) {
      setIsEmojiOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addEmojiAtCursor = (emoji) => {
    const currentValue = getValues(inputName);
    const pos = cursorPos ?? currentValue.length;

    // Insert the newValue at the cursor position
    const newValue =
      currentValue.substring(0, pos) +
      emoji.native +
      currentValue.substring(pos);
    if (cursorPos != null) {
      setCursorPos(cursorPos + emoji.native.length);
    }

    setValue(inputName, newValue);
  };

  return {
    addEmojiAtCursor,
    handleCursorChange,
  };
};
