import { useMutation, useQuery } from "@apollo/client";
import { CREATOR_STRIKES } from "../../graphql/queries";
import { CreatorStrike } from "../../__generated__/graphql";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../catalyst/table";

import { Strong, Text } from "../catalyst/text";
import { UNDO_USER_STRIKE } from "../../graphql/mutations";
import { Button } from "../catalyst/button";
import {
  Dialog,
  DialogActions,
  DialogBody,
  DialogTitle,
} from "../catalyst/dialog";
import { useState } from "react";
import { Badge } from "../catalyst/badge";
import { TemporalDealWorkflowLink, IndigoLink } from "../Links";

export default function CreatorStrikeHistory({
  creatorId,
}: {
  creatorId: string;
}) {
  const { data, loading } = useQuery(CREATOR_STRIKES, {
    variables: { creatorId },
  });
  const [undoStrikeToUser] = useMutation(UNDO_USER_STRIKE, {
    refetchQueries: [{ query: CREATOR_STRIKES, variables: { creatorId } }],
  });
  const finalizeUndoStrike = (strike: CreatorStrike) =>
    undoStrikeToUser({
      variables: {
        creatorId,
        strikeId: strike.id,
      },
    });

  const [showUndoStrikeDialog, setShowUndoStrikeDialog] = useState(false);
  const [stagingUndoStrike, setStagingUndoStrike] =
    useState<CreatorStrike | null>();

  const openUndoDialog = (strike: CreatorStrike) => {
    setStagingUndoStrike(strike);
    setShowUndoStrikeDialog(true);
  };
  const closeUndoDialog = () => {
    setShowUndoStrikeDialog(false);
    setStagingUndoStrike(null);
  };

  return (
    <div className="flex flex-col rounded-lg border p-4">
      <h1>Creator Strike History</h1>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Table className="text-black">
          <TableHead>
            <TableRow className="text-black">
              <TableHeader>Type</TableHeader>
              <TableHeader>Details</TableHeader>
              <TableHeader>Links</TableHeader>
              <TableHeader>Actions</TableHeader>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.creatorStrikes.map((strike) => (
              <StrikeRow
                key={strike.id}
                strike={strike as CreatorStrike}
                onStrikeUndoClick={() =>
                  openUndoDialog(strike as CreatorStrike)
                }
              />
            ))}
          </TableBody>
        </Table>
      )}
      <Dialog size="lg" onClose={closeUndoDialog} open={showUndoStrikeDialog}>
        <DialogTitle>Are you sure you want to undo this strike?</DialogTitle>
        <div>
          {stagingUndoStrike ? (
            <DialogBody>
              <StrikeDetails strike={stagingUndoStrike} includeType />
              <Text className="mt-4">
                <Strong>
                  It might take a few minutes for the creator to be unblocked
                  after removing the strike
                </Strong>
              </Text>
            </DialogBody>
          ) : null}
          <DialogActions>
            <Button
              color="green"
              onClick={() => {
                finalizeUndoStrike(stagingUndoStrike);
                closeUndoDialog();
              }}
            >
              Confirm
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </div>
  );
}

const StrikeRow = ({
  strike,
  onStrikeUndoClick,
}: {
  strike: CreatorStrike;
  onStrikeUndoClick: () => void;
}) => (
  <TableRow className="text-black">
    {/* Type */}
    <TableCell>
      <Text>
        <Badge color="red">{strike.badActorType}</Badge>
      </Text>
    </TableCell>

    {/* Details */}
    <TableCell>
      <StrikeDetails strike={strike} />
    </TableCell>

    {/* Links */}
    <TableCell>
      {strike.deal ? (
        <Text>
          <Strong>Deal ID: </Strong>
          <TemporalDealWorkflowLink dealId={strike.deal.id} />
        </Text>
      ) : null}
      {strike.post ? (
        <Text>
          <Strong>Post Url: </Strong>
          <IndigoLink target="_blank" href={strike.post.externalPermalink}>
            Click Here
          </IndigoLink>
        </Text>
      ) : null}
    </TableCell>

    {/* Actions */}
    <TableCell>
      {strike.weight && strike.weight > 0 ? (
        <Button color="red" onClick={onStrikeUndoClick}>
          Undo Strike
        </Button>
      ) : (
        <div className="flex flex-col">
          <Text>
            <Strong>Undone: </Strong>
            {new Date(strike.undoneAt).toLocaleDateString()}
          </Text>
          <Text>
            <Strong>Admin: </Strong>
            {strike.undoActorAdmin == null
              ? "Kale System"
              : `${strike.undoActorAdmin.name} (${strike.undoActorAdmin.email})`}
          </Text>
        </div>
      )}
    </TableCell>
  </TableRow>
);

const StrikeDetails = ({
  strike,
  includeType = false,
}: {
  strike: CreatorStrike;
  includeType?: Boolean;
}) => (
  <div className="max-w-70 text-wrap">
    {includeType ? (
      <Text>
        <Strong>Type: </Strong>
        {strike.badActorType}
      </Text>
    ) : null}
    <Text>
      <Strong>Note: </Strong> {strike.note}
    </Text>
    <Text>
      <Strong>Created: </Strong>
      {new Date(strike.createdAt).toLocaleDateString()}
    </Text>
    <Text>
      <Strong>Admin: </Strong>
      {strike.actorAdmin == null
        ? "Kale System"
        : `${strike.actorAdmin.name} (${strike.actorAdmin.email})`}
    </Text>
  </div>
);
