import { useMutation } from "@apollo/client";
import { AdminPermission, Dropdown, OptionInterface, Spinner } from "@kalecard/common";
import { useEffect, useState } from "react";
import { CREATE_ADMIN_PERMISSION, UPDATE_ADMIN_PERMISSION } from "../../graphql/mutations";
import { ADMIN_PERMISSIONS } from "../../graphql/queries";
import { SubmitHandler, useForm } from "react-hook-form";
import { Field, FieldGroup, Fieldset, Label } from "../catalyst/fieldset";
import { Input } from "../catalyst/input";
import { adminRoles } from "../../utils/admins";
import { FormattedRole } from "../admins/AdminRole";
import { Button } from "../catalyst/button";
import { Strong } from "../catalyst/text";
import { Checkbox, CheckboxField } from "../catalyst/checkbox";

interface AdminPermissionInput {
  description: string;
  permissionName?: string;
}

function AdminPermissionForm({ 
  adminPermission, 
  groupName,
  onSubmit, 
  mutation, 
  isNewPermission 
}: { 
  adminPermission?: AdminPermission, 
  groupName?: string,
  onSubmit: () => void, 
  mutation: any, 
  isNewPermission: boolean 
}) {
  const [roles, setRoles] = useState(adminPermission?.roles ?? []);
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [error, setError] = useState(null);
  const [updateAdminToPermissionMappings, setUpdateAdminToPermissionMappings] = useState(false);
  const [haveRolesBeenUpdated, setHaveRolesBeenUpdated] = useState(false);
  const [formattedRolesMappingCallout, setFormattedRolesMappingCallout] = useState(null);

  const [executeMutation] = useMutation(mutation, {
    refetchQueries: [ADMIN_PERMISSIONS]
  });

  const { register, handleSubmit } = useForm<AdminPermissionInput>({
    defaultValues: {
      description: adminPermission?.description ?? '',
    }
  });
  
  const handleRoleClick = (role: OptionInterface) => {
    if (!roles.find((tempRole) => tempRole === role.id)) {
      const newRoles = [...roles, role.id];
      setRoles(newRoles);
    }
  }

  const handleRemoveRole = (role: string) => {
    const newRoles = roles.filter((tempRole) => tempRole !== role);
    setRoles(newRoles);
  }

  const submit: SubmitHandler<AdminPermissionInput> = async (
    data: AdminPermissionInput
  ) => {
    setIsSaveLoading(true);
    
    const variables = isNewPermission 
    ? {
      groupName: groupName,
      permissionName: data.permissionName,
      description: data.description,
      roles: Array.from(roles),
      updateAdminToPermissionMappings: updateAdminToPermissionMappings
    } : {
      adminPermissionId: adminPermission.id,
      description: data.description,
      roles: Array.from(roles),
      updateAdminToPermissionMappings: updateAdminToPermissionMappings
    }

    try {
      await executeMutation({ variables: variables });
      onSubmit();
    } catch (err) {
      console.log(err);
      setError(`Failed to ${isNewPermission ? "create" : "update"} admin permission.`);
    }
    setIsSaveLoading(false);
  };

  useEffect(() => {
    if (roles.length !== (adminPermission?.roles?.length ?? 0)) {
      setHaveRolesBeenUpdated(true);
      return;
    };

    if (isNewPermission && roles.length === 0) {
      setHaveRolesBeenUpdated(false);
      return;
    }

    const sortedA = [...roles].sort();
    const sortedB = [...adminPermission.roles].sort();

    setHaveRolesBeenUpdated(!sortedA.every((value, index) => value === sortedB[index]));
  }, [roles]);

  useEffect(() => {
    if (haveRolesBeenUpdated) {
      const newRoles = isNewPermission ? roles : roles.filter((role) => !adminPermission.roles.find((tempRole) => tempRole === role));
      const removedRoles = isNewPermission ? [] : adminPermission.roles.filter((role) => !roles.find((tempRole) => tempRole === role));

      let message = "Automatically ";
      if (newRoles.length > 0) {
        message += `give this permission to all ${newRoles.join("s and ")}s`;
      }

      if (newRoles.length > 0 && removedRoles.length > 0) {
        message += " and ";
      }

      if (removedRoles.length > 0) {
        message += `revoke this permission from all ${removedRoles.join("s and ")}s`;
      }
      message += ".";

      setFormattedRolesMappingCallout(message);
    }
  }, [roles, haveRolesBeenUpdated]);

  return (
    <form>
      <Fieldset>
        <FieldGroup>
          <Field className="flex space-x-2 items-center">
            <Label>Admin Permission ID:</Label>
            {isNewPermission 
            ? (<p className="flex text-sm items-center">
              {groupName}{"_"}
              <Input name="permissionName" {...register("permissionName", { required: true })} />
            </p>)
            : (<p className="text-sm">{adminPermission.groupName}{"_"}{adminPermission.permissionName}</p>)}    
          </Field>
          
          <Field>
            <Label>Description</Label>
            <Input name="description" {...register("description", { required: true })} />
          </Field>

          <Field className="space-y-2">
            <div className="flex space-x-2 items-center">
              <Label><Strong>Roles:</Strong></Label>
              <div className="flex space-x-2">
                {roles.map((role) => (
                  <FormattedRole role={role} key={role} showRemove={true} onRemove={handleRemoveRole} />
                ))}
              </div>
            </div>
            <div className="w-fit">
              <Dropdown options={adminRoles} activeOptionId={""} optionClicked={handleRoleClick} label={"Select an Admin role to add."} />
            </div>
          </Field>

          <div className="space-y-2">
            {haveRolesBeenUpdated && (
              <CheckboxField>
                <Checkbox
                  key="updateAdminToPermissionMappings"
                  checked={updateAdminToPermissionMappings}
                  onChange={() => setUpdateAdminToPermissionMappings(!updateAdminToPermissionMappings)}
                  color="emerald"
                />
                <Strong className="text-sm font-normal">{formattedRolesMappingCallout}</Strong>
              </CheckboxField>
            )}
            <div className="flex space-x-2 items-center">
              <Button color="emerald" className="hover:cursor-pointer" onClick={handleSubmit(submit)}>
                {isNewPermission ? "Create Permission" : "Save Changes"}
              </Button>
              {isSaveLoading && (<div className="flex flex-wrap content-center justify-center">
                <Spinner size="h-6 w-6" />
              </div>)}
            </div>
          </div>

          {error && <p className="text-sm font-medium text-red-500">{error}</p>}
        </FieldGroup>
      </Fieldset>
    </form>
  );
}

export function NewAdminPermissionForm({ groupName, onSubmit }: { groupName: string, onSubmit: () => void; }) {
  return <AdminPermissionForm groupName={groupName} onSubmit={onSubmit} mutation={CREATE_ADMIN_PERMISSION} isNewPermission={true} />;
}

export function EditAdminPermissionForm({ adminPermission, onSubmit }: { adminPermission: AdminPermission, onSubmit: () => void; }) {
  return <AdminPermissionForm adminPermission={adminPermission} onSubmit={onSubmit} mutation={UPDATE_ADMIN_PERMISSION} isNewPermission={false} />;
}