import React from "react";
import { BonsaiActionPanel } from "../BonsaiContainer";
import {
  BonsaiContextLinkProps,
  BonsaiContextLinkType,
  BonsaiVideoPanel,
  bonsaiContextLinkTypeForExternalUrl,
  creatorAdminLink,
} from "@kalecard/common";
import { BonsaiQueue } from "../BonsaiModels";

export enum MustardVerificationOutcome {
  MISLEADING = "MISLEADING",
  REUSED = "REUSED",
  STOLEN = "STOLEN",
  FALSE_POSITIVE = "FALSE_POSITIVE",
}

interface MustardVerificationInput {
  originalPostCreatorId: string;
  originalPostUrl: string;
  originalPostExternalUrl: string;

  duplicatePostCreatorId: string;
  duplicatePostUrl: string;
  duplicatePostExternalUrl: string;
}

interface MustardVerificationToolProps {
  queue: BonsaiQueue;
  taskId: number;
  onComplete: (outcome: any) => void;
  input: MustardVerificationInput;
  readOnly: boolean;
}

export function MustardVerificationTool(props: MustardVerificationToolProps) {
  const [selected, setSelected] =
    React.useState<MustardVerificationOutcome>(undefined);

  const sameCreator =
    props.input.originalPostCreatorId === props.input.duplicatePostCreatorId;
  const originalVideoPanelLinks: BonsaiContextLinkProps[] = [
    {
      type: bonsaiContextLinkTypeForExternalUrl(
        props.input.originalPostExternalUrl
      ),
      url: props.input.originalPostExternalUrl,
    },
    {
      type: BonsaiContextLinkType.CREATOR,
      url: creatorAdminLink(props.input.originalPostCreatorId),
    },
  ];

  const duplicateVideoPanelLinks: BonsaiContextLinkProps[] = [
    {
      type: bonsaiContextLinkTypeForExternalUrl(
        props.input.duplicatePostExternalUrl
      ),
      url: props.input.duplicatePostExternalUrl,
    },
  ];
  if (!sameCreator) {
    duplicateVideoPanelLinks.push({
      type: BonsaiContextLinkType.CREATOR,
      url: creatorAdminLink(props.input.duplicatePostCreatorId),
    });
  }

  return (
    <>
      <div className="mt-6 flex text-xl font-extrabold text-black">
        {sameCreator ? "Same Creator" : "Different Creators"}
      </div>
      <div className="mt-2 flex flex-row flex-nowrap gap-8">
        <div className="flex-1">
          <BonsaiVideoPanel
            title="Original"
            source={props.input.originalPostUrl}
            links={originalVideoPanelLinks}
            autoPlay={true}
          />
        </div>
        <div className="flex-1">
          <BonsaiVideoPanel
            title="Suspected Duplicate"
            source={props.input.duplicatePostUrl}
            links={duplicateVideoPanelLinks}
            autoPlay={true}
          />
        </div>
      </div>
      {!props.readOnly && (
        <BonsaiActionPanel
          onSubmit={async () => {
            props.onComplete({
              outcome: selected,
            });
            setSelected(undefined);
          }}
          taskId={props.taskId}
          taskDescription={props.queue.taskDescription}
          submitDisabled={!selected}
        >
          {sameCreator ? (
            <SameCreatorControls selected={selected} onSelected={setSelected} />
          ) : (
            <DifferentCreatorControls
              selected={selected}
              onSelected={setSelected}
            />
          )}
        </BonsaiActionPanel>
      )}
    </>
  );
}

interface DifferentCreatorControlsProps {
  selected: MustardVerificationOutcome;
  onSelected: (outcome: MustardVerificationOutcome) => void;
}

function DifferentCreatorControls(props: DifferentCreatorControlsProps) {
  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
          props.selected === MustardVerificationOutcome.STOLEN
            ? "bg-red-600 text-white hover:bg-red-600"
            : "bg-white hover:bg-gray-50"
        }`}
        onClick={() => props.onSelected(MustardVerificationOutcome.STOLEN)}
      >
        Stolen Content
      </button>
      <button
        type="button"
        className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
          props.selected === MustardVerificationOutcome.FALSE_POSITIVE
            ? "bg-blue-500 text-white hover:bg-blue-500 "
            : "bg-white hover:bg-gray-50"
        }`}
        onClick={() =>
          props.onSelected(MustardVerificationOutcome.FALSE_POSITIVE)
        }
      >
        False Positive
      </button>
    </span>
  );
}

interface SameCreatorControlsProps {
  selected: MustardVerificationOutcome;
  onSelected: (outcome: MustardVerificationOutcome) => void;
}

function SameCreatorControls(props: SameCreatorControlsProps) {
  return (
    <span className="isolate inline-flex rounded-md shadow-sm">
      <button
        type="button"
        className={`relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
          props.selected === MustardVerificationOutcome.MISLEADING
            ? "bg-red-600 text-white hover:bg-red-600"
            : "bg-white hover:bg-gray-50"
        }`}
        onClick={() => props.onSelected(MustardVerificationOutcome.MISLEADING)}
      >
        Misleading Content
      </button>
      <button
        type="button"
        className={`relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-kale-green-400/25 hover:bg-gray-50 focus:z-10 ${
          props.selected === MustardVerificationOutcome.REUSED
            ? "bg-orange-500 text-white hover:bg-orange-500"
            : "bg-white hover:bg-gray-50"
        }`}
        onClick={() => props.onSelected(MustardVerificationOutcome.REUSED)}
      >
        Reused Content
      </button>
      <button
        type="button"
        className={`relative -ml-px inline-flex items-center rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-kale-green-400/25 focus:z-10 ${
          props.selected === MustardVerificationOutcome.FALSE_POSITIVE
            ? "bg-blue-500 text-white hover:bg-blue-500 "
            : "bg-white hover:bg-gray-50"
        }`}
        onClick={() =>
          props.onSelected(MustardVerificationOutcome.FALSE_POSITIVE)
        }
      >
        False Positive
      </button>
    </span>
  );
}
