import { useMutation, useQuery } from '@apollo/client';
import { BrandLandingPage, Loading, MAIN_URL, Spinner } from '@kalecard/common';
import { useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { UPDATE_BRAND_LANDING_PAGE } from '../../graphql/mutations';
import { BRAND_LANDING_PAGE } from '../../graphql/queries';
import UploadLandingPageVideo, {
  BrandLandingPageVideoInput,
} from '../forms/UploadLandingPageVideo';
import { Brand } from '../../__generated__/graphql';

interface BrandLandingPageInput {
  video1Files?: File[];
}

export interface BrandLandingPageQuery {
  brandLandingPage: BrandLandingPage;
}

export default function BrandLandingPageForm({ brand }: { brand: Brand }) {
  const landingPage = `${MAIN_URL}/t/${brand.referralCode}`;
  const [isLoading, setIsLoading] = useState<boolean>();
  const [videoData1, setVideoData1] =
    useState<BrandLandingPageVideoInput>(null);
  const [videoData2, setVideoData2] =
    useState<BrandLandingPageVideoInput>(null);
  const [videoData3, setVideoData3] =
    useState<BrandLandingPageVideoInput>(null);
  const [successMessage, setSuccessMessage] = useState<string>(null);
  const [errorMessage, setErrorMessage] = useState<string>(null);

  const { handleSubmit } = useForm<BrandLandingPageInput>({});

  const reset = () => {
    setVideoData1(null);
    setVideoData2(null);
    setVideoData3(null);
  };

  const [updateBrandLandingPage] = useMutation(UPDATE_BRAND_LANDING_PAGE);

  const onSubmit: SubmitHandler<BrandLandingPageInput> = async () => {
    setIsLoading(true);
    setSuccessMessage(null);
    setErrorMessage(null);

    const videos = [];
    videos.push(videoData1);
    videos.push(videoData2);
    videos.push(videoData3);
    const variables = {
      brandId: brand.id,
      videos: videos,
    };
    console.log(variables);
    try {
      const result = await updateBrandLandingPage({
        variables: variables,
      });
      setSuccessMessage(
        'Congrats, your changes have been applied! It will take ~1 min for the changes to show.'
      );
      console.log(result);
      setVideoData1(null);
      setVideoData2(null);
      setVideoData3(null);
    } catch (err) {
      console.error(err);
      setErrorMessage('Failed to apply your changes, try again later.');
    }
    setIsLoading(false);
  };

  const { data, loading } = useQuery<BrandLandingPageQuery>(
    BRAND_LANDING_PAGE,
    {
      variables: { referralCode: brand.referralCode },
    }
  );

  return (
    <div className="rounded-md border-2 p-2">
      <div>
        <h2 className="font-bold">Brand Landing Page</h2>
        <div className=" text-sm">
          Landing Page:{' '}
          <a
            href={landingPage}
            className="font-bold  text-kale-orange-500 hover:opacity-60"
          >
            {landingPage}
          </a>
        </div>
      </div>
      {loading ? (
        <Loading />
      ) : (
        <div className="flex flex-col space-y-2">
          <div className="mt-2 flex w-full flex-wrap space-x-4">
            <UploadLandingPageVideo
              brandId={brand?.id}
              landingPageVideo={
                data?.brandLandingPage?.videos?.length >= 1
                  ? data?.brandLandingPage?.videos[0]
                  : null
              }
              setValues={setVideoData1}
            />
            <UploadLandingPageVideo
              brandId={brand?.id}
              landingPageVideo={
                data?.brandLandingPage?.videos?.length >= 2
                  ? data?.brandLandingPage?.videos[1]
                  : null
              }
              setValues={setVideoData2}
            />
            <UploadLandingPageVideo
              brandId={brand?.id}
              landingPageVideo={
                data?.brandLandingPage?.videos?.length >= 3
                  ? data?.brandLandingPage?.videos[2]
                  : null
              }
              setValues={setVideoData3}
            />
          </div>
          <div className="flex flex-col items-end justify-end py-5">
            <div className="flex items-center justify-end space-x-3">
              {isLoading && <Spinner size={'h-6 w-6'} />}
              {successMessage && (
                <p className="font-bold text-green-500">{successMessage}</p>
              )}
              {errorMessage && (
                <p className="font-bold text-red-500">{errorMessage}</p>
              )}
              <button
                type="button"
                disabled={isLoading}
                onClick={() => {
                  reset();
                }}
                className="rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleSubmit(onSubmit)();
                }}
                disabled={isLoading}
                className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:opacity-60"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
