import { Spinner, numberWithCommas } from "@kalecard/common";
import { useState } from "react";
import { useForm } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BrandsDropdown from "../../brands/BrandsDropdown";
import { useMutation } from "@apollo/client";
import { CREATOR_TRANSACTION } from "../../../graphql/mutations";
import { useHistory } from "react-router";
import WhitelistRequestDropdown from "../../WhitelistRequestsDropdown";
import Decimal from "decimal.js";

interface CreatorTransactionInputs {
  amount: number;
  transactionType: string;
  brandId: string;
  timestamp: number;
  messageToCreator?: string;
  postURL?: string;
}

export default function CreatorTransactionForm({
  creatorId,
}: {
  creatorId: string;
}) {
  const [isLoading, setIsLoading] = useState<boolean>();
  const [startDate, setStartDate] = useState(new Date());
  const [brandId, setBrandId] = useState(null);
  const [whitelistRequestId, setWhitelistRequestId] = useState(null);
  const [successMessage, setSuccessMessage] = useState<string>(null);
  const [errorMessage, setErrorMessage] = useState<string>(null);
  const [creatorTransaction] = useMutation(CREATOR_TRANSACTION);
  const history = useHistory();

  const { reset, register, handleSubmit, watch, setValue } =
    useForm<CreatorTransactionInputs>({
      defaultValues: {
        transactionType: "BRAND_REFERRAL_REWARD",
      },
    });
  const onSubmit = async (data: CreatorTransactionInputs) => {
    setSuccessMessage(null);
    setErrorMessage(null);
    setIsLoading(true);
    if (
      brandId === null &&
      (data.transactionType === "BRAND_REFERRAL_REWARD" ||
        data.transactionType === "CONTENT_RIGHTS_PAYOUT")
    ) {
      setErrorMessage("Please select a brand");
      setIsLoading(false);
      return;
    }

    if (
      whitelistRequestId === null &&
      data.transactionType === "WHITELISTING_PAYOUT"
    ) {
      setErrorMessage("Please select a whitelist request");
      setIsLoading(false);
      return;
    }

    const amountInCents = new Decimal(data.amount).times(100);
    const params = {
      creatorId,
      amount: amountInCents.toNumber(),
      transactionType: data.transactionType,
      brandId: brandId,
      timestamp: startDate.getTime().toString(),
      whitelistRequestId: whitelistRequestId,
      messageToCreator: data.messageToCreator,
      postURL: data.postURL,
    };

    try {
      console.log(params);
      await creatorTransaction({
        variables: params,
      });
      reset();
      setStartDate(new Date());
      setBrandId(null);
      setSuccessMessage(
        `
          Successfully ran: 
          ${data.transactionType} 
          amount: $${numberWithCommas(data.amount)} 
          date: ${startDate.getMonth() + 1}/${startDate.getFullYear()}
          brand id: ${brandId}
        `
      );
      history.push(`/creators/${creatorId}/transactions`);
    } catch (error) {
      console.error(error);
      setErrorMessage(
        `Failed to run ${data.transactionType}, please try again.`
      );
    }
    setIsLoading(false);
  };

  return (
    <form className="rounded-md border-2 p-2" onSubmit={handleSubmit(onSubmit)}>
      <div className="flex w-full flex-col space-y-4">
        <h1>Creator transaction</h1>
        {/* Type of charge this is */}
        <div className="sm:col-span-3">
          <label
            htmlFor="transactionType"
            className="block text-sm font-medium text-gray-700"
          >
            What is the transaction for?
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <select
              id="transactionType"
              name="transactionType"
              {...register("transactionType", { required: true })}
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            >
              <option value={"BRAND_REFERRAL_REWARD"}>
                Brand Referral Reward
              </option>
              <option value={"WHITELISTING_PAYOUT"}>Whitelisting payout</option>
              <option value={"MISCELLANEOUS_REWARD"}>
                Miscellaneous reward
              </option>
              <option value={"CONTENT_RIGHTS_PAYOUT"}>
                Content Rights Payout
              </option>
            </select>
          </div>
        </div>
        {/* Amount to use for the transaction */}
        <div className="sm:col-span-3">
          <label
            htmlFor="amount"
            className="block text-sm font-medium text-gray-700"
          >
            Amount
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              onWheel={(e) => (e.target as HTMLElement).blur()}
              step={0.01}
              min={0}
              {...register("amount", {
                required: true,
                validate: (value, formValues) =>
                  value > 0 || "Amount must be greater than 0",
              })}
              name="amount"
              id="amount"
              className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              aria-describedby="amount-currency"
            />
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
              <span className="text-gray-500 sm:text-sm" id="amount-currency">
                USD
              </span>
            </div>
          </div>
        </div>
        {/* What month should this charge be for? */}
        {watch("transactionType") === "BRAND_REFERRAL_REWARD" && (
          <div className="sm:col-span-3">
            <label
              htmlFor="month"
              className="block text-sm font-medium text-gray-700"
            >
              What date should this transaction be recorded for?
            </label>
            <DatePicker
              className="mt-1 rounded-md border-gray-300 text-sm shadow-sm"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
            />
          </div>
        )}
        {/* What brand does this belong to? */}
        {(watch("transactionType") === "BRAND_REFERRAL_REWARD" ||
          watch("transactionType") === "CONTENT_RIGHTS_PAYOUT") && (
          <BrandsDropdown
            brandId={brandId}
            setBrandId={setBrandId}
            setBrand={(brand) => null}
          />
        )}
        {/* Which whitelisting request is it? */}
        {watch("transactionType") === "WHITELISTING_PAYOUT" && (
          <WhitelistRequestDropdown
            creatorId={creatorId}
            requestId={whitelistRequestId}
            states={["IN_REVIEW"]}
            setRequestId={setWhitelistRequestId}
          />
        )}
        {/* What message do we want to send the creator? */}
        {watch("transactionType") === "MISCELLANEOUS_REWARD" && (
          <div className="flex flex-col">
            <label
              htmlFor="messageToCreator"
              className="block text-sm font-medium text-gray-700"
            >
              <p>
                A message to send the creator explaining them what the
                miscellaneous reward is for:
              </p>
            </label>
            <textarea
              className="w-full rounded-md border-gray-300 px-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              name="messageToCreator"
              placeholder="🎉 It's your pay day! Thanks for being a valuable member of our creator community."
              id="messageToCreator"
              {...register("messageToCreator")}
            />
          </div>
        )}
        {watch("transactionType") === "CONTENT_RIGHTS_PAYOUT" && (
          <div className="flex flex-col">
            <label
              htmlFor="postURL"
              className="block text-sm font-medium text-gray-700"
            >
              <p>The post's external URL:</p>
            </label>
            <textarea
              className="w-full rounded-md border-gray-300 px-3 focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
              name="postURL"
              placeholder="https://www.tiktok.com/@username/video/123456"
              id="postURL"
              {...register("postURL")}
            />
            <p className="pt-2 text-xs font-medium text-gray-700">
              Please follow the following format: <br />
              TikTok - https://www.tiktok.com/@username/video/123456 <br />
              Instagram - https://www.instagram.com/reel/ABCdeEFG/
            </p>
          </div>
        )}
      </div>
      <div className="pt-5">
        <div className="flex items-center justify-end">
          {isLoading && <Spinner size={"h-6 w-6"} />}
          <button
            disabled={isLoading}
            type="submit"
            className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-indigo-300"
          >
            Submit
          </button>
        </div>
        <div className="mt-2 flex items-center justify-end">
          {successMessage && (
            <p className="text-sm font-bold text-green-500">{successMessage}</p>
          )}
          {errorMessage && (
            <p className="text-sm font-bold text-red-500">{errorMessage}</p>
          )}
        </div>
      </div>
    </form>
  );
}
