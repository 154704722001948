import AddNewSocialHandle from "./actions/AddNewSocialHandle";
import UpdateCreatorAccountState from "./actions/UpdateCreatorAccountState";
import CreatorTransactionForm from "./actions/CreatorTransactionForm";

export default function CreatorActions({ creator }) {
  return (
    <div className="mt-4 space-y-6 sm:space-y-5">
      <UpdateCreatorAccountState creator={creator} />
      <AddNewSocialHandle creatorId={creator.id} />
      <CreatorTransactionForm creatorId={creator.id} />
    </div>
  );
}
