import {
  BonsaiPanel,
  BonsaiVideoPanel,
  BonsaiOutcome,
  OutcomeControls,
  BonsaiContextLinkType,
  bonsaiContextLinkTypeForExternalUrl,
  timeDifferenceForDate,
} from "@kalecard/common";
import { Strong, Text } from "../../catalyst/text";
import { BonsaiActionPanel } from "../BonsaiContainer";
import { BonsaiQueue } from "../BonsaiModels";
import { useState } from "react";
import { MusicalNoteIcon } from "@heroicons/react/24/outline";

interface CommercialSoundCheckInput {
  creatorId: number;
  postId: number;
  dealId: number;
  brandId: number;
  challengeId: number;
  productId: number;
  postExternalUrl: string;
  postUrl: string;
  postedAt: number;
  postedBy: string;
}

interface CommercialSoundCheckOutput {
  outcome: BonsaiOutcome;
}

interface CommercialSoundCheckToolProps {
  queue: BonsaiQueue;
  onComplete: (outcome: CommercialSoundCheckOutput) => void;
  readOnly: boolean;
  taskId: number;
  input: CommercialSoundCheckInput;
}

export default function CommercialSoundCheckTool(
  props: CommercialSoundCheckToolProps
) {
  const [selectedOutcome, setSelectedOutcome] =
    useState<BonsaiOutcome>(undefined);

  const links = [
    {
      type: bonsaiContextLinkTypeForExternalUrl(props.input.postExternalUrl),
      url: props.input.postExternalUrl,
    },
    {
      type: BonsaiContextLinkType.CREATOR,
      url: `/creators/${props.input.creatorId}/messages`,
    },
  ];

  return (
    <>
      <div className="flex flex-row gap-4">
        <div className={"flex-1"}>
          <BonsaiPanel>
            <div className="flex h-full flex-col space-y-4 p-4">
              <Text className="flex items-center space-x-2">
                <Strong className="text-lg font-bold">
                  Commercial Sound Check
                </Strong>{" "}
                <MusicalNoteIcon className="h-5 w-5" />
              </Text>
              <div className="flex-row justify-center space-y-2">
                <Strong>Post Details:</Strong>
                <Text>
                  Posted {timeDifferenceForDate(props.input.postedAt)} by{" "}
                  {props.input.postedBy}
                </Text>
              </div>
            </div>
          </BonsaiPanel>
        </div>
        <div className={"flex-1"}>
          <BonsaiVideoPanel
            source={props.input.postUrl}
            links={links}
            autoPlay={false}
          />
        </div>
      </div>

      {!props.readOnly && (
        <BonsaiActionPanel
          submitDisabled={selectedOutcome === undefined}
          onSubmit={() => {
            props.onComplete({
              outcome: selectedOutcome,
            });
            setSelectedOutcome(undefined);
          }}
          taskDescription={props.queue.taskDescription}
          taskId={props.taskId}
        >
          <OutcomeControls
            selected={selectedOutcome}
            onSelected={(outcome) => {
              setSelectedOutcome(outcome);
            }}
          />
        </BonsaiActionPanel>
      )}
    </>
  );
}
