import {
  SageChallengeBudget,
  SageDetails,
  SageSettings,
} from '../../__generated__/graphql';
import { AvailableChallenge, ChallengeBudgetRow } from './ChallengeBudgetRow';
import { BudgetScheduleRow } from './BudgetScheduleRow';
import { SetBrandPacingRow } from './SetBrandPacingRow';
import { DetailsSideBar } from './DetailsSideBar';

export function SageForm(props: { settings: SageSettings }) {
  const pacingEnabled = !!props.settings.schedule;
  const effectiveBudget = calculateEffectiveBudget(props.settings);

  const availableChallenges = props.settings.availableChallenges.map(
    (challenge) => {
      return {
        id: Number(challenge.id),
        name: challengeToTitle(
          Number(challenge.id),
          challenge.title,
          challenge.description
        ),
      } as AvailableChallenge;
    }
  );

  return (
    <div className="mx-auto flex max-w-6xl gap-8 p-4">
      <div className="flex-grow">
        <div className="flex flex-col gap-6">
          <SetBrandPacingRow
            brandId={props.settings.id}
            pacingEnabled={pacingEnabled}
          />
          <BudgetScheduleRow
            brandId={props.settings.id}
            schedule={props.settings.schedule}
            addScheduleEnabled={
              pacingEnabled && props.settings.schedule.events.length < 2
            }
          />
          <ChallengeBudgetRow
            effectiveBudget={effectiveBudget}
            challenges={props.settings.challengeBudgets}
            brandId={props.settings.id}
            availableChallenges={availableChallenges}
          />
        </div>
      </div>
      <div className="w-64">
        <DetailsSideBar
          details={props.settings.details as SageDetails}
          effectiveBudget={effectiveBudget}
          pacingEnabled={pacingEnabled}
        />
      </div>
    </div>
  );
}

export function EmptyState(props: { text: string }) {
  return (
    <div className="rounded-md border border-dashed border-gray-300 pb-10 pt-10">
      <div className="text-center text-sm text-gray-400">{props.text}</div>
    </div>
  );
}

export function challengeToTitle(
  id: number,
  title: string | null,
  description: string
) {
  return `${id} - ${title ? title : description}`;
}

const calculateEffectiveBudget = (settings: SageSettings) => {
  return (
    settings.details.contentBudget -
    settings.details.predictedOutstanding -
    settings.challengeBudgets
      .map((b) => effectiveChallengeBudget(b))
      .reduce((a, b) => a + b, 0)
  );
};

const effectiveChallengeBudget = (challenge: SageChallengeBudget) => {
  return Math.max(challenge.allocatedBudget - challenge.utilizedBudget, 0); // this should be clamped to zero, because it doesn't make sense for negative outstanding challenge budget
};
