import { useApolloClient, useMutation } from "@apollo/client";
import { useEffect, useState } from "react";
import { auth } from "../../config/firebase";
import { MESSAGE_CREATOR } from "../../graphql/mutations";
import { MESSAGES } from "../../graphql/queries";
import { Spinner, timeDifferenceForDate } from "@kalecard/common";
import MessageMediaComponent from "../MessageMediaComponent";
import MessageMedia from "@kalecard/common/lib/esm/graphql/MessageMedia";
import TemplatedMessages from "./TemplatedMessages";

interface CreatorMessagesInterface {
  id: string;
  phoneNumber: string;
}

export default function CreatorMessages({
  id,
  phoneNumber,
}: CreatorMessagesInterface) {
  const [localMessages, setLocalMessages] = useState([]);
  const [response, setResponse] = useState("");
  const [error, setError] = useState(null);
  const [edges, setEdges] = useState([]);
  const [after, setAfter] = useState(null);
  const [hasNextPage, setHasNextPage] = useState(false);
  const [loadingMessages, setLoadingMessages] = useState(false);
  const apolloClient = useApolloClient();
  const [sendMessageMutation] = useMutation(MESSAGE_CREATOR);
  const [loadingSend, setLoadingSend] = useState(false);

  const onChangeHandler = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { name, value } = event.currentTarget;
    if (name === "response") {
      setResponse(value);
    }
  };

  const sendMessage = async () => {
    try {
      setLoadingSend(true);
      const tokenId = await auth.currentUser.getIdToken();
      const result = await sendMessageMutation({
        variables: {
          userId: id,
          text: response,
        },
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
      console.log(result);
      setLocalMessages([{ id: "local-", text: response }, ...localMessages]);
      setResponse("");
      setError(null);
    } catch (err) {
      setError("Error sending message. Try again later");
      console.error(err);
    } finally {
      setLoadingSend(false);
    }
  };

  useEffect(() => {
    loadMore();
    return () => {};
  }, []);

  const loadMore = async () => {
    setLoadingMessages(true);
    const tokenId = await auth.currentUser.getIdToken();
    try {
      const result = await apolloClient.query({
        query: MESSAGES,
        variables: {
          first: 10,
          after,
          userId: id,
        },
        fetchPolicy: "network-only",
        context: {
          headers: {
            authorization: tokenId ? `Bearer ${tokenId}` : "",
          },
        },
      });
      console.log(result);
      setHasNextPage(result.data.messages.pageInfo.hasNextPage);
      setAfter(
        result.data.messages.edges[result.data.messages.edges.length - 1]
          ?.cursor
      );
      setEdges([...edges, ...result.data.messages.edges]);
    } catch (error) {
      console.error(error);
    }
    setLoadingMessages(false);
  };

  var listFooter = null;
  if (hasNextPage) {
    listFooter = (
      <button
        className="rounded border border-green-500 bg-transparent px-6 py-2 text-center font-semibold text-green-700 hover:border-transparent hover:bg-green-500 hover:text-white"
        onClick={() => loadMore()}
      >
        Load more
      </button>
    );
  }

  if (loadingMessages) {
    listFooter = (
      <div className="flex flex-wrap content-center">
        <Spinner size="h-6 w-6" />
      </div>
    );
  }

  // if (loading) return <Loading />
  // if (error) return <ApiError error={error} />

  return (
    <div className="flex flex-col">
      <div className="flex-col">
        <div className="mt-2 flex justify-end">
          {error !== null && (
            <div className="mx-1 w-full bg-red-600 py-3 text-center text-white">
              {error}
            </div>
          )}
          <textarea
            disabled={loadingSend}
            className="mx-2 w-1/2 rounded px-4 py-3 disabled:bg-gray-300"
            name="response"
            value={response}
            placeholder="Say something..."
            id="response"
            onChange={(event) => onChangeHandler(event)}
          />
          <button
            disabled={loadingSend}
            className="rounded border border-blue-500 bg-transparent px-2 py-2 text-center font-semibold text-blue-700 hover:border-transparent hover:bg-blue-500 hover:text-white disabled:bg-gray-300"
            onClick={() => sendMessage()}
          >
            Send
          </button>
        </div>
        <div className="mt-2">
          <TemplatedMessages userId={id} templatedMessage={setResponse} />
        </div>
      </div>
      <div className="flex w-full flex-col">
        {localMessages.map((message, index) => {
          return (
            <div key={message.id + index} className="mt-2 flex justify-end">
              <div className="rounded-lg bg-blue-300 px-4 py-3">
                <div className="whitespace-pre-line">{message.text}</div>
                <p className="text-right text-xs text-white">just now</p>
              </div>
            </div>
          );
        })}
        {edges.map((edge) => {
          const showMessageLeft = edge.node.from !== null;
          const messageFailed =
            edge.node.twilioStatus === "failed" ||
            edge.node.twilioStatus === "undelivered" ||
            edge.node.twilioStatus === "partially_delivered" ||
            edge.node.twilioStatus === "canceled";
          return (
            <div key={edge.node.id}>
              {edge.node.text && (
                <div
                  key={edge.node.id}
                  className={`flex ${
                    showMessageLeft ? "ml-2" : "justify-end"
                  } mt-2`}
                >
                  <div
                    className={`max-w-md rounded-lg px-4 py-3 ${
                      showMessageLeft
                        ? "bg-green-300"
                        : `${
                            messageFailed
                              ? "border-2 border-red-500 bg-red-300"
                              : "bg-blue-300"
                          }`
                    }`}
                  >
                    <div className="whitespace-pre-line">{edge.node.text}</div>
                    <p className="text-right text-xs text-white">
                      {edge.node?.admin?.username
                        ? `${edge.node?.admin?.username} - `
                        : ""}
                      {timeDifferenceForDate(Number(edge.node.createdAt))}
                    </p>
                    <p className="text-right text-xs text-white">
                      {edge.node?.twilioStatus}
                    </p>
                  </div>
                </div>
              )}
              {edge.node.messageMedia?.map((media: MessageMedia) => {
                return (
                  <div
                    key={media.id}
                    className={`flex ${
                      showMessageLeft ? "ml-2" : "justify-end"
                    } mt-2`}
                  >
                    <div
                      className={`max-w-md rounded-lg px-4 py-3 ${
                        showMessageLeft ? "bg-green-300" : "bg-blue-300"
                      }`}
                    >
                      <div key={media.id}>
                        <MessageMediaComponent messageMedia={media} />
                      </div>
                      <p className="text-right text-xs text-white">
                        {timeDifferenceForDate(Number(edge.node.createdAt))}
                      </p>
                    </div>
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="mt-4 flex w-1/2 justify-center">{listFooter}</div>
    </div>
  );
}
