import { ImageWithTracking, VideoWithTracking } from "@kalecard/common";

interface MediaComponentProps {
  url: string;
  mediaType: string;
}

export default function MediaComponent({
  url,
  mediaType,
}: MediaComponentProps) {
  if (mediaType === "IMAGE") {
    return (
      <ImageWithTracking
        component="MediaComponent"
        key={url}
        src={url}
        onError={(e) => ((e.target as HTMLImageElement).style.display = "none")}
        className="flex:1 max-w-15 pt-5 px-5"
      ></ImageWithTracking>
    );
  } else if (mediaType === "VIDEO") {
    return (
      <VideoWithTracking
        component="MediaComponent"
        controls={true}
        controlsList="nofullscreen noremoteplayback noplaybackrate"
        disablePictureInPicture={true}
        key={url}
        src={url}
        autoPlay={true}
        loop={true}
        muted={true}
        playsInline={true}
        onContextMenu={(e) => e.preventDefault()}
        onDragStart={(e) => e.preventDefault()}
        onError={(e) =>
          ((e.target as HTMLVideoElement).style.display = "none")
        }
        className="flex:1 h-full w-full rounded-2xl object-cover p-2"
      ></VideoWithTracking>
    );
  } else {
    return null;
  }
}
