import { createContext, useEffect, useState } from "react";
import client from "../config/apolloClient";
import { auth } from "../config/firebase";

export const UserContext = createContext<{
  user: firebase.default.User | null;
}>(null);

const UserProvider = ({ children }: any) => {
  const [user, setUser] = useState<firebase.default.User | null>(null);

  useEffect(() => {
    const unsub = auth.onAuthStateChanged(async (userAuth) => {
      // TODO: Get more data if we need it about the user. This happens when we
      // find the logged in user.

      // Reset the store so previously logged in user doesn't get the old queries
      // client.resetStore();

      if (userAuth) {
        localStorage.setItem("authenticated", "true");
        setUser(userAuth);
      } else {
        localStorage.setItem("authenticated", "false");
      }
    });
    return () => {
      client.resetStore();
      localStorage.setItem("authenticated", "false");
      unsub();
    };
  }, []);

  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  );
};

export default UserProvider;
