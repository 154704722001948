import { BrandPaymentStructure } from "@kalecard/common";

export enum BrandPaymentStructuresActionType {
  NEW_PAYMENT_STRUCTURE,
  UPDATE_PAYMENT_STRUCTURE,
  DELETE_PAYMENT_STRUCTURE
}

export interface BrandPaymentStructuresState {
  brandPaymentStructures: {};
  defaultPaymentStructures: {};
}

export interface BrandPaymentStructuresAction {
  type: BrandPaymentStructuresActionType;
  payload: any;
}

export function buildPaymentStructuresGroups(
  brandPaymentStructures: BrandPaymentStructure[],
  brandId: string,
  brandPaymentStructuresData: {},
  defaultPaymentStructuresData: {}
) {
  brandPaymentStructures.forEach((brandPaymentStructure) => {
    const table = brandPaymentStructure.brand?.id === brandId ? brandPaymentStructuresData : defaultPaymentStructuresData

    if (brandPaymentStructure.productType) {
      if (table[brandPaymentStructure.productType]) {
        table[brandPaymentStructure.productType] = [...table[brandPaymentStructure.productType], brandPaymentStructure];
      } else {
        table[brandPaymentStructure.productType] = [brandPaymentStructure];
      }
    } else {
      if (table["DEFAULT"]) {
        table["DEFAULT"] = [...table["DEFAULT"], brandPaymentStructure];
      } else {
        table["DEFAULT"] = [brandPaymentStructure];
      }
    }
  });

  return [brandPaymentStructuresData, defaultPaymentStructuresData];
}

export function BrandPaymentStructuresReducer(
  state: BrandPaymentStructuresState,
  action: BrandPaymentStructuresAction
): BrandPaymentStructuresState {
  switch (action.type) {
    case BrandPaymentStructuresActionType.UPDATE_PAYMENT_STRUCTURE: {
      const brandPaymentStructure = action.payload as BrandPaymentStructure;
      const productType = brandPaymentStructure.productType ?? "DEFAULT";

      let dataObject = brandPaymentStructure?.brand ? state.brandPaymentStructures : state.defaultPaymentStructures;
      dataObject[productType] = dataObject[productType].map((paymentStructure) => {
        if (paymentStructure.id === brandPaymentStructure.id) {
          return brandPaymentStructure;
        } else {
          return paymentStructure;
        }
      });

      if (brandPaymentStructure.brand) {
        return {
          ...state,
          brandPaymentStructures: dataObject
        };
      } else {
        return {
          ...state,
          defaultPaymentStructures: dataObject
        }
      }
    }

    case BrandPaymentStructuresActionType.NEW_PAYMENT_STRUCTURE: {
      const brandPaymentStructure = action.payload as BrandPaymentStructure;
      const productType = brandPaymentStructure.productType ?? "DEFAULT";

      let dataObject = brandPaymentStructure?.brand ? state.brandPaymentStructures : state.defaultPaymentStructures;

      console.log(dataObject[productType]);

      dataObject[productType] = dataObject[productType]?.find((paymentStructure) => paymentStructure.id === brandPaymentStructure.id)
        ? dataObject[productType]
        : (dataObject[productType]
          ? [...dataObject[productType], brandPaymentStructure].sort((a, b) => b.minQualityScore - a.minQualityScore)
          : [brandPaymentStructure]);

      if (brandPaymentStructure.brand) {
        return {
          ...state,
          brandPaymentStructures: dataObject
        };
      } else {
        return {
          ...state,
          defaultPaymentStructures: dataObject
        }
      }
    }

    case BrandPaymentStructuresActionType.DELETE_PAYMENT_STRUCTURE: {
      const brandPaymentStructure = action.payload as BrandPaymentStructure;
      const productType = brandPaymentStructure.productType ?? "DEFAULT";

      let dataObject = brandPaymentStructure?.brand ? state.brandPaymentStructures : state.defaultPaymentStructures;
      dataObject[productType] = dataObject[productType].map((paymentStructure) => {
        if (paymentStructure.id === brandPaymentStructure.id) {
          return null;
        } else {
          return paymentStructure;
        }
      }).filter((brandPaymentStructure) => brandPaymentStructure);

      if (brandPaymentStructure.brand) {
        return {
          ...state,
          brandPaymentStructures: dataObject
        };
      } else {
        return {
          ...state,
          defaultPaymentStructures: dataObject
        }
      }
    }
  }
}